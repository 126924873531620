import fetch from 'node-fetch';
import { MetaInfo } from '../types/meta';
import { getAuthToken, getBaseApiUrl } from './helpers';

export const getMeta = async (accountId: string, token?: string): Promise<MetaInfo> => {
  const url = `${getBaseApiUrl()}/v1/api/archive/${accountId}/meta`;
  const response = await fetch(url, {
    headers: {
      Authorization: token ?? (await getAuthToken(accountId)),
    },
  });
  if (response.ok) {
    return response.json() as Promise<MetaInfo>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const saveMeta = async (accountId: string, metaInfo: MetaInfo | undefined): Promise<number> => {
  console.log('about to make call');
  const response = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/meta`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ metaInfo }),
  });
  console.log('done fetching');
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};
