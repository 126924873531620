import { getAuthToken, getBaseApiUrl } from './helpers';

export const doOrganizerLookup = async (accountId: string, lookupValue: string | number) => {
  const url = `${getBaseApiUrl()}/v1/api/archive/${accountId}/organizer/${lookupValue}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    const parsedResponse = (await response.json()) as Promise<Record<string, string>>;
    if (!parsedResponse || Object.keys(parsedResponse).length === 0) {
      return Promise.reject('No lookup results found');
    }
    return parsedResponse;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const uploadOrganizerLookupFile = async (accountId: string, file: File | Blob, onUploadProgress?: (progress: number) => void) => {
  const beginResponse = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/organizer/upload`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAuthToken(),
    },
  });

  const presignedPostData = await beginResponse.json();

  const form = new FormData();
  Object.keys(presignedPostData.fields).forEach((key) => {
    form.append(key, presignedPostData.fields[key]);
  });

  form.append('file', file);

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        if (xhr.status === 200 || xhr.status === 204) {
          resolve(xhr.status);
        } else {
          reject(xhr.status);
        }
      }
    };
    xhr.upload.onerror = () => {
      console.error('Upload failed.', xhr.responseText);
      reject(xhr.status);
    };

    xhr.upload.onprogress = (event) => {
      console.log(`Uploaded ${event.loaded} of ${event.total} bytes`);
      if (onUploadProgress) {
        onUploadProgress(100 * (event.loaded / event.total));
      }
    };

    xhr.open('POST', presignedPostData.url);

    xhr.send(form);
  });
};

function base64ToBlob(base64: string, type = 'application/octet-stream') {
  const binStr = atob(base64);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  return new Blob([arr], { type: type });
}

export const printLeadSheets = async (accountId: string, types: string[], indexes: Record<string, string | number | Date>) => {
  const url = `${getBaseApiUrl()}/v1/api/archive/${accountId}/organizer/print`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: await getAuthToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accountId,
      types,
      indexes,
    }),
  });
  if (response.ok) {
    const base64Response = await response.text();
    const pdfBlob = base64ToBlob(base64Response, 'application/pdf');
    const link = document.createElement('a');
    link.href = URL.createObjectURL(pdfBlob);
    link.download = 'leadsheets.pdf';
    link.click();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};
