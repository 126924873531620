import React, { useState } from 'react';
import { uploadOrganizerLookupFile } from '../../api/organizer';
import { useUserContext } from '../../contexts/UserContext';
import { toast } from 'react-toastify';
import { Button, CustomInput, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import ProgressModal from '../../document/documentdetail/uploadprogressmodal';

export const UploadToS3: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const { currentAccount } = useUserContext();

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(e.target.files ? e.target.files[0] : null);
  };

  const uploadFile = async () => {
    if (!selectedFile) return;
    setIsSubmitting(true);
    try {
      await uploadOrganizerLookupFile(currentAccount.accountId, selectedFile, setProgress);
      toast.success('Organizer lookup file has been uploaded.');
    } catch (e) {
      console.log(e);
      toast.error(`Unable to upload lookup file, please try again.`);
    } finally {
      setIsSubmitting(false);
      setProgress(0);
    }
  };

  return (
    <>
      <div>
        <InputGroup>
          <CustomInput id="fileInput" type="file" onChange={handleFileInput} accept=".txt" />
          <InputGroupAddon addonType="append">
            <Button onClick={uploadFile}>Upload</Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
      <ProgressModal modal={isSubmitting && !!selectedFile} progress={progress} />
    </>
  );
};
