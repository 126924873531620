import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { parseImageSiloFile } from '.';
import { submitImageSiloBatch, uploadImageSiloBatchFile } from '../api/imagesilo';
import { useMetaProvider } from '../contexts/MetaContext';
import { useUserContext } from '../contexts/UserContext';
import ProgressModal from '../document/documentdetail/uploadprogressmodal';
import styles from '../styles/archiveui.module.css';
import { FullImageSiloBulkload, ImageSiloBulkload, ISDocument } from '../types/imagesilo';
import { chunkArray } from '../utils/array';
import ISIndexTable from './isindextable';

const ImageSilo = (): JSX.Element => {
  const history = useHistory();
  const { accountId } = useUserContext().currentAccount;
  const { documentTypes, documentIndexes } = useMetaProvider().metaState;
  const [selectedType, setSelectedType] = useState<string>();
  const [indexMapping, setIndexMapping] = useState<Record<string, string>>({});
  const [batchName, setBatchName] = useState<string>();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [bulkload, setBulkLoad] = useState<ImageSiloBulkload>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onDrop = useCallback(async (acceptedFile) => {
    const bulkload = await parseImageSiloFile(accountId, acceptedFile[0]);
    if (!bulkload) {
      setBulkLoad(undefined);
      setUploadedFile(undefined);
      setSelectedType(undefined);
      setIndexMapping({});
      return;
    }

    for (const type of documentTypes) {
      if (type.name.toUpperCase() === bulkload?.projectName.toUpperCase() || type.description.toUpperCase() === bulkload?.projectName.toUpperCase()) {
        setSelectedType(type.id);
        break;
      }
    }

    const mapping: Record<string, string> = {};
    bulkload.indexFields.forEach((indexField) => {
      for (const documentIndex of documentIndexes) {
        if (documentIndex.name.toUpperCase() === indexField.name.toUpperCase()) {
          mapping[indexField.name] = documentIndex.id;
          return;
        }
      }
    });
    setIndexMapping(mapping);

    setBulkLoad(bulkload);
    setUploadedFile(acceptedFile[0]);
  }, []);

  const onRejectFile = () => {
    toast.error('Invalid File');
  };

  const validIndexMapping = useMemo(() => {
    if (!bulkload) return false;
    for (const index of bulkload.indexFields) {
      if (!indexMapping[index.name]) return false;
    }
    return true;
  }, [bulkload, indexMapping]);

  const updateIndexMapping = (imageSiloIndexName: string, scribonlineIndexId: string) => {
    setIndexMapping((prev) => {
      return {
        ...prev,
        [imageSiloIndexName]: scribonlineIndexId,
      };
    });
  };

  const updateBatchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBatchName(e.target.value);
  };

  const submitBatch = async () => {
    if (!(bulkload && selectedType && validIndexMapping && batchName && uploadedFile)) {
      toast.error('Unable to create batch');
      return;
    }

    bulkload.batchName = batchName;
    const fullBulkLoad = { ...bulkload, batchName, indexMapping, documentType: selectedType };
    let allBulkLoads = [fullBulkLoad];
    const splitDocs: ISDocument[][] = chunkArray([...fullBulkLoad.documents], 1000);
    if (splitDocs.length > 1) {
      const bulkloads: FullImageSiloBulkload[] = [];
      for (const docSet of splitDocs) {
        bulkloads.push({ ...fullBulkLoad, documents: docSet });
      }

      allBulkLoads = bulkloads;
    }

    await uploadImageSiloBatchFile(accountId, fullBulkLoad.batchId, uploadedFile, setUploadProgress);

    console.log('moving to json');
    const allUploads: Promise<void>[] = [];
    allBulkLoads.forEach((bulkload, index) => {
      allUploads.push(submitImageSiloBatch(accountId, `${fullBulkLoad.batchId}_${index}`, bulkload));
    });

    await toast.promise(Promise.all(allUploads), {
      pending: 'Submitting conversion documents',
      success: 'Conversion Submitted',
      error: 'Unable to submit conversion request',
    });
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    let error = false;
    try {
      await submitBatch();
    } catch (e) {
      console.log(e);
      error = true;
    } finally {
      if (!error) {
        history.push('/batch');
      } else {
        setIsSubmitting(false);
        setUploadProgress(0);
      }
    }
  };

  console.log('progress', uploadProgress);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'application/zip', multiple: false, onDropRejected: onRejectFile });
  return (
    <div>
      <Card style={{ marginTop: '1em', borderRadius: '.5em' }}>
        <CardBody className={styles.searchBarHeader}>
          <h5 style={{ textAlign: 'start', fontWeight: 500, marginTop: '.5em' }}>ImageSilo</h5>
        </CardBody>
        <CardBody className={styles.searchBarBody}>
          <Row>
            <Col md="6">
              <Row>
                <Col md="3">
                  <Label for="batchName" style={{ paddingTop: '6px' }}>
                    Batch Name:
                  </Label>
                </Col>
                <Col md="9">
                  <Input value={batchName} type="text" name="batchName" id="batchName" onChange={updateBatchName} required={true} />
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <ButtonGroup style={{ float: 'right' }}>
                <Button className={styles.scrbBtnOrange} style={{ width: '350px' }} block onClick={(event) => event.preventDefault()}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <>Drop the file here ...</>
                    ) : uploadedFile ? (
                      <>{uploadedFile.name.length < 20 ? uploadedFile.name : `${uploadedFile.name.substring(0, 20)}...`}</>
                    ) : (
                      <>Drop your file here, or click to select a file</>
                    )}
                  </div>
                </Button>
                <Button className={styles.scrbBtnBlue} disabled={!(bulkload && selectedType && validIndexMapping && batchName)} onClick={() => onSubmit()}>
                  Submit
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {bulkload && (
        <>
          {' '}
          <Card style={{ marginTop: '1em', borderRadius: '.5em' }}>
            <CardBody className={styles.searchBarHeader}>
              <h5 style={{ textAlign: 'start', fontWeight: 500, marginTop: '.5em' }}>Project to Document Type Mapping</h5>
            </CardBody>
            <CardBody>
              <Row>
                <Col md="6">
                  <Row>
                    <Col md="3" style={{ paddingTop: '6px' }}>
                      <strong>Project Name:</strong>
                    </Col>{' '}
                    <Col md="9" style={{ paddingTop: '6px' }}>
                      {bulkload.projectName}
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="3" style={{ paddingTop: '6px' }}>
                      <strong>Document Type:</strong>
                    </Col>
                    <Col md="9">
                      <Input
                        value={selectedType}
                        type="select"
                        onChange={(e) => {
                          setSelectedType(e.target.value);
                        }}
                      >
                        <option></option>
                        {documentTypes.map((type) => {
                          return (
                            <option key={type.id} value={type.id}>
                              {type.name}
                            </option>
                          );
                        })}
                      </Input>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card style={{ marginTop: '1em', borderRadius: '.5em' }}>
            <ISIndexTable imageSiloIndexes={bulkload.indexFields} curIndexMapping={indexMapping} onMappingSelect={updateIndexMapping} />
          </Card>
        </>
      )}
      <ProgressModal modal={isSubmitting && !!uploadedFile} progress={uploadProgress} />
    </div>
  );
};

export default ImageSilo;
