import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';

import { UploadToS3 } from './upload';

const OrganizerConfig = (): JSX.Element => {
  return (
    <div>
      <Card style={{ marginTop: '1em', borderRadius: '.5em' }}>
        <CardBody className={styles.searchBarHeader}>
          <h5 style={{ textAlign: 'start', fontWeight: 500, marginTop: '.5em' }}>Organizer Configuration</h5>
        </CardBody>
        <CardBody className={styles.searchBarBody}>
          <Col size="sm-4">
            <UploadToS3 />
          </Col>
        </CardBody>
      </Card>
    </div>
  );
};

export default OrganizerConfig;
