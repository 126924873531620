import { Role } from '../types/role';
import { PermissionStatus } from '../types/role';

export const parseDocumentTypePermissionsFromRoles = (allRoles: Role[], membershipRoleIds?: string[]) => {
  const toReturn: Record<string, PermissionStatus> = {};
  const currentUserRoles = allRoles.filter((role) => membershipRoleIds?.includes(role.id));
  currentUserRoles.forEach((role) => {
    if (!role.documentTypePermissions) {
      return;
    }

    Object.keys(role.documentTypePermissions).forEach((typeKey) => {
      const permission: PermissionStatus = role.documentTypePermissions[typeKey];
      const curVal = toReturn[typeKey];
      if (curVal === 'DENY' || (curVal === 'ALLOW' && permission !== 'DENY')) {
        return;
      }

      toReturn[typeKey] = permission;
    });
  });

  return toReturn;
};
