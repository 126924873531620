import moment from 'moment';
import { DocumentType, DocumentIndex } from '../../types/meta';
import { dateToString } from '../documentsearch/daterange';

export const requiredIndexesForDocument = (documentTypes: DocumentType[], indexes: DocumentIndex[], selectedTypeKeys?: string[]) => {
  const typesSelected = documentTypes.filter((type) => selectedTypeKeys?.includes(type.id));
  let requiredIndexKeys = indexes.filter((index) => index.indexRequired).map((index) => index.id);

  typesSelected.forEach((type) => {
    const additionalRequiredIndexes = type.requiredIndexes?.filter((requiredIndex) => requiredIndex && !requiredIndexKeys.includes(requiredIndex));
    requiredIndexKeys = requiredIndexKeys.concat(additionalRequiredIndexes);
  });
  return requiredIndexKeys;
};

export const findRequiredIndexesForDocTypes = (documentTypes: DocumentType[], selectedTypes?: string[]) => {
  if (!selectedTypes) return [];
  const selectedDocTypesRequiredIndexes: string[] = [];
  documentTypes.forEach((docType) => {
    if (selectedTypes.includes(docType.id)) {
      docType.requiredIndexes?.forEach((index) => {
        selectedDocTypesRequiredIndexes.push(index);
      });
    }
  });
  return selectedDocTypesRequiredIndexes;
};

export const findHiddenIndexesForDocTypes = (documentTypes: DocumentType[], selectedTypes?: string[]) => {
  if (!selectedTypes) return [];
  const selectedDocTypesHideIndexes: string[] = [];
  const selectedDocumentTypes = documentTypes.filter((type) => selectedTypes.includes(type.id));
  selectedDocumentTypes.forEach((docType) => {
    if (docType.hideIndexes) {
      docType.hideIndexes.forEach((index) => {
        if (selectedDocumentTypes.every((type) => type.hideIndexes?.includes(index)) && !selectedDocTypesHideIndexes.includes(index)) {
          selectedDocTypesHideIndexes.push(index);
        }
      });
    }
  });
  return selectedDocTypesHideIndexes;
};

export const stringifyIndexValues = (indexes?: DocumentIndex[], values?: Record<string, string | number | Date | undefined>) => {
  if (!indexes || !values) return;
  const toReturn: Record<string, string> = {};
  indexes.forEach((index) => {
    const initialValue = values[index.id];
    if (!initialValue) return;
    let stringifiedValue: string = initialValue as string;
    if (index.type === 'date') {
      // stringifiedValue = dateToString(new Date(initialValue));
      stringifiedValue = dateToString(initialValue);
    }

    if (index.type === 'number') {
      stringifiedValue = initialValue + '';
    }

    toReturn[index.id] = stringifiedValue;
  });
  return toReturn;
};

export const sanitizeDates = (indexes: DocumentIndex[], indexValues: Record<string, string | number | Date>) => {
  const toReturn = { ...indexValues };
  const mappedDocumentIndexes: Record<string, DocumentIndex> = {};
  indexes.forEach((index) => {
    mappedDocumentIndexes[index.id] = index;
  });

  Object.keys(indexValues).forEach((key) => {
    const documentIndex = mappedDocumentIndexes[key];
    if (documentIndex.type === 'date') {
      const value = moment(indexValues[key]).utc().toISOString();
      toReturn[key] = value;
    }
  });
  return toReturn;
};
