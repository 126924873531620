import React, { Dispatch, SetStateAction, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import { Card, CardBody, Form, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import TypeModal from './typeModal';
import { DocumentIndex, DocumentType, DocumentTypeGroup, DocumentTypeStatus } from '../../types/meta';
import AccessControl from '../../users/userprofile/accesscontrol';

interface searchBarProps {
  indexes: DocumentIndex[];
  saveDocTypeHandler: (docType: DocumentType, newDocType: boolean) => void;
  accountId: string;
  statusFilter: DocumentTypeStatus | '*';
  updateStatusFilter: (status: DocumentTypeStatus) => void;
}

const TypeSearchBar = ({ saveDocTypeHandler, indexes, accountId, statusFilter, updateStatusFilter }: searchBarProps): JSX.Element => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <div>
      <div className={styles.filtersCard}>
        <CardBody className={styles.searchBarBody}>
          <Row>
            <Col md={2}>
              <Input
                value={statusFilter}
                type="select"
                name="status"
                id="status"
                aria-label="Select Type Filter"
                onChange={(e) => updateStatusFilter(e.target.value as DocumentTypeStatus)}
                required
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="deleted">Deleted</option>
                <option value="*">All</option>
              </Input>
            </Col>
            <Col>
              <AccessControl permissionId={'meta'} action={'create'}>
                <Button
                  id="addUserBtn"
                  aria-label="Add User Button"
                  color="#1C76A7"
                  style={{ backgroundColor: '#1C76A7', color: 'white', marginRight: '.5em', float: 'right' }}
                  onClick={toggle}
                >
                  <BsPlus />
                </Button>
              </AccessControl>
            </Col>
          </Row>
        </CardBody>
      </div>
      <TypeModal modal={modal} toggle={toggle} newType={true} saveMetaHandler={saveDocTypeHandler} indexes={indexes} accountId={accountId} />
    </div>
  );
};

export default TypeSearchBar;
