import React, { ChangeEvent } from 'react';
import { ListGroupItem, Input, Row, Col, Label, FormGroup, ListGroupItemText, ListGroupItemHeading, Button } from 'reactstrap';
import { PermissionStatus } from '../../types/role';
import { DocumentType } from '../../types/meta';
import styles from '../../styles/archiveui.module.css';

interface PermissionProps {
  documentType: DocumentType;
  curPermission?: PermissionStatus;
  updatePermission: (type: string, status: PermissionStatus) => void;
}

const TypePermission = ({ documentType, curPermission, updatePermission }: PermissionProps) => {
  console.log('rendering');
  return (
    <FormGroup row>
      <Label sm="3">{documentType.name}:</Label>
      <Col sm="9">
        <Input
          value={curPermission ?? 'NONE'}
          type="select"
          required={true}
          onChange={(e) => {
            updatePermission(documentType.id, e.target.value as PermissionStatus);
          }}
        >
          <option value="NONE">Not Set</option>
          <option value="ALLOW">Allow</option>
          <option value="DENY">Deny</option>
        </Input>
      </Col>
    </FormGroup>
  );
};

export default TypePermission;
