import React, { RefObject, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ScanDocument, ScanPage } from '../../types/scanner';
import styles from '../../styles/archiveui.module.css';
import useOnScreen from '../../componentutils/useonscreen';

interface ThumbnailProps {
  scanPage: ScanPage;
  pageIndex: number;
  documentIndex: number;
  documents: ScanDocument[];
  isSelected: boolean;
  selectedPages: Record<string, boolean>;
  movePage: (fromDocumentIndex: number, toDocumentIndex: number, fromPageIndex: number, toPageIndex: number) => void;
  moveSelectedPages: (toPageIndex: number, toDocumentIndex: number) => void;
  updateRefs: (id: string, ref: RefObject<HTMLDivElement>, isInView: boolean) => void;
}

export const Thumbnail = ({
  scanPage,
  pageIndex,
  documentIndex,
  documents,
  isSelected,
  selectedPages,
  movePage,
  moveSelectedPages,
  updateRefs,
}: ThumbnailProps) => {
  const dragRef = useRef<HTMLDivElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);

  const hoverIndicatorTop = useRef<HTMLDivElement>(null);
  const hoverIndicatorBottom = useRef<HTMLDivElement>(null);
  const [, drag, preview] = useDrag(
    () => ({
      type: 'thumbnail',
      item: { type: 'thumbnail', pageIndex, documentIndex, page: scanPage, id: scanPage.id },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [scanPage, documents],
  );

  const [{ isHovering }, drop] = useDrop(
    {
      accept: 'thumbnail',
      collect: (monitor) => ({
        isHovering: !!monitor.isOver(),
      }),
      drop(item: any, monitor) {
        if (!dropRef.current) {
          return;
        }
        const dragPageIndex = item.pageIndex;
        let hoverPageIndex = pageIndex;
        const dragDocumentIndex = item.documentIndex;
        const hoverDocumentIndex = documentIndex;

        if (dragPageIndex === hoverPageIndex && dragDocumentIndex === hoverDocumentIndex) {
          return;
        }

        if (selectedPages[scanPage.id]) {
          return;
        }

        const clientOffset = monitor.getClientOffset();
        if (!clientOffset) {
          return;
        }
        const hoverBoundingRect = dropRef.current.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        const isDifferentDocument = dragDocumentIndex !== hoverDocumentIndex;
        if (
          // coming down
          (dragPageIndex < hoverPageIndex && dragDocumentIndex === hoverDocumentIndex) ||
          dragDocumentIndex < hoverDocumentIndex
        ) {
          if (hoverClientY < hoverMiddleY) {
            if (!isDifferentDocument && hoverPageIndex !== 0) {
              hoverPageIndex--;
            }
          } else {
            if (isDifferentDocument) {
              hoverPageIndex++;
            }
          }
        }
        if (
          // coming up
          ((dragPageIndex > hoverPageIndex && dragDocumentIndex === hoverDocumentIndex) || dragDocumentIndex > hoverDocumentIndex) &&
          hoverClientY > hoverMiddleY
        ) {
          hoverPageIndex++;
        }

        if (selectedPages[item.id]) {
          moveSelectedPages(hoverPageIndex, hoverDocumentIndex);
        } else {
          movePage(dragDocumentIndex, hoverDocumentIndex, dragPageIndex, hoverPageIndex);
        }
      },
      hover(item: any, monitor) {
        if (!hoverIndicatorBottom.current || !hoverIndicatorTop.current || !dropRef.current) {
          return;
        }
        const dragPageIndex = item.pageIndex;
        const hoverPageIndex = pageIndex;
        const dragDocumentIndex = item.documentIndex;
        const hoverDocumentIndex = documentIndex;
        if (selectedPages[scanPage.id]) {
          return;
        }
        if (dragPageIndex === hoverPageIndex && dragDocumentIndex === hoverDocumentIndex) {
          hoverIndicatorTop.current.style.display = 'none';
          hoverIndicatorBottom.current.style.display = 'none';
          return;
        }

        const clientOffset = monitor.getClientOffset();
        if (!clientOffset) {
          return;
        }
        const hoverBoundingRect = dropRef.current.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // coming down
        if ((dragPageIndex < hoverPageIndex && dragDocumentIndex === hoverDocumentIndex) || dragDocumentIndex < hoverDocumentIndex) {
          if (hoverClientY < hoverMiddleY) {
            hoverIndicatorTop.current.style.display = 'block';
            hoverIndicatorBottom.current.style.display = 'none';
          } else {
            hoverIndicatorTop.current.style.display = 'none';
            hoverIndicatorBottom.current.style.display = 'block';
          }
        }
        // coming up
        if ((dragPageIndex > hoverPageIndex && dragDocumentIndex === hoverDocumentIndex) || dragDocumentIndex > hoverDocumentIndex) {
          if (hoverClientY > hoverMiddleY) {
            hoverIndicatorBottom.current.style.display = 'block';
            hoverIndicatorTop.current.style.display = 'none';
          } else {
            hoverIndicatorBottom.current.style.display = 'none';
            hoverIndicatorTop.current.style.display = 'block';
          }
        }
      },
    },
    [scanPage, documents, selectedPages],
  );

  preview(drop(dropRef));
  drag(dragRef);
  const isInView = useOnScreen(dropRef);
  updateRefs(scanPage.id, dropRef, isInView);
  return (
    <div ref={dropRef}>
      {isHovering && (
        <div>
          <div style={{ backgroundColor: 'blue', height: '5px', width: '70%', margin: 'auto', display: 'none' }} ref={hoverIndicatorTop}></div>
        </div>
      )}
      <div>
        <div style={{ backgroundColor: 'blue', height: '5px', width: '70%', margin: 'auto', display: 'none' }} ref={hoverIndicatorTop}></div>
      </div>
      <div className={isSelected ? styles.thumbnailOuterContainerSelected : styles.thumbnailOuterContainer} style={{ padding: '10px' }}>
        <div className={styles.thumbnailContainer} style={{ width: '200px', height: '282.8px', margin: 'auto' }} ref={dragRef}>
          <img src={`${scanPage.thumbnailURL}`} style={{ maxHeight: '100%', maxWidth: '100%' }}></img>
        </div>
      </div>
      {isHovering && (
        <div>
          <div style={{ backgroundColor: 'blue', height: '5px', width: '70%', margin: 'auto', display: 'none' }} ref={hoverIndicatorBottom}></div>
        </div>
      )}
    </div>
  );
};
