export const chunkArray = <T>(array: T[], length: number): T[][] => {
  const chunks = [];
  let i = 0;

  while (i < array.length) {
    chunks.push(array.slice(i, (i += length)));
  }

  return chunks;
};
