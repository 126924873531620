import React, { ChangeEvent, useMemo } from 'react';

import { IndexInput } from './inputs/indexinput';
import { InputType } from 'reactstrap/es/Input';
import { PicklistInput } from './inputs/picklistinput';
import { useMetaProvider } from '../../../contexts/MetaContext';
import { findHiddenIndexesForDocTypes, findRequiredIndexesForDocTypes, stringifyIndexValues } from '../metautils';
import { Document } from '../../../types/document';
import { Account } from '../../../types/account';

interface IndexInputProps {
  editDocument?: Partial<Document>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedDocTypeRequiredIndexes?: string[];
  organizerLookupFunction?: () => void;
  indexValues?: Record<string, string | number | Date>;
  currentAccount?: Account;
  selectedTypes?: string[];
}

export const IndexFields = ({ editDocument, onChange, organizerLookupFunction, indexValues, currentAccount, selectedTypes }: IndexInputProps) => {
  console.log('selected types', selectedTypes);
  const { metaState } = useMetaProvider();

  const selectedDocTypeRequiredIndexes: string[] = useMemo(() => {
    if (!metaState.documentTypes || !metaState.documentIndexes) return [];
    return findRequiredIndexesForDocTypes(metaState.documentTypes, selectedTypes);
  }, [metaState, selectedTypes]);
  const selectedDocTypeHideIndexes: string[] = useMemo(() => {
    if (!metaState.documentTypes || !metaState.documentIndexes) return [];
    return findHiddenIndexesForDocTypes(metaState.documentTypes, selectedTypes);
  }, [metaState, selectedTypes]);

  const values = stringifyIndexValues(metaState.documentIndexes, indexValues);
  return (
    <>
      {metaState.documentIndexes &&
        metaState.documentIndexes.map((index, position) => {
          if (selectedDocTypeHideIndexes.includes(index.id)) return null;
          const indexType = index.type;
          const requiredByDocType = selectedDocTypeRequiredIndexes?.includes(index.id);
          //will need to update in the future, but putting this here to make it compile
          if (indexType === 'picklist' || indexType === 'picklistWithOther') {
            return (
              <PicklistInput
                key={`indexInput_${position}`}
                indexName={index.description}
                indexId={index.id}
                value={values && values[index.id]}
                onChange={onChange}
                pickListOptions={index.picklistItems}
                isRequired={index.indexRequired || requiredByDocType}
              />
            );
          }
          let inputType: InputType;
          if (indexType === 'string') {
            inputType = 'text';
          } else {
            inputType = indexType;
          }

          return (
            <IndexInput
              key={`indexInput_${position}`}
              inputType={inputType}
              indexName={index.description}
              indexId={index.id}
              value={values ? (values[index.id] ? values[index.id] : '') : undefined}
              onChange={onChange}
              isRequired={index.indexRequired || requiredByDocType}
              isOrganizerPrimaryIndex={
                currentAccount && currentAccount.activateOrganizer && currentAccount.organizerPrimaryIndex === index.id && !!organizerLookupFunction
              }
              organizerLookupFunction={organizerLookupFunction}
            />
          );
        })}
    </>
  );
};
