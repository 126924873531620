import React, { Dispatch, SetStateAction, useState, FormEvent } from 'react';
import { MdClear } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import { Card, CardBody, Form, Row, Col, FormGroup, Input, Button, UncontrolledTooltip } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import IndexModal from './indexModal';
import { DocumentIndex } from '../../types/meta';
import AccessControl from '../../users/userprofile/accesscontrol';

interface SearchBarProps {
  accountId: string;
  toggleModal: () => void;
}

const IndexSearchBar = ({ toggleModal }: SearchBarProps): JSX.Element => {
  return (
    <div>
      <div className={styles.filtersCard}>
        <CardBody className={styles.searchBarBody}>
          <Form className={styles.metaForm}>
            <AccessControl permissionId={'meta'} action={'create'}>
              <Button id="addUserBtn" aria-label="Add User Button" className={styles.scrbBtnBlueMargin} onClick={toggleModal}>
                <BsPlus />
              </Button>
            </AccessControl>
          </Form>
        </CardBody>
      </div>
    </div>
  );
};

export default IndexSearchBar;
