import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Card, CardBody, Col, Row } from 'reactstrap';
import { concatAndPrint, getDocumentThumbnail, searchDocuments } from '../api/document';
import { inviteExternalUser } from '../api/users';
import { useCartContext } from '../contexts/CartContext';
import { useUserContext } from '../contexts/UserContext';
import DocumentTable from '../document/documentlist/documentTable';
import { LoadingComponent } from '../Loading';
import { Document } from '../types/document';
import AccessControl from '../users/userprofile/accesscontrol';
import SendExternalModal from './sendexternalmodal';
import styles from '../styles/archiveui.module.css';

const downloadPDF = (base64: string) => {
  const linkSource = `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement('a');
  const fileName = 'cart.pdf';

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export type ExpirationMetric = 'days' | 'weeks' | 'months';

export const Cart = () => {
  const { accountId } = useUserContext().currentAccount;
  const [documents, setDocuments] = useState<Document[]>([]);
  const [thumbnails, setThumbnails] = useState<Record<string, string>>({});
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [sendExternalModalOpen, setSendExternalModalOpen] = useState<boolean>(false);
  const { cartItems, emptyCart } = useCartContext();
  const [curPage, setCurPage] = useState<number>(1);
  const [isSubmitting, setIsSubmitting] = useState<boolean>();
  const history = useHistory();

  const updateDocuments = async () => {
    setLoadingDocs(true);
    let newDocuments: Document[] = [];
    const docsToFetch: string[] = [];
    for (const cartItem of cartItems) {
      const docForItem = documents.find((document) => document.id === cartItem);

      if (!docForItem) {
        docsToFetch.push(cartItem);
        continue;
      }
      newDocuments.push(docForItem);
    }

    if (docsToFetch.length > 0) {
      const query = docsToFetch
        .map((docid) => {
          return `id:"${docid}"`;
        })
        .join(' OR ');

      const searchResults = await searchDocuments(accountId, query, undefined, 1, docsToFetch.length);
      newDocuments = [...newDocuments, ...searchResults.documents];
    }

    newDocuments.sort((a, b) => cartItems.indexOf(a.id) - cartItems.indexOf(b.id));
    setDocuments(newDocuments);
    setLoadingDocs(false);
  };

  const fetchThumbs = async () => {
    for (const documentId of cartItems) {
      if (Object.keys(thumbnails).includes(documentId)) continue;

      getDocumentThumbnail(accountId, documentId).then((thumb) => {
        setThumbnails((thumbnails) => {
          return {
            ...thumbnails,
            [documentId]: thumb,
          };
        });
      });
    }
  };

  useEffect(() => {
    updateDocuments();
    fetchThumbs();
  }, [cartItems]);

  useEffect(() => {
    if (cartItems.length === 0) {
      history.push('/');
    }
    // remove from selected if removed from cart
    setSelectedItems((items) => {
      return items.filter((item) => cartItems.includes(item));
    });
  }, [cartItems]);

  const selectItem = useCallback((documentId: string) => {
    setSelectedItems((items) => {
      return [...items, documentId];
    });
  }, []);

  const deselectItem = useCallback((documentId: string) => {
    setSelectedItems((items) => {
      return items.filter((item) => item !== documentId);
    });
  }, []);

  const toggleSelectedItem = useCallback((documentId: string, isSelected: boolean) => {
    if (isSelected) {
      selectItem(documentId);
    } else {
      deselectItem(documentId);
    }
  }, []);

  const printConcatenated = async () => {
    const response = await toast.promise(concatAndPrint(accountId, selectedItems), {
      pending: 'Preparing documents...',
      success: 'Downloading',
      error: 'Unable to print documents',
    });
    downloadPDF(response);
  };

  const _sendDocumentsExternal = async (email: string, expirationQuantity: number, expirationMetric: ExpirationMetric) => {
    const expirationDate = moment();
    expirationDate.add(expirationQuantity, expirationMetric);
    await toast.promise(inviteExternalUser(accountId, email, selectedItems, expirationDate.toDate()), {
      pending: 'Preparing documents...',
      success: 'Documents sent',
      error: 'Unable to send documents',
    });
    setSendExternalModalOpen(false);
  };

  const sendDocumentsExternal = async (email: string, expirationQuantity: number, expirationMetric: ExpirationMetric) => {
    setIsSubmitting(true);
    try {
      await _sendDocumentsExternal(email, expirationQuantity, expirationMetric);
    } catch (e) {
      toast.error('Unable to send these documents, please try again.');
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const pageSize = 10;
  const totalPages = useMemo(() => {
    return Math.ceil(cartItems.length / pageSize);
  }, [cartItems]);

  useEffect(() => {
    if (curPage > totalPages) {
      setCurPage((page) => page - 1);
    }
  }, [totalPages]);

  const thumbsToShow = useMemo(() => {
    const docsForPage = [...documents].splice(curPage * pageSize - pageSize, curPage * pageSize);
    const toReturn: Record<string, string> = {};
    docsForPage.forEach((doc) => {
      toReturn[doc.id] = thumbnails[doc.id];
    });
    return toReturn;
  }, [curPage, documents, thumbnails]);

  return (
    <div style={{ height: '100%' }}>
      <Card style={{ marginTop: '1em', borderRadius: '.5em', paddingTop: '0em' }}>
        <CardBody className={styles.searchBarHeader}>
          <div>
            <h5 className={styles.searchBarTitle}>Cart</h5>
          </div>
        </CardBody>
        <CardBody className={styles.searchBarBody}>
          <Row>
            <Col md="4 offset-8">
              <ButtonGroup style={{ float: 'right' }}>
                <Button color="primary" disabled={selectedItems.length === 0} onClick={printConcatenated}>
                  Print
                </Button>
                <AccessControl permissionId={'externaluser'} action={'create'}>
                  <Button color="success" disabled={selectedItems.length === 0} onClick={() => setSendExternalModalOpen(true)}>
                    Send Externally
                  </Button>
                </AccessControl>
                <Button color="danger" onClick={emptyCart}>
                  Empty Cart
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <LoadingComponent isLoading={loadingDocs}>
        <DocumentTable
          documents={documents}
          selectDocument={toggleSelectedItem}
          selectedDocuments={selectedItems}
          accountId={accountId}
          thumbnails={thumbsToShow}
          isCart={true}
          pageSize={pageSize}
          totalPages={totalPages}
          controlledPage={curPage}
          updatePage={setCurPage}
        />
      </LoadingComponent>
      <SendExternalModal
        onSubmit={sendDocumentsExternal}
        modal={sendExternalModalOpen}
        toggle={() => setSendExternalModalOpen((sendExternalModalOpen) => !sendExternalModalOpen)}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default Cart;
