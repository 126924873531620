import React, { useMemo, useRef } from 'react';
import { useEffect, useState } from 'react';
import { getMeta, saveMeta } from '../api/meta';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import TypeSearchBar from './types/SearchBar';
import IndexSearchBar from './indexes/SearchBar';
import ImportTypes from './import/importtypes';
import ImportIndexes from './import/importindexes';
import TypeModal from './types/typeModal';
import IndexModal from './indexes/indexModal';
import { IndexTable } from './indexes/table';
import TypeTable from './types/table';
import { v4 as uuid } from 'uuid';
import { DocumentIndex, DocumentType, DocumentTypeGroup, DocumentTypeStatus, MetaInfo, MetaItem } from '../types/meta';
import styles from '../styles/archiveui.module.css';
import { useMetaProvider } from '../contexts/MetaContext';
import { useUserContext } from '../contexts/UserContext';
import { toastError } from '../toast';
import { LoadingComponent } from '../Loading';
import GroupModal from './groups/groupmodal';
import GroupTable from './groups/table';
import AccessControl from '../users/userprofile/accesscontrol';

export type IndexType = 'string' | 'date' | 'number' | 'picklist' | 'picklistWithOther' | any;

interface MetaProps {
  accountId: string;
}

export const sortMeta = <Type extends MetaItem>(items: Type[]) => {
  return items.sort((a: Type, b: Type) => {
    if (a.order && b.order) {
      return a.order - b.order;
    }
    if (a.order) {
      return 1;
    }
    if (b.order) {
      return -1;
    }
    return 0;
  });
};

const Meta = () => {
  const { currentAccount } = useUserContext();
  const [typeModal, setTypeModal] = useState(false);
  const toggleTypeModal = () => {
    setTypeModal(!typeModal);
  };
  const [indexModal, setIndexModal] = useState(false);
  const toggleIndexModal = () => {
    setIndexModal(!indexModal);
  };

  const { refreshMeta } = useMetaProvider();

  const [metaInfo, setMetaInfo] = useState<MetaInfo>({ documentIndexes: [], documentTypes: [], documentTypeGroups: [], accountId: '' });

  const [activeTab, setActiveTab] = useState('1');
  const [thisIndexType, setIndexType] = useState<string>();
  const [thisDocType, setDocType] = useState<string>();
  const [metaLoaded, setMetaLoaded] = useState<boolean>(false);
  const [typeStatusFilter, updateTypeStatusFilter] = useState<DocumentTypeStatus | '*'>('active');
  const metaUpdated = useRef(false);
  const [groupModal, setGroupModal] = useState(false);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleGroupModal = () => {
    setGroupModal(!groupModal);
  };

  useEffect(() => {
    const setMeta = async () => {
      const metaFromApi = await getMeta(currentAccount.accountId);
      setMetaInfo({
        documentTypes: sortMeta(metaFromApi.documentTypes),
        documentIndexes: sortMeta(metaFromApi.documentIndexes),
        documentTypeGroups: sortMeta(metaFromApi.documentTypeGroups),
        accountId: currentAccount.accountId,
      });
      setMetaLoaded(true);
    };
    setMeta();
  }, [currentAccount]);

  useEffect(() => {
    return () => {
      if (metaUpdated.current) {
        refreshMeta();
      }
    };
  }, []);

  const saveMetaInternal = async (metaToSave: MetaInfo) => {
    metaUpdated.current = true;
    const orderedIndexes = setMetaOrder([...metaToSave.documentIndexes]);
    const orderedTypes = setMetaOrder([...metaToSave.documentTypes]);
    const orderedGroups = setMetaOrder([...metaToSave.documentTypeGroups]);
    const newMeta = {
      accountId: currentAccount.accountId,
      documentTypes: orderedTypes,
      documentIndexes: orderedIndexes,
      documentTypeGroups: orderedGroups,
    };
    await toastError(saveMeta(currentAccount.accountId, newMeta), 'Unable to save meta');
  };

  const setMetaOrder = <Type extends MetaItem>(items: Type[]) => {
    const toReturn = [...items];
    toReturn.forEach((index, pos) => {
      index.order = pos;
    });
    return toReturn;
  };

  const saveDocTypeMetaHandler = (docType: DocumentType, newDocType: boolean) => {
    let newDocTypes = [...metaInfo.documentTypes];
    if (newDocType) {
      docType.accountId = currentAccount.accountId;
      docType.id = uuid();
      newDocTypes.push(docType);
    } else {
      newDocTypes = newDocTypes.map((currentType) => {
        if (currentType.id === docType.id) {
          return { ...currentType, ...docType };
        }
        return currentType;
      });
    }

    setMetaInfo((prev) => {
      const theNewMeta = { ...prev, documentTypes: newDocTypes };
      saveMetaInternal(theNewMeta);
      return theNewMeta;
    });
  };

  const saveDocIndexMetaHandler = (docIndex: DocumentIndex, newIndex: boolean) => {
    let newIndexes = [...metaInfo.documentIndexes];
    if (newIndex) {
      docIndex.accountId = currentAccount.accountId;
      docIndex.id = uuid();
      newIndexes.push(docIndex);
    } else {
      newIndexes = newIndexes.map((currentIndex) => {
        if (currentIndex.id === docIndex.id) {
          return { ...currentIndex, ...docIndex };
        }
        return currentIndex;
      });
    }

    setMetaInfo((prev) => {
      const theNewMeta = { ...prev, documentIndexes: newIndexes };
      saveMetaInternal(theNewMeta);
      return theNewMeta;
    });
  };

  const saveDocTypeGroupMetaHandler = (group: DocumentTypeGroup) => {
    let newGroups = metaInfo.documentTypeGroups ? [...metaInfo.documentTypeGroups] : [];
    if (!group.id) {
      group.accountId = currentAccount.accountId;
      group.id = uuid();
      newGroups.push(group);
    } else {
      newGroups = newGroups.map((currentGroup) => {
        if (group.id === currentGroup.id) {
          return { ...currentGroup, ...group };
        }
        return currentGroup;
      });
    }

    setMetaInfo((prev) => {
      const theNewMeta = { ...prev, documentTypeGroups: newGroups };
      saveMetaInternal(theNewMeta);
      return theNewMeta;
    });
  };

  const onIndexRowClick = (cell: any) => {
    if (cell.column.Header === 'Actions') {
      setIndexType(cell.row.original.id);
      toggleIndexModal();
    }
  };
  const onTypeRowClick = (cell: any) => {
    if (cell.column.Header === 'Actions') {
      setDocType(cell.row.original.id);
      toggleTypeModal();
    }
  };

  const startNewIndex = () => {
    setIndexType(undefined);
    toggleIndexModal();
  };

  const editIndex = useMemo(() => {
    return metaInfo.documentIndexes.find((index) => index.id === thisIndexType);
  }, [metaInfo.documentIndexes, thisIndexType]);

  const editType = useMemo(() => {
    return metaInfo.documentTypes.find((type) => type.id === thisDocType);
  }, [metaInfo.documentTypes, thisDocType]);

  const renderedIndexModal = (
    <IndexModal
      modal={indexModal}
      toggle={toggleIndexModal}
      docIndex={editIndex}
      saveDocIndexMetaHandler={saveDocIndexMetaHandler}
      accountId={currentAccount.accountId}
      documentIndexes={metaInfo.documentIndexes}
    />
  );

  const renderedTypeModal = (
    <TypeModal
      modal={typeModal}
      toggle={toggleTypeModal}
      newType={false}
      docType={editType}
      saveMetaHandler={saveDocTypeMetaHandler}
      indexes={metaInfo.documentIndexes}
      accountId={currentAccount.accountId}
    />
  );

  const renderedGroupmodal = <GroupModal modal={groupModal} toggle={toggleGroupModal} saveGroupHandler={saveDocTypeGroupMetaHandler} />;

  const setDocumentTypes = (newTypes: DocumentType[]) => {
    setMetaInfo((prev) => {
      const newMeta = { ...prev, documentTypes: [...newTypes] };
      saveMetaInternal(newMeta);
      return newMeta;
    });
  };
  const setDocumentIndexes = (newIndexes: DocumentIndex[]) => {
    setMetaInfo((prev) => {
      const newMeta = { ...prev, documentIndexes: [...newIndexes] };
      saveMetaInternal(newMeta);
      return newMeta;
    });
  };
  const setGroups = (newGroups: DocumentTypeGroup[]) => {
    setMetaInfo((prev) => {
      const newMeta = { ...prev, documentTypeGroups: [...newGroups] };
      saveMetaInternal(newMeta);
      return newMeta;
    });
  };

  const typesToShow = useMemo(() => {
    return metaInfo.documentTypes.filter((type) => {
      if (typeStatusFilter === '*') return true;
      return type.status === typeStatusFilter;
    });
  }, [metaInfo, typeStatusFilter]);

  return (
    <div style={{ height: '98%' }}>
      <Nav tabs style={{ marginTop: '1em' }}>
        <NavItem>
          <NavLink
            className={activeTab === '1' ? styles.activeTab : styles.nonActiveTab}
            active={activeTab === '1'}
            onClick={() => {
              toggle('1');
            }}
          >
            Types
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '2' ? styles.activeTab : styles.nonActiveTab}
            active={activeTab === '2'}
            onClick={() => {
              toggle('2');
            }}
          >
            Indexes
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '3' ? styles.activeTab : styles.nonActiveTab}
            active={activeTab === '3'}
            onClick={() => {
              toggle('3');
            }}
          >
            Groups
          </NavLink>
        </NavItem>
        <AccessControl>
          <>
            <NavItem>
              <NavLink
                className={activeTab === '4' ? styles.activeTab : styles.nonActiveTab}
                active={activeTab === '4'}
                onClick={() => {
                  toggle('4');
                }}
              >
                Import Types
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '5' ? styles.activeTab : styles.nonActiveTab}
                active={activeTab === '5'}
                onClick={() => {
                  toggle('5');
                }}
              >
                Import Indexes
              </NavLink>
            </NavItem>
          </>
        </AccessControl>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <LoadingComponent isLoading={!metaLoaded}>
            <>
              <TypeSearchBar
                saveDocTypeHandler={saveDocTypeMetaHandler}
                indexes={metaInfo.documentIndexes}
                accountId={currentAccount.accountId}
                statusFilter={typeStatusFilter}
                updateStatusFilter={updateTypeStatusFilter}
              />
              <TypeTable documentTypes={[...typesToShow]} setDocumentTypes={setDocumentTypes} onRowClick={onTypeRowClick} />
            </>
          </LoadingComponent>
        </TabPane>
        <TabPane tabId="2">
          <LoadingComponent isLoading={!metaLoaded}>
            <>
              <IndexSearchBar accountId={currentAccount.accountId} toggleModal={startNewIndex} />
              <IndexTable documentIndexes={[...metaInfo.documentIndexes]} setDocumentIndexes={setDocumentIndexes} onRowClick={onIndexRowClick} />
            </>
          </LoadingComponent>
        </TabPane>
        <TabPane tabId="3">
          <GroupTable
            groups={metaInfo.documentTypeGroups ?? []}
            setDocumentGroups={setGroups}
            documentTypes={metaInfo.documentTypes}
            toggleModal={toggleGroupModal}
          />
        </TabPane>
        <AccessControl>
          <>
            <TabPane tabId="4">
              <ImportTypes accountId={currentAccount.accountId} />
            </TabPane>
            <TabPane tabId="5">
              <ImportIndexes accountId={currentAccount.accountId} />
            </TabPane>
          </>
        </AccessControl>
      </TabContent>
      {renderedIndexModal}
      {renderedTypeModal}
      {renderedGroupmodal}
    </div>
  );
};

export default Meta;
