import React, { useCallback, useEffect, useMemo } from 'react';
import { getMeta } from '../api/meta';
import { DocumentIndex, DocumentType, DocumentTypeGroup, MetaInfo } from '../types/meta';
import { PermissionStatus } from '../types/role';
import { useUserContext } from './UserContext';

type Action = { type: 'setMeta'; meta: MetaInfo } | { type: 'setIndexes' } | { type: 'setDocumentTypes' } | { type: 'beginSetMeta' };
type Dispatch = (action: Action) => void;
type MetaState = {
  documentTypes: DocumentType[];
  documentIndexes: DocumentIndex[];
  allowedDocumentTypes: DocumentType[];
  documentTypeGroups: DocumentTypeGroup[];
};
type ContextState = { metaState: MetaState; isRefreshing: boolean };
type MetaProviderProps = { children: React.ReactNode };
//refreshMeta: () => void
const MetaStateContext = React.createContext<{ metaState: MetaState; refreshMeta: () => void; isRefreshing: boolean } | undefined>(undefined);

const refreshMeta = async (dispatch: Dispatch, accountId: string) => {
  try {
    dispatch({ type: 'beginSetMeta' });
    const meta = await getMeta(accountId);
    dispatch({
      meta,
      type: 'setMeta',
    });
  } catch (e) {
    // user does not have meta permissions, set placeholder
    dispatch({
      meta: {
        accountId: accountId,
        documentIndexes: [],
        documentTypeGroups: [],
        documentTypes: [],
      },
      type: 'setMeta',
    });
  }
};

export const MetaProvider = ({ children }: MetaProviderProps) => {
  let documentTypePermissions: Record<string, PermissionStatus> = {};
  try {
    documentTypePermissions = useUserContext().documentTypePermissions;
  } catch (e) {
    console.log('Outside of user context, we are embedded, allow all');
    documentTypePermissions = { '*': 'ALLOW' };
  }

  const metaReducer = (state: ContextState, action: Action) => {
    switch (action.type) {
      case 'beginSetMeta': {
        return {
          ...state,
          isRefreshing: true,
        };
      }
      case 'setMeta': {
        if (action.meta.documentIndexes) {
          action.meta.documentIndexes = action.meta.documentIndexes.sort((a: DocumentIndex, b: DocumentIndex) => {
            if (a.order && b.order) {
              return a.order - b.order;
            }
            if (a.order) {
              return 1;
            }
            if (b.order) {
              return -1;
            }
            return 0;
          });
        }

        let allowedDocumentTypes: DocumentType[] = action.meta.documentTypes;
        if (action.meta.documentTypes) {
          if (!documentTypePermissions) {
            allowedDocumentTypes = [];
          } else {
            if (documentTypePermissions['*'] !== 'ALLOW') {
              allowedDocumentTypes = action.meta.documentTypes.filter((type) => {
                return documentTypePermissions[type.id] === 'ALLOW';
              });
            }
          }
        }

        return { metaState: { ...action.meta, allowedDocumentTypes }, isRefreshing: false };
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
    return state;
  };

  const [state, dispatch] = React.useReducer(metaReducer, {
    metaState: { documentTypes: [], documentIndexes: [], allowedDocumentTypes: [], documentTypeGroups: [] },
    isRefreshing: true,
  });
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context

  const accountId = (() => {
    try {
      const { currentAccount } = useUserContext();
      return currentAccount.accountId;
    } catch {
      console.log('user context is not available, check for embedded');
      const storedId = localStorage.getItem('integration_account');
      if (storedId) {
        return storedId;
      }
    }
    return '';
  })();

  const refreshCall = useCallback(() => {
    refreshMeta(dispatch, accountId);
  }, [accountId]);

  useEffect(() => {
    refreshCall();
  }, [accountId]);

  const value = {
    metaState: state.metaState,
    isRefreshing: state.isRefreshing,
    refreshMeta: refreshCall,
  };

  return <MetaStateContext.Provider value={value}>{children}</MetaStateContext.Provider>;
};

export const useMetaProvider = () => {
  const context = React.useContext(MetaStateContext);
  if (context === undefined) {
    throw new Error('useMetaProvider must be used within a MetaProvider');
  }
  return context;
};

export default { MetaProvider };
