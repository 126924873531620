import React, { ChangeEvent } from 'react';
import { ListGroupItem, Input, Row, Col, Label, FormGroup, ListGroupItemText, ListGroupItemHeading, Button } from 'reactstrap';
import { PermissionStatus } from '../../types/role';
import { DocumentType } from '../../types/meta';
import styles from '../../styles/archiveui.module.css';
import { chunkArray } from '../../utils/array';
import { useMetaProvider } from '../../contexts/MetaContext';
import TypePermission from './TypePermission';

interface PermissionProps {
  userPermissions: Record<string, PermissionStatus>;
  updatePermission: (type: string, permission: PermissionStatus) => void;
}

const TypePermissionList = ({ userPermissions, updatePermission }: PermissionProps) => {
  const documentTypes = useMetaProvider().metaState.documentTypes;
  console.log('documentTypes', documentTypes);

  return (
    <div style={{ maxWidth: '98%' }}>
      {chunkArray<DocumentType>(documentTypes, 3).map((chunk, rowIndex) => {
        return (
          <Row key={`docTypeRow_${rowIndex}`}>
            {chunk.map((type, colIndex) => {
              return (
                <Col key={`docTypeColumn_${rowIndex}_${colIndex}`} md={4}>
                  <TypePermission
                    documentType={type}
                    curPermission={userPermissions ? userPermissions[type.id] : undefined}
                    updatePermission={updatePermission}
                  />
                </Col>
              );
            })}
          </Row>
        );
      })}
    </div>
  );
};

export default TypePermissionList;
