import fetch from 'node-fetch';
import { ImportGroup } from '../types/scribonline';
import { getAuthToken, getBaseApiUrl } from './helpers';

export const submitConversionBatch = async (accountId: string, batchName: string, groups: ImportGroup[]): Promise<void> => {
  return submitConversionBatchWithId(accountId, new Date().getTime() + '', batchName, 0, groups);
};

export const submitConversionBatchWithId = async (
  accountId: string,
  batchId: string,
  batchName: string,
  batchIndex: number,
  groups: ImportGroup[],
): Promise<void> => {
  const url = `${getBaseApiUrl()}/v1/api/archive/${accountId}/scribonline/conversion`;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ accountId, batchId, batchName, batchIndex, groups }),
  };
  const response = await fetch(url, requestOptions);
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const searchDocumentsCount = async (accountId: string, query: string, types?: string[]): Promise<number> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ query, types, isCount: true }),
  };
  console.log('request', requestOptions);
  const response = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/document/_search`, requestOptions);
  console.log('got response', response);
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};
