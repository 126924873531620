import React, { useCallback } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BiTrash } from 'react-icons/bi';
import { Column, Row, useSortBy, useTable } from 'react-table';
import { Alert, Card, Table } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import moment from 'moment';
import { FaSort } from 'react-icons/fa';
import { DocumentTypeGroup, DocumentType } from '../../types/meta';
import update from 'immutability-helper';
import Multiselect from 'multiselect-react-dropdown';
import GroupSearchBar from './searchbar';
import AccessControl from '../../users/userprofile/accesscontrol';
import { userHasAccess } from '../../users/userprofile/user';

interface IndexTableProps {
  groups: DocumentTypeGroup[];
  setDocumentGroups: (groups: DocumentTypeGroup[]) => void;
  documentTypes: DocumentType[];
  toggleModal: (groupId?: string) => void;
}

export const GroupTable = ({ groups, setDocumentGroups, documentTypes, toggleModal }: IndexTableProps) => {
  const typeOptions = documentTypes.map((type) => {
    return { name: type.name, id: type.id };
  });

  const removeGroup = (groupId: string) => {
    const newGroups = groups.filter((group) => group.id !== groupId);
    setDocumentGroups(newGroups);
  };

  const addTypeToGroup = (groupId: string, typeId: string) => {
    const newGroups = groups.map((group) => {
      if (group.id === groupId && !group.types.includes(typeId)) group.types.push(typeId);
      return group;
    });

    setDocumentGroups(newGroups);
  };

  const removeTypeFromGroup = (groupId: string, typeId: string) => {
    const newGroups = groups.map((group) => {
      if (group.id === groupId && group.types.includes(typeId)) group.types = group.types.filter((type) => type !== typeId);
      return group;
    });

    setDocumentGroups(newGroups);
  };

  const getGroupColumns = () =>
    React.useMemo(() => {
      const cols = [
        {
          Header: 'Heading',
          accessor: 'heading',
        },
        {
          Header: 'Types',
          accessor: 'types',
          // eslint-disable-next-line react/display-name
          Cell: (cellProps) => {
            const selectedTypes = typeOptions.filter((option) => {
              return cellProps.row.original.types.includes(option.id);
            });

            return (
              <div>
                <AccessControl
                  permissionId={'meta'}
                  action={'create'}
                  alternateChildren={
                    <>
                      {selectedTypes.map((type, index) => {
                        let seperator = ', ';
                        if (index === selectedTypes.length - 1) seperator = '';
                        return (
                          <>
                            <span key={`type_display_${type.name}`}>{type.name}</span>
                            {seperator}
                          </>
                        );
                      })}
                    </>
                  }
                >
                  <Multiselect
                    options={typeOptions}
                    selectedValues={selectedTypes}
                    displayValue="name"
                    onSelect={(selectedList, selectedItem) => addTypeToGroup(cellProps.row.original.id, selectedItem.id)}
                    onRemove={(selectedList, selectedItem) => removeTypeFromGroup(cellProps.row.original.id, selectedItem.id)}
                  />
                </AccessControl>
              </div>
            );
          },
        },
      ] as Column<DocumentTypeGroup>[];

      if (userHasAccess([{ permissionId: 'meta', action: 'create' }])) {
        cols.push({
          Header: 'Actions',
          accessor: 'id',
          Cell: (cellProps) => (
            <AccessControl permissionId={'meta'} action={'create'}>
              <span
                className={styles.actionsCell}
                onClick={() => {
                  removeGroup(cellProps.row.original.id);
                }}
              >
                <BiTrash color="#212529" />
              </span>
            </AccessControl>
          ),
        });
      }

      return cols;
    }, [documentTypes, groups]);

  const groupColumns: Column<DocumentTypeGroup>[] = getGroupColumns();

  if (!groupColumns) return null;
  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);

  const table = useTable({
    columns: groupColumns,
    data: groups,
    getRowId,
  });

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = groups[dragIndex];
      setDocumentGroups(
        update(groups, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        }),
      );
    },
    [groups],
  );

  const responsiveTable = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (responsiveTable != undefined) {
    responsiveTable.style.borderRadius = '.5em';
  }

  return (
    <>
      <GroupSearchBar toggleModal={toggleModal} />
      {groups.length > 0 ? (
        <Card className={styles.tableContainer}>
          <DndProvider backend={HTML5Backend}>
            <Table striped hover size="sm" id="auditTable" style={{ backgroundColor: 'white' }} {...table.getTableProps()}>
              <thead className={styles.tableHeader}>
                {table.headerGroups.map((headerGroup) => (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th className={styles.th}></th>
                    {headerGroup.headers.map((column) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <th {...column.getHeaderProps()} className={styles.th}>
                          {column.render('Header')}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...table.getTableBodyProps()}>
                {table.rows.map((row, index) => {
                  table.prepareRow(row);
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <AccessControl permissionId={'meta'} action={'create'} alternateChildren={<RowNoDrag row={row} />}>
                      <RowWithDrag index={index} row={row} moveRow={moveRow} {...row.getRowProps()} />
                    </AccessControl>
                  );
                })}
              </tbody>
            </Table>
          </DndProvider>
        </Card>
      ) : (
        <Alert color="warning" className={styles.noResultsAlert}>
          No results found...
        </Alert>
      )}
    </>
  );
};

export default GroupTable;
const DND_ITEM_TYPE = 'row';

const BaseRow = ({ row }: { row: Row<DocumentTypeGroup> }) => {
  return (
    <>
      {row.cells.map((cell: any, key: any) => {
        if (cell.column.Header === 'Created' || cell.column.Header === 'Updated') {
          return (
            <td key={key} {...cell.getCellProps()}>
              {moment(cell.render('Cell')).format('MM/DD/YYYY')}
            </td>
          );
        } else if (cell.column.Header === 'Actions') {
          return (
            <td key={key} {...cell.getCellProps()} className={styles.metaActionsCell}>
              {cell.render('Cell')}
            </td>
          );
        } else {
          return (
            <td key={key} {...cell.getCellProps()}>
              {cell.render('Cell')}
            </td>
          );
        }
      })}
    </>
  );
};

const RowWithDrag = ({ row, index, moveRow }: any) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item: any, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Object is possibly 'null'.
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Object is possibly 'null'.
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      item.index = hoverIndex;
      moveRow(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DND_ITEM_TYPE,
      item: { type: DND_ITEM_TYPE, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [index],
  );

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr ref={dropRef} style={{ opacity }}>
      <td ref={dragRef}>
        <FaSort />
      </td>
      <BaseRow row={row} />
    </tr>
  );
};

const RowNoDrag = ({ row }: { row: Row<DocumentTypeGroup> }) => {
  return (
    <tr>
      <td></td>
      <BaseRow row={row} />
    </tr>
  );
};
