import React, { ChangeEvent } from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'reactstrap';
import { IndexFields } from '../../document/metacomponents/index/indexfields';
import { findRequiredIndexesForDocTypes, stringifyIndexValues } from '../../document/metacomponents/metautils';
import TypeSelect from '../../document/metacomponents/type/typeselect';
import { Document } from '../../types/document';
import { Account } from '../../types/account';

interface MetaModalProps {
  modal: boolean;
  toggle: () => void;
  onSubmit: () => void;
  onIndexEdit: (event: ChangeEvent<HTMLInputElement>) => void;
  onTypeEdit: (event: ChangeEvent<HTMLInputElement>) => void;
  editDocument?: Partial<Document>;
  error?: string;
  indexValues?: Record<string, string | number | Date>;
  organizerLookupFunction?: () => void;
  currentAccount?: Account;
}

const MetaModal = ({
  modal,
  toggle,
  onSubmit,
  onTypeEdit,
  onIndexEdit,
  editDocument,
  error,
  indexValues,
  organizerLookupFunction,
  currentAccount,
}: MetaModalProps) => {
  return (
    <div>
      <Modal toggle={toggle} isOpen={modal}>
        <Form
          style={{ margin: '50px' }}
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit();
          }}
        >
          <Row>
            <Col sm={5}>
              <h5>Document Types</h5>
              <TypeSelect toggleType={onTypeEdit} selectedTypes={editDocument?.types} />
            </Col>
            <Col sm={7}>
              <h5>Indexes</h5>
              <IndexFields
                editDocument={editDocument}
                onChange={onIndexEdit}
                indexValues={{ ...indexValues }}
                organizerLookupFunction={organizerLookupFunction}
                currentAccount={currentAccount}
                selectedTypes={editDocument && editDocument.types ? [...editDocument.types] : []}
              />
            </Col>
          </Row>
          {error && (
            <Alert color="danger" style={{ textAlign: 'center' }}>
              {error}
            </Alert>
          )}
          <Row>
            <Col sm={5}>
              <Button color="warning" onClick={toggle} block>
                Close
              </Button>
            </Col>
            <Col sm={7}>
              <Button color="primary" type="submit" block>
                Release Document
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default MetaModal;
