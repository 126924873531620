import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toastError } from '../../toast';
import { useUserContext } from '../../contexts/UserContext';
import { searchDocuments } from '../../api/document';
import SearchComponent from './searchcomponent';
import DocumentList from '../documentlist';
import { Document } from '../../types/document';
import { useCartContext } from '../../contexts/CartContext';
import { toast } from 'react-toastify';

export type SortField = { field: string; ascending: boolean };
type SearchState = {
  query: string;
  currentPage?: number;
  documents?: Document[];
  searching: boolean;
  error?: string;
  totalCount: number;
  sort?: SortField;
};

const defaultSort = undefined;
type Action =
  | { type: 'submit_search'; query: string }
  | { type: 'reset_search' }
  | { type: 'update_page'; page: number }
  | { type: 'update_documents'; documents: Document[]; count: number }
  | { type: 'search_error'; message: string }
  | { type: 'update_sort'; sort: SortField }
  | { type: 'sticky_search'; prevState: SearchState };

const searchReducer = (state: SearchState, action: Action): SearchState => {
  switch (action.type) {
    case 'reset_search': {
      return {
        query: '',
        searching: false,
        totalCount: 0,
        sort: defaultSort,
      };
    }
    case 'update_page': {
      return {
        ...state,
        currentPage: action.page,
        searching: true,
      };
    }
    case 'update_sort': {
      return {
        ...state,
        currentPage: 1,
        sort: action.sort,
        searching: true,
      };
    }
    case 'update_documents': {
      return {
        ...state,
        documents: action.documents,
        totalCount: action.count,
        searching: false,
      };
    }
    case 'submit_search': {
      return {
        ...state,
        query: action.query,
        currentPage: 1,
        searching: true,
        totalCount: 0,
      };
    }
    case 'search_error': {
      return {
        ...state,
        query: '',
        error: action.message,
        searching: false,
        totalCount: 0,
      };
    }
    case 'sticky_search': {
      return { ...action.prevState, searching: true };
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

const pageSize = 10;

const DocumentSearch = () => {
  const { currentAccount } = useUserContext();
  const { user } = useUserContext().profile;
  const [searchState, dispatch] = React.useReducer(searchReducer, {
    searching: false,
    query: '',
    documents: undefined,
    currentPage: undefined,
    error: undefined,
    totalCount: 0,
    sort: defaultSort,
  });
  const { query, currentPage, documents, searching, error, totalCount, sort } = searchState;
  const { addToCart } = useCartContext();
  const [showInactiveTypes, setShowInactiveTypes] = useState<boolean>(false);
  const searchStorageKey = `scribonline_documentsearch_${user.id}_${currentAccount.accountId}`;

  useEffect(() => {
    if (searching) searchDocumentsInternal();
  }, [searching]);

  useEffect(() => {
    const { query, currentPage, sort, documents } = searchState;
    if (query && documents && documents.length > 0) {
      localStorage.setItem(searchStorageKey, JSON.stringify({ query, currentPage, sort }));
    }
  }, [searchState]);

  useEffect(() => {
    const storageItem = localStorage.getItem(searchStorageKey);
    if (!storageItem) return;
    const prevState: SearchState = JSON.parse(storageItem);
    if (prevState.query) {
      dispatch({ type: 'sticky_search', prevState });
    }
  }, []);

  const sortString = useMemo(() => {
    if (!sort) return;
    return `${sort.field}:${sort.ascending ? 'asc' : 'desc'}`;
  }, [sort]);
  const searchDocumentsInternal = async () => {
    console.log('the query', query, currentPage, pageSize, sortString);
    const searchResults = await toastError(
      searchDocuments(currentAccount.accountId, query, undefined, currentPage, pageSize, sortString),
      'Error searching documents',
    );
    if (searchResults.count === 0) {
      dispatch({ type: 'search_error', message: 'No document results found.' });
      return;
    }
    dispatch({ type: 'update_documents', documents: searchResults.documents, count: searchResults.count });
  };

  const addAllToCart = async () => {
    const allResults = await searchDocuments(currentAccount.accountId, query, undefined, 1, totalCount);
    addToCart(allResults.documents.map((document: Document) => document.id));
  };

  const addResultsToCart = async () => {
    await toast.promise(addAllToCart(), {
      pending: 'Adding search results to cart.',
      success: 'Search results added to cart.',
      error: 'Unable to add search results to cart.',
    });
  };

  const resetSearch = useCallback(() => {
    dispatch({ type: 'reset_search' });
    setShowInactiveTypes(false);
    localStorage.removeItem(searchStorageKey);
  }, []);

  const submitSearch = useCallback((query: string) => {
    dispatch({ type: 'submit_search', query });
  }, []);

  const updatePage = useCallback((newPage: number) => {
    dispatch({ type: 'update_page', page: newPage });
  }, []);

  const updateSort = useCallback((newSort: SortField) => {
    dispatch({ type: 'update_sort', sort: newSort });
  }, []);

  return (
    <>
      {documents ? (
        <DocumentList
          loadingDocuments={searching}
          totalCount={totalCount}
          updatePage={updatePage}
          startNewSearch={resetSearch}
          pageSize={pageSize}
          documents={documents}
          currentPage={currentPage}
          addResultsToCart={addResultsToCart}
          showInactiveTypes={showInactiveTypes}
          currentSort={sort}
          updateSort={updateSort}
        />
      ) : (
        <SearchComponent
          submitSearch={submitSearch}
          searchError={error}
          searching={searching}
          showInactiveTypes={showInactiveTypes}
          setShowInactiveTypes={setShowInactiveTypes}
        />
      )}
    </>
  );
};

export default DocumentSearch;
