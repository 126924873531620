import React, { Dispatch, SetStateAction, useState } from 'react';
import moment from 'moment';
import { useTable, useSortBy, usePagination, Column } from 'react-table';
import { Card, Table, Input, Button, Alert } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import SearchBar from '../rolelisttable/SearchBar';
import { AiFillEdit } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { Role } from '../../types/role';
import { formatDate } from '../../utils/date';
import AccessControl from '../../users/userprofile/accesscontrol';
import { userHasAccess } from '../../users/userprofile/user';

interface RoleTableProps {
  roles: Role[];
  searchText: string;
  updateSearchText: Dispatch<SetStateAction<string>>;
}

const RoleTable = ({ roles, searchText, updateSearchText }: RoleTableProps) => {
  const history = useHistory();
  const data = roles;

  const columns = React.useMemo(() => {
    const cols = [
      {
        Header: 'Name',
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Created',
        accessor: 'created',
        Cell: (cellProps) => {
          return <>{cellProps.value && formatDate(cellProps.value)}</>;
        },
      },
      {
        Header: 'Updated',
        accessor: 'modified',
        Cell: (cellProps) => {
          return <>{cellProps.value && formatDate(cellProps.value)}</>;
        },
      },
    ] as Column<Role>[];

    if (userHasAccess([{ permissionId: 'roles', action: 'update' }])) {
      cols.push({
        Header: 'Actions',
        // eslint-disable-next-line react/display-name
        Cell: () => (
          <td className={styles.actionsCell}>
            <AiFillEdit id="edit" color="#212529" />
          </td>
        ),
      });
    }

    return cols;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data, initialState: { pageIndex: 0 } }, useSortBy, usePagination);

  const onRowClick = (cell: any) => {
    if (cell.column.Header === 'Actions') {
      history.push(`/roledetail/${cell.row.original.id}`);
    }
  };

  const table = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (table != undefined) {
    table.style.borderRadius = '.5em';
  }

  return (
    <div className={styles.tableContainer}>
      <SearchBar searchText={searchText} updateSearchText={updateSearchText} />
      <AccessControl permissionId={'roles'} action={'read'}>
        {roles.length > 0 ? (
          <>
            <Card style={{ marginTop: '1em', borderRadius: '.5em' }}>
              <Table striped hover size="sm" id="auditTable" responsive style={{ backgroundColor: 'white' }} {...getTableProps()}>
                <thead className={styles.tableHeader}>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        if (column.Header === 'Name') {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.IdHeader}>
                              {column.render('Header')}
                              {/* Add a sort direction indicator */}
                              <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                            </th>
                          );
                        } else if (column.Header === 'Actions') {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.actionsHeader}>
                              {column.render('Header')}
                              {/* Add a sort direction indicator */}
                              <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                            </th>
                          );
                        } else {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.th}>
                              {column.render('Header')}
                              {/* Add a sort direction indicator */}
                              <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                            </th>
                          );
                        }
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          if (cell.column.Header === 'Name') {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <td
                                style={{ paddingLeft: '1.25em' }}
                                onClick={() => {
                                  onRowClick(cell);
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          } else if (cell.column.Header === 'Actions') {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <td
                                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}
                                onClick={() => {
                                  onRowClick(cell);
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          } else {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <td
                                onClick={() => {
                                  onRowClick(cell);
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
            <div className="pagination" style={{ justifyContent: 'end', marginTop: '.5em' }}>
              <div style={{ marginRight: '.5em' }}>
                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </Button>{' '}
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </Button>{' '}
                <Button onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </Button>{' '}
                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  {'>>'}
                </Button>{' '}
              </div>
              <div style={{ marginRight: '.5em' }}>
                <span>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
                </span>
              </div>
              <Input
                style={{ maxWidth: '10em' }}
                type="select"
                aria-label="Select Page Size"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Input>
            </div>
          </>
        ) : (
          <Alert color="warning" className={styles.noResultsAlert}>
            No results found...
          </Alert>
        )}
      </AccessControl>
    </div>
  );
};

export default RoleTable;
