/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef, useMemo, ChangeEvent, useCallback } from 'react';
import { Col, Row, Button, Form, FormFeedback, FormGroup, Label, Input, CardBody, ListGroup, InputGroup, InputGroupAddon } from 'reactstrap';
import { toastError } from '../../../toast';
import { useParams } from 'react-router';
import { getPermissions } from '../../../api/roles';
import styles from '../../../styles/archiveui.module.css';
import { APIUser } from '../../../types/user';
import { useHistory } from 'react-router-dom';
import 'react-phone-input-2/lib/bootstrap.css';
import { Permission, PermissionAssociation, PermissionId, PermissionStatus } from '../../../types/role';
import { getAPIUser, updateAPIUser } from '../../../api/users';
import { Loading, LoadingComponent } from '../../../Loading';
import RolePermission from '../../../roles/roledetail/permission';
import { toast } from 'react-toastify';
import { BsFillEyeFill, BsEyeSlashFill } from 'react-icons/bs';

interface UserDetailParams {
  accountId: string;
  id: string | undefined;
}

const UserDetail = (): JSX.Element => {
  const history = useHistory();
  const { id, accountId } = useParams<UserDetailParams>();
  const currentUserRef = useRef<APIUser>();
  const [userEdits, setUserEdits] = useState<Partial<APIUser>>({});
  const [showSecret, setShowSecret] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [allPermissions, setAllPermissions] = useState<Record<string, Permission>>();
  const [formErrors, setFormErrors] = useState({ displayName: '', status: '' });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const setInitialState = async () => {
    if (id) {
      const currentUser: APIUser = await toastError(getAPIUser(id), 'Unable to fetch user');
      currentUserRef.current = currentUser;
      setUserEdits((userEdits) => {
        return {
          ...userEdits,
          ...currentUser,
        };
      });
    } else {
      setUserEdits((userEdits) => {
        return {
          ...userEdits,
          accountId,
          status: 'ACTIVE',
        };
      });
    }
    const allpermissions = await getPermissions();
    setAllPermissions(allpermissions);
    setLoading(false);
  };

  useEffect(() => {
    setInitialState();
  }, []);

  const handleInputEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserEdits((prevValues) => {
      return { ...prevValues, [event.target.name]: event.target.value };
    });
  };

  const findOrCreatePermission = (permissionId: PermissionId, userPermissions: PermissionAssociation[]) => {
    const userPermission = userPermissions.find((permission) => permission.permissionId === permissionId);
    return (
      userPermission ??
      (() => {
        const emptyPermission: PermissionAssociation = {
          roleid: '',
          permissionId: permissionId,
          allowedActions: { create: 'NONE', read: 'NONE', update: 'NONE', delete: 'NONE' },
        };
        userPermissions.push(emptyPermission);
        return emptyPermission;
      })()
    );
  };

  const togglePermission = useCallback(
    (event: ChangeEvent<HTMLInputElement>, perm: Permission) => {
      const permId = perm.id;
      const permAction = event.target.name;
      const permActionValue = event.target.value as PermissionStatus;
      const newPermissions = userEdits.permissions ? [...userEdits.permissions] : [];
      const permissionToEdit = findOrCreatePermission(permId, newPermissions);

      switch (permAction) {
        case 'create':
          permissionToEdit.allowedActions.create = permActionValue;
          break;
        case 'read':
          permissionToEdit.allowedActions.read = permActionValue;
          break;
        case 'update':
          permissionToEdit.allowedActions.update = permActionValue;
          break;
        case 'delete':
          permissionToEdit.allowedActions.delete = permActionValue;
          break;
        default:
          break;
      }

      setUserEdits((prevValues) => {
        return { ...prevValues, permissions: newPermissions };
      });
    },
    [userEdits],
  );

  const setAllPermissionToggle = useCallback(
    (perm: Permission) => {
      const permId = perm.id;
      const newPermissions = userEdits.permissions ? [...userEdits.permissions] : [];
      const permissionToEdit = findOrCreatePermission(permId, newPermissions);

      permissionToEdit.allowedActions = { create: 'ALLOW', read: 'ALLOW', update: 'ALLOW', delete: 'ALLOW' };
      setUserEdits((prevValues) => {
        return { ...prevValues, permissions: newPermissions };
      });
    },
    [userEdits],
  );

  const validateForm = useCallback(() => {
    const errors = { displayName: '', status: '' };
    let valid = true;
    if (userEdits.displayName && userEdits.displayName.trim().length === 0) {
      errors.displayName = 'Display name is required';
      valid = false;
    }
    if (userEdits.status && userEdits.status !== 'ACTIVE' && userEdits.status !== 'INACTIVE') {
      errors.status = 'Invalid status value';
      valid = false;
    }
    setFormErrors(errors);
    return valid;
  }, [userEdits]);

  const _saveUserHandler = async () => {
    await toast.promise(updateAPIUser(!currentUserRef.current, { ...userEdits }), {
      pending: 'Saving API User',
      error: 'Unable to save user',
      success: 'User saved',
    });
    history.goBack();
  };

  const saveUserHandler = async () => {
    setIsSubmitting(true);
    try {
      await _saveUserHandler();
    } catch (e) {
      toast.error('Unable to save API User, please try again.');
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const appClientInfo = useMemo(() => {
    if (!currentUserRef.current) return null;
    return (
      <Row form>
        <Col>
          <FormGroup>
            <Label for="appClientId">App Client ID</Label>
            <Input defaultValue={currentUserRef.current?.appClientId} type="text" name="appClientId" id="appClientId" disabled={true} />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="clientSecret">Client Secret</Label>
            <InputGroup>
              <Input
                defaultValue={currentUserRef.current?.clientSecret}
                type={showSecret ? 'text' : 'password'}
                name="clientSecret"
                id="clientSecret"
                disabled={true}
              />
              <InputGroupAddon addonType="append">
                <Button onClick={() => setShowSecret((showSecret) => !showSecret)} color={showSecret ? 'dark' : 'primary'}>
                  {showSecret ? <BsEyeSlashFill /> : <BsFillEyeFill />}
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    );
  }, [currentUserRef.current, showSecret]);

  const renderedPermissions = useMemo(() => {
    if (!allPermissions) {
      return null;
    }

    return (
      <>
        {Object.keys(allPermissions).map((permissionId, index) => {
          const permission = allPermissions[permissionId];
          const userPermission = userEdits.permissions?.find((permission) => permission.permissionId === permissionId);
          return (
            <RolePermission
              togglePermission={togglePermission}
              key={`permissions_${index}`}
              permission={permission}
              allowedActions={userPermission?.allowedActions}
              actionsFromDefinititon={permission.actions}
              toggleAll={setAllPermissionToggle}
            />
          );
        })}
      </>
    );
  }, [userEdits, allPermissions]);

  if (loading) {
    return <Loading />;
  }

  return (
    <LoadingComponent isLoading={loading}>
      <div className={styles.detailContainer}>
        <CardBody className={styles.searchBarHeader}>
          <h5 className={styles.searchBarTitle}>User Detail</h5>
        </CardBody>
        <CardBody>
          <Form
            onSubmit={(event) => {
              if (validateForm()) {
                event.preventDefault();
                saveUserHandler();
              }
            }}
          >
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="displayName">Display Name</Label>
                  <Input
                    defaultValue={currentUserRef.current?.displayName}
                    type="text"
                    name="displayName"
                    id="displayName"
                    invalid={formErrors.displayName !== ''}
                    onChange={(event) => handleInputEdit(event)}
                    required
                  />
                  <FormFeedback>{formErrors.displayName}</FormFeedback>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="status">Status</Label>
                  <Input
                    defaultValue={currentUserRef.current?.status}
                    type="select"
                    name="status"
                    id="status"
                    invalid={formErrors.status !== ''}
                    onChange={(event) => handleInputEdit(event)}
                    required
                  >
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </Input>
                  <FormFeedback>{formErrors.status}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            {appClientInfo}
            <ListGroup>{renderedPermissions}</ListGroup>
            <div className={styles.detailBtnContainer}>
              <Button
                className={styles.scrbBtnBlueMargin}
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <Button className={styles.scrbBtnOrange} disabled={isSubmitting}>
                Save
              </Button>
            </div>
          </Form>
        </CardBody>
      </div>
    </LoadingComponent>
  );
};

export default UserDetail;
