import React from 'react';
import { Button, Col, Form, Modal, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { UploadProgress } from '../../types/scanner';

interface ProgressProps {
  modal: boolean;
  progressTracker: UploadProgress[];
  toggle: () => void;
}

const ProgressModal = ({ modal, toggle, progressTracker }: ProgressProps) => {
  const sortedProgress = progressTracker.sort((a, b) => {
    return a.startTime.getTime() - b.startTime.getTime();
  });
  if (sortedProgress.length > 0) {
    return (
      <div>
        <Modal toggle={toggle} isOpen={modal}>
          <ModalHeader>Upload Progress</ModalHeader>
          <Form style={{ margin: '50px' }}>
            <Row>
              <Col sm={12}>
                {sortedProgress.map((entry, index) => {
                  return (
                    <div key={entry.documentId} className={`alert alert-${entry.error ? 'danger' : 'success'}`}>
                      {index + 1}. %{entry.percentageComplete} {entry.error}
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Form>
          <ModalFooter>
            <Row>
              <Col>
                <Button color="warning" onClick={toggle} block>
                  Close
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  return (
    <div>
      <Modal toggle={toggle} isOpen={modal}>
        <ModalHeader>Upload Progress</ModalHeader>
        <Row>
          <Col sm={12} style={{ textAlign: 'center' }}>
            <h4>No progress</h4>
          </Col>
        </Row>
        <ModalFooter>
          <Row>
            <Col>
              <Button color="warning" onClick={toggle} block>
                Close
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProgressModal;
