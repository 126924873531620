import { toast } from 'react-toastify';
import parse, { Node } from 'xml-parser';
import { ImageSiloBulkload, ISDocument, ISIndex, ISIndexField, ISIndexType } from '../types/imagesilo';
import { v4 as uuid } from 'uuid';

const parseDocumentIndexValue = (fieldNode: Node) => {
  let name = undefined;
  let value = undefined;
  for (const child of fieldNode.children) {
    if (child.name === 'NAME') {
      name = child.content;
    }

    if (child.name === 'VALUE') {
      value = child.content;
    }
  }

  if (!name || !value) {
    return;
  }
  return { name, value };
};

const parseDocument = (node: Node) => {
  const indexFields: ISIndex[] = [];
  let pages = undefined;
  const fileLocations: string[] = [];
  let createdDate = undefined;

  if (node.attributes['DOCCREATEDATE']) {
    createdDate = new Date(node.attributes['DOCCREATEDATE']);
  }

  for (const child of node.children) {
    if (child.name === 'DOCINDEXFIELD') {
      const indexField = parseDocumentIndexValue(child);
      if (!indexField) return;
      indexFields.push(indexField);
    }

    if (child.name === 'OBJECT') {
      for (const fileNode of child.children) {
        if (fileNode.name === 'PAGES') {
          if (fileNode.content) {
            pages = parseInt(fileNode.content);
          }
        }

        if (fileNode.name.startsWith('FILE')) {
          if (fileNode.content) fileLocations.push(fileNode.content);
        }
      }
    }
  }

  if (!createdDate || !pages || indexFields.length === 0 || fileLocations.length === 0) return;
  return {
    indexFields,
    pages,
    fileLocations,
    createdDate,
  };
};

const parseDocIndexField = (node: Node) => {
  let name = undefined;
  let format = undefined;
  let type = undefined;
  let required = false;

  for (const child of node.children) {
    if (child.name === 'NAME') {
      name = child.content;
      continue;
    }

    if (child.name === 'FORMAT') {
      format = child.content;
      continue;
    }

    if (child.name === 'REQUIRED') {
      required = child.content === '1';
      continue;
    }

    if (child.name === 'TYPE') {
      type = child.content as ISIndexType;
      continue;
    }
  }

  if (!name || !type) {
    return;
  }

  return {
    name,
    format,
    required,
    type,
  };
};

export const parseImageSiloXmlToObject = (accountId: string, xml: string): ImageSiloBulkload | undefined => {
  const parsed = parse(xml);
  let project = undefined;
  for (const child of parsed.root.children) {
    if (child.name === 'PROJECT') {
      project = child;
      break;
    }
  }

  if (!project) return;

  let projectName = undefined;
  const indexFields: ISIndexField[] = [];
  const documents: ISDocument[] = [];

  for (const child of project.children) {
    if (child.name === 'NAME') {
      projectName = child.content;
    }

    if (child.name === 'DOCINDEXFIELDS') {
      for (const node of child.children) {
        const indexField = parseDocIndexField(node);
        if (!indexField) {
          toast.error('Unable to parse xml... invalid DOCINDEXFIELD');
          return;
        }
        indexFields.push(indexField);
      }
    }

    if (child.name === 'DOCUMENTS') {
      for (const node of child.children) {
        const document = parseDocument(node);
        if (!document) {
          toast.error('Unable to parse xml... invalid DOCUMENT');
          return;
        }
        documents.push(document);
      }
    }
  }

  if (!projectName) {
    toast.error('Unable to parse xml... no project name');
    return;
  }

  if (documents.length === 0) {
    toast.error('Unable to parse xml... no documents found');
    return;
  }

  if (indexFields.length === 0) {
    toast.error('Unable to parse xml... index fields not defined');
    return;
  }

  return { accountId, batchId: uuid(), projectName, indexFields, documents };
};

export const parseImageSiloXmlToDocuments = (xml: string): ISDocument[] | undefined => {
  const parsed = parse(xml);
  let project = undefined;
  for (const child of parsed.root.children) {
    if (child.name === 'PROJECT') {
      project = child;
      break;
    }
  }

  if (!project) return;

  const documents: ISDocument[] = [];

  for (const child of project.children) {
    if (child.name === 'DOCUMENTS') {
      for (const node of child.children) {
        const document = parseDocument(node);
        if (!document) {
          toast.error('Unable to parse xml... invalid DOCUMENT');
          return;
        }
        documents.push(document);
      }
    }
  }

  if (documents.length === 0) {
    toast.error('Unable to parse xml... no documents found');
    return;
  }

  return documents;
};
