import fetch from 'node-fetch';
import { ScannerAction, ScannerLog } from '../types/logging';
import { v4 as uuid } from 'uuid';
import { getBaseApiUrl } from './helpers';

export const generateScannerLog = async (accountId: string, userId: string, action: ScannerAction) => {
  const userAgent = window.navigator.userAgent;
  const machineId = checkMachineId();
  const scannerLog: ScannerLog = {
    id: uuid(),
    accountId,
    actionUser: userId,
    userAgent,
    machineId,
    action,
    actionTime: new Date(),
  };

  console.log('about to add log', scannerLog);
  const response = await fetch(`${getBaseApiUrl()}/v1/api/archive/log/scanner`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...scannerLog }),
  });
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const checkMachineId = () => {
  let machineId = localStorage.getItem('scribs_machine_id');
  if (!machineId) {
    machineId = uuid();
    localStorage.setItem('scribs_machine_id', machineId);
  }
  return machineId;
};
