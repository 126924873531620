import fetch from 'node-fetch';
import { Batch, BatchStatus, BatchType } from '../types/document';
import { getAuthToken, getBaseApiUrl } from './helpers';
import { Document } from '../types/document';
import { arrayBufferToBlob, downloadCsv } from '../utils/fileutils';

export const getBatches = async (accountId: string, status?: BatchStatus, type?: BatchType): Promise<Batch[]> => {
  const url = `${getBaseApiUrl()}/v1/api/archive/${accountId}/batch`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<Batch[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const revertBatch = async (accountId: string, batchId: string) => {
  const url = `${getBaseApiUrl()}/v1/api/archive/${accountId}/batch/${batchId}?deleteDocs=true`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
    method: 'DELETE',
  });
  if (response.ok) {
    return {};
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const downloadBatchReport = async (accountId: string, batchId: string) => {
  const url = `${getBaseApiUrl()}/v1/api/archive/${accountId}/batch/${batchId}/report`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });

  if (response.ok) {
    Promise.resolve('ok');
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getBatchDocStatus = async (accountId: string, batchId: string) => {
  const url = `${getBaseApiUrl()}/v1/api/archive/${accountId}/batch/${batchId}/status`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<Batch>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const searchDocuments = async (
  accountId: string,
  query: string,
  types?: string[],
  curPage?: number,
  pageSize?: number,
  sort?: string | string[],
): Promise<{ documents: Document[]; count: number }> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ query, types, curPage, pageSize, sort }),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/document/_search`, requestOptions);
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getDocument = async (accountId: string, documentid: string): Promise<Document> => {
  // call the server so we can check auth and get our cookies set
  const response = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/document/${documentid}`, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

const multiImageContentUpload = async (accountId: string, documentId: string, files: File[], onUploadProgress?: (progress: number) => void) => {
  const formData = new FormData();
  for (const file of files) {
    let filename = file.name;
    if (file.type === 'application/pdf' && !filename.endsWith('pdf')) {
      filename = `${filename}.pdf`;
    }
    formData.append('file', file, filename);
  }

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        if (xhr.status === 200 || xhr.status === 204) {
          resolve(xhr.status);
        } else {
          reject(xhr.status);
        }
      }
    };
    xhr.upload.onerror = () => {
      console.error('Upload failed.', xhr.responseText);
      reject(xhr.status);
    };

    xhr.upload.onprogress = (event) => {
      console.log(`Uploaded ${event.loaded} of ${event.total} bytes`);
      if (onUploadProgress) {
        onUploadProgress(100 * (event.loaded / event.total));
      }
    };

    xhr.open('POST', `${getBaseApiUrl()}/server/${accountId}/${documentId}/imageupload`);
    xhr.send(formData);
  });
};

const parseFilesForUpload = async (accountId: string, documentId: string, files?: File | File[], onUploadProgress?: (progress: number) => void) => {
  if (!files) return undefined;

  if (!Array.isArray(files)) {
    files = [files];
  }

  if (files.length === 0) return undefined;
  // check if single pdf, if not we will need to concat
  if (files.length === 1 && files[0].type === 'application/pdf') {
    const file = files[0];
    //check if we need to use large content upload
    if (file.size > 4000000) {
      await uploadLargeDocumentContent(accountId, documentId, file, onUploadProgress);
      return undefined;
    } else {
      // if not large, we just upload direct
      return fileToBase64(file);
    }
  }

  await multiImageContentUpload(accountId, documentId, files, onUploadProgress);
  return undefined;
};

export const saveDocument = async (accountId: string, document: Document, files?: File | File[], onUploadProgress?: (progress: number) => void) => {
  const content = await parseFilesForUpload(accountId, document.id, files, onUploadProgress);
  const authToken = await getAuthToken();
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        if (xhr.status === 200 || xhr.status === 204) {
          resolve(xhr.status);
        } else {
          reject(xhr.status);
        }
      }
    };

    xhr.upload.onerror = () => {
      console.error('Upload failed.', xhr.responseText);
      reject(xhr.status);
    };

    xhr.upload.onprogress = (event) => {
      console.log(`Uploaded ${event.loaded} of ${event.total} bytes`);
      if (onUploadProgress && content) {
        onUploadProgress(100 * (event.loaded / event.total));
      }
    };

    xhr.open('PUT', `${getBaseApiUrl()}/v1/api/archive/${accountId}/document/${document.id}`);
    xhr.setRequestHeader('Authorization', authToken);
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.send(
      JSON.stringify({
        content,
        ...document,
      }),
    );
  });
};

export const beginAsyncContentUpload = async (accountId: string, document: Partial<Document>) => {
  const response = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/document/${document.id}?asyncUpload=true`, {
    method: 'PUT',
    headers: {
      Authorization: await getAuthToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...document,
    }),
  });
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getDocumentContent = async (accountId: string, documentid: string) => {
  // call the server so we can check auth and get our cookies set
  const response = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/document/${documentid}/content`, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    const presignedUrl = await response.text();
    return fetchS3PresignedObject(presignedUrl);
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getDocumentThumbnail = async (accountId: string, documentid: string) => {
  // call the server so we can check auth and get our cookies set
  const response = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/document/${documentid}/thumb`, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.text();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const saveDocumentContent = async (accountId: string, documentId: string, document: ArrayBuffer, onUploadProgress?: (progress: number) => void) => {
  if (document.byteLength > 4000000) {
    const content = arrayBufferToBlob(document);
    return uploadLargeDocumentContent(accountId, documentId, content, onUploadProgress);
  }
  const content = arrayBufferToBase64(document);
  const response = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/document/${documentId}/content`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAuthToken(),
    },
    body: JSON.stringify({
      accountId,
      documentId,
      content,
    }),
  });

  if (response.ok) {
    return {};
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

const uploadLargeDocumentContent = async (accountId: string, documentId: string, document: File | Blob, onUploadProgress?: (progress: number) => void) => {
  const beginResponse = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/document/${documentId}/content?largeUpload`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAuthToken(),
    },
    body: JSON.stringify({
      accountId,
      id: documentId,
    }),
  });

  const presignedPostData = await beginResponse.json();

  const form = new FormData();
  Object.keys(presignedPostData.fields).forEach((key) => {
    form.append(key, presignedPostData.fields[key]);
  });

  form.append('file', document);

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        if (xhr.status === 200 || xhr.status === 204) {
          resolve(xhr.status);
        } else {
          reject(xhr.status);
        }
      }
    };
    xhr.upload.onerror = () => {
      console.error('Upload failed.', xhr.responseText);
      reject(xhr.status);
    };

    xhr.upload.onprogress = (event) => {
      console.log(`Uploaded ${event.loaded} of ${event.total} bytes`);
      if (onUploadProgress) {
        onUploadProgress(100 * (event.loaded / event.total));
      }
    };

    xhr.open('POST', presignedPostData.url);

    xhr.send(form);
  });
};

export const getAnnotations = async (accountId: string, documentId: string) => {
  const response = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/document/annotations/${documentId}`, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const saveAnnotations = async (accountId: string, documentId: string, annotationJSON: any) => {
  const response = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/document/annotations/${documentId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAuthToken(),
    },
    body: JSON.stringify({
      annotationJSON,
    }),
  });
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const fetchS3PresignedObject = async (url: string) => {
  const response = await fetch(url, {
    method: 'GET',
  });

  if (response.ok) {
    return response.arrayBuffer();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        const content = reader.result as string;
        resolve(content.split(',')[1]);
      }
    };
    reader.onerror = (error) => reject(error);
  });
};

export const concatAndPrint = async (accountId: string, documentIds: string[]) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ accountId, documentIds: JSON.stringify(documentIds) }),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/document/concat`, requestOptions);
  if (response.ok) {
    return response.text();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};
