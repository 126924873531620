import { doOrganizerLookup } from '../api/organizer';
import { toastError } from '../toast';

export const organizerLookup = async (accountId: string, lookupValue: string | number | Date, prevValues: Record<string, string | number | Date>) => {
  if (typeof lookupValue !== 'string' && typeof lookupValue !== 'number') {
    console.error('Primary index value is a date, this should not be able to happen');
    return;
  }
  const lookupResult = await toastError(doOrganizerLookup(accountId, lookupValue), 'Lookup found no results...');
  if (!lookupResult) return;
  return { ...prevValues, ...lookupResult };
};
