import React, { useState, FormEvent } from 'react';
import { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { DocumentIndex, DocumentType, DocumentTypeStatus } from '../../types/meta';
import AccessControl from '../../users/userprofile/accesscontrol';
import styles from '../../styles/archiveui.module.css';
import { TypeModalIndexTable } from './TypeDetailIndexTable';

interface IndexModalProps {
  modal: boolean;
  toggle: () => void;
  newType: boolean;
  docType?: DocumentType;
  saveMetaHandler: (docType: DocumentType, newDocType: boolean) => void;
  indexes: DocumentIndex[];
  accountId: string;
}

const TypeModal = ({ modal, toggle, newType, docType, saveMetaHandler, indexes, accountId }: IndexModalProps) => {
  const documentTypeStatus: DocumentTypeStatus = 'active';
  const [docTypeDetail, setDocTypeDetail] = useState<DocumentType>({
    accountId: '',
    id: '',
    name: '',
    description: '',
    created: new Date(),
    modified: new Date(),
    status: documentTypeStatus as DocumentTypeStatus,
    shortCode: '',
    requiredIndexes: [''],
    hideIndexes: [],
    order: 0,
  });
  const [touched, setTouched] = useState({
    name: false,
    description: false,
    status: false,
    shortCode: false,
  });

  const setInitialState = () => {
    if (docType?.id != null) {
      setDocTypeDetail({
        ...docTypeDetail,
        accountId: docType.accountId,
        id: docType.id,
        name: docType.name,
        description: docType.description,
        created: docType.created,
        modified: docType.modified,
        status: docType.status,
        shortCode: docType.shortCode,
        requiredIndexes: docType.requiredIndexes,
        hideIndexes: docType.hideIndexes,
        order: docType.order,
      });
    } else {
      console.log(docTypeDetail.requiredIndexes);
    }
  };

  useEffect(() => {
    setInitialState();
  }, [modal]);

  const addToPartialType = (key: string, value: any) => {
    setDocTypeDetail((prevValues) => {
      return { ...prevValues, [key]: value };
    });
  };

  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  const validate = (name: string | undefined, description: string | undefined, status: string | undefined, shortCode: string | undefined) => {
    const errors = {
      name: '',
      description: '',
      status: '',
      shortCode: '',
    };

    if (name != undefined) {
      if (touched.name && name.trim().length < 1) {
        errors.name = 'Type Name is required';
      }
    }
    if (description != undefined) {
      if (touched.description && description.trim().length < 1) {
        errors.description = 'Description is required';
      }
    }
    if (status != undefined) {
      if (touched.status && status.trim().length < 1) {
        errors.status = 'Status is required';
      }
    }
    if (shortCode != undefined) {
      if (touched.shortCode && shortCode.trim().length < 1) {
        errors.shortCode = 'Short Code is required';
      }
    }
    return errors;
  };

  const formErrors = validate(docTypeDetail?.name, docTypeDetail?.description, docTypeDetail?.status, docTypeDetail.shortCode);
  const clearForm = () => {
    setDocTypeDetail({
      accountId: '',
      id: '',
      name: '',
      description: '',
      created: new Date(),
      modified: new Date(),
      status: documentTypeStatus,
      shortCode: '',
      requiredIndexes: [''],
      hideIndexes: [],
      order: 0,
    });
    setTouched({ name: false, description: false, status: false, shortCode: false });
  };

  const toggleRequiredIndexs = (indexId: string) => {
    const reqIndexesRef: string[] = docTypeDetail.requiredIndexes ?? [];
    if (reqIndexesRef.includes(indexId)) {
      const indexOfElement = reqIndexesRef.indexOf(indexId);
      reqIndexesRef.splice(indexOfElement, 1);
    } else {
      reqIndexesRef.push(indexId);
    }
    setDocTypeDetail((prevValues) => {
      return { ...prevValues, requiredIndexes: reqIndexesRef };
    });
  };

  const toggleHideIndexes = (indexId: string) => {
    const hideIndexesRef: string[] = docTypeDetail.hideIndexes ?? [];
    if (hideIndexesRef.includes(indexId)) {
      const indexOfElement = hideIndexesRef.indexOf(indexId);
      hideIndexesRef.splice(indexOfElement, 1);
    } else {
      hideIndexesRef.push(indexId);
    }
    setDocTypeDetail((prevValues) => {
      return { ...prevValues, hideIndexes: hideIndexesRef };
    });
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader style={{ backgroundColor: '#ceeaf4' }} toggle={toggle}>
          {newType ? 'Add Type' : 'Edit Type'}
        </ModalHeader>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            validate(docTypeDetail.name, docTypeDetail.description, docTypeDetail.status, docTypeDetail.shortCode);
            clearForm();
            toggle();
            saveMetaHandler(docTypeDetail, newType);
          }}
        >
          <ModalBody style={{ backgroundColor: '#F4F4F4' }}>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    id="givenName"
                    aria-label="Given Name"
                    value={docTypeDetail?.name}
                    invalid={formErrors.name !== ''}
                    onBlur={() => handleBlur('name')}
                    onChange={(e) => {
                      const re = /^[a-z\d\-_\s]+$/i;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        addToPartialType('name', e.target.value);
                      }
                    }}
                    required
                  />
                  <FormFeedback>{formErrors.name}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="status">Status</Label>
                  <Input
                    type="select"
                    name="status"
                    id="status"
                    value={docTypeDetail?.status}
                    invalid={formErrors.status !== ''}
                    onBlur={() => handleBlur('status')}
                    onChange={(e) => {
                      const re = /^[a-z\d\-_\s]+$/i;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        addToPartialType('status', e.target.value);
                      }
                    }}
                    required={true}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="deleted">Deleted</option>
                  </Input>
                  <FormFeedback>{formErrors.status}</FormFeedback>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="shortCode">Short Code</Label>
                  <Input
                    type="text"
                    name="shortCode"
                    id="shortCode"
                    value={docTypeDetail?.shortCode}
                    invalid={formErrors.shortCode !== ''}
                    onBlur={() => handleBlur('shortCode')}
                    onChange={(e) => {
                      const re = /^[a-z\d\-_\s]+$/i;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        addToPartialType('shortCode', e.target.value.toLocaleUpperCase());
                      }
                    }}
                  />
                  <FormFeedback>{formErrors.shortCode}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <TypeModalIndexTable
                documentIndexes={indexes}
                onRequiredToggle={toggleRequiredIndexs}
                onHideToggle={toggleHideIndexes}
                requiredIndexes={docTypeDetail.requiredIndexes}
                hideIndexes={docTypeDetail.hideIndexes ?? []}
              />
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    value={docTypeDetail?.description}
                    invalid={formErrors.description !== ''}
                    onBlur={() => handleBlur('description')}
                    onChange={(e) => {
                      const re = /^[a-z\d\-_\s]+$/i;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        addToPartialType('description', e.target.value);
                      }
                    }}
                  />
                  <FormFeedback>{formErrors.description}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{ backgroundColor: '#F4F4F4' }}>
            <Button className={styles.scrbBtnBlue} onClick={toggle}>
              Cancel
            </Button>{' '}
            <AccessControl permissionId={'meta'} action={'create'}>
              <Button type="submit" className={styles.scrbBtnOrange}>
                Save
              </Button>
            </AccessControl>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default TypeModal;
