import React from 'react';
import { PermissionStatus } from '../../types/role';
import { useMetaProvider } from '../../contexts/MetaContext';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { MdCheckCircle } from 'react-icons/md';
import { BsFillXCircleFill } from 'react-icons/bs';

interface PermissionDisplayProps {
  permissions: Record<string, PermissionStatus>;
}

const TypePermissionDisplay = ({ permissions }: PermissionDisplayProps) => {
  const documentTypes = useMetaProvider().metaState.documentTypes;
  return (
    <ListGroup>
      {documentTypes.map((type) => {
        const isAllowed = permissions && permissions[type.id] && permissions[type.id] === 'ALLOW';
        return (
          <ListGroupItem key={`typeDisplay_${type.id}`} style={{ backgroundColor: isAllowed ? '#c6f7cc' : '#ffccd7' }}>
            {type.name}
            {isAllowed ? <MdCheckCircle style={{ float: 'right', color: '#06942e' }} /> : <BsFillXCircleFill style={{ float: 'right', color: '#f53863' }} />}
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
};

export default TypePermissionDisplay;
