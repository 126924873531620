import React, { useEffect, useState } from 'react';
import DWT from '.';
import { getBaseApiUrl } from '../../api/helpers';
import { MetaProvider } from '../../contexts/MetaContext';
import { Loading } from '../../Loading';
import Toast, { toastError } from '../../toast';

export const EmbeddedScanner = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const accountId = params.get('tenantId') ?? '';
  const username = params.get('username') ?? '';
  const token = params.get('token') ?? '';
  const indexes = params.get('indexes');
  let indexValues = undefined;
  if (indexes) {
    indexValues = JSON.parse(indexes) as Record<string, string | Date | number>;
  }
  const type = params.get('type') ?? undefined;

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  useEffect(() => {
    const authenticate = async () => {
      const url = `${getBaseApiUrl()}/v1/api/archive/${accountId}/auth`;
      const response = await toastError(
        fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        'Unable to authorize user',
      );
      if (response.ok) {
        localStorage.setItem('integration_token', token);
        localStorage.setItem('integration_account', accountId);
        setAuthenticated(true);
      }
    };
    authenticate();
  }, []);
  return authenticated ? (
    <MetaProvider>
      <DWT userEmail={username} token={token} presetIndexValues={indexValues} presetType={type} accountId={accountId} />
      <Toast />
    </MetaProvider>
  ) : (
    <>
      <Loading />
    </>
  );
};

export default EmbeddedScanner;
