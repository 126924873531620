import React from 'react';
import { Column, useTable } from 'react-table';
import { Input, Table } from 'reactstrap';
import { useMetaProvider } from '../contexts/MetaContext';
import { ISIndexField, ISIndexType } from '../types/imagesilo';
import styles from '../styles/archiveui.module.css';

interface IndexTableProps {
  imageSiloIndexes: ISIndexField[];
  curIndexMapping: Record<string, string>;
  onMappingSelect: (isIndexName: string, scribonlineIndexId: string) => void;
}
const ISIndexTable = ({ imageSiloIndexes, curIndexMapping, onMappingSelect }: IndexTableProps) => {
  const scribonlineIndexes = useMetaProvider().metaState.documentIndexes;
  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Image Silo Index',
          accessor: 'name',
          Cell: (cellProps) => {
            if (cellProps.value) {
              return <span>{cellProps.value}</span>;
            } else {
              return <span></span>;
            }
          },
        },
        { Header: 'Type', accessor: 'type' },
        {
          Header: 'Maps To',
          Cell: (cellProps: any) => {
            const isIndexType: ISIndexType = cellProps.row.original.type;
            return (
              <span>
                <Input
                  value={curIndexMapping[cellProps.row.original.name]}
                  type="select"
                  onChange={(e) => {
                    onMappingSelect(cellProps.row.original.name, e.target.value);
                  }}
                >
                  <option></option>
                  {scribonlineIndexes
                    .filter((index) => {
                      if (isIndexType === 'Text' || isIndexType === 'Long Text') {
                        return index.type === 'string' || index.type === 'picklist' || index.type === 'picklistWithOther';
                      } else if (isIndexType === 'Date') {
                        return index.type === 'date';
                      } else {
                        return index.type === 'string' || index.type === 'number' || index.type === 'picklist' || index.type === 'picklistWithOther';
                      }
                    })
                    .map((index) => {
                      return (
                        <option key={index.id} value={index.id}>
                          {index.name}
                        </option>
                      );
                    })}
                </Input>
              </span>
            );
          },
        },
      ] as Column<ISIndexField>[],
    [imageSiloIndexes, scribonlineIndexes],
  );

  const data = React.useMemo(() => imageSiloIndexes, [imageSiloIndexes]);
  const { headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <Table striped hover size="sm" id="auditTable" responsive>
      <thead className={styles.tableHeader}>
        {headerGroups.map((headerGroup) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                if (column.Header === 'Original') {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <th {...column.getHeaderProps()} className={styles.IdHeader}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                    </th>
                  );
                } else {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <th {...column.getHeaderProps()} className={styles.th}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                    </th>
                  );
                }
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            // eslint-disable-next-line react/jsx-key
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                if (cell.column.Header === 'Original') {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <td style={{ paddingLeft: '1.25em' }} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                } else {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ISIndexTable;
