import React, { useState, FormEvent } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Label, Input } from 'reactstrap';
import { DocumentTypeGroup, DocumentTypeStatus } from '../../types/meta';
import AccessControl from '../../users/userprofile/accesscontrol';
import styles from '../../styles/archiveui.module.css';

interface IndexModalProps {
  modal: boolean;
  toggle: () => void;
  saveGroupHandler: (documentTypeGroup: DocumentTypeGroup) => void;
}

const GroupModal = ({ modal, toggle, saveGroupHandler }: IndexModalProps) => {
  const [curGroup, setCurGroup] = useState<DocumentTypeGroup>({ heading: '', types: [], id: '', accountId: '', order: 0, status: 'ACTIVE' });
  const resetModal = () => {
    setCurGroup({
      heading: '',
      types: [],
      id: '',
      accountId: '',
      order: 0,
      status: 'ACTIVE',
    });
  };

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader style={{ backgroundColor: '#ceeaf4' }} toggle={toggle}>
        Document Type Group
      </ModalHeader>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          saveGroupHandler(curGroup);
          toggle();
          resetModal();
        }}
      >
        <ModalBody style={{ backgroundColor: '#F4F4F4' }}>
          <Label>Heading</Label>
          <Input
            type="text"
            value={curGroup.heading}
            required
            onChange={(e) => {
              setCurGroup({ ...curGroup, heading: e.target.value });
            }}
          />
        </ModalBody>
        <ModalFooter style={{ backgroundColor: '#F4F4F4' }}>
          <Button className={styles.scrbBtnBlue} onClick={toggle}>
            Cancel
          </Button>{' '}
          <AccessControl permissionId={'meta'} action={'create'}>
            <Button type="submit" className={styles.scrbBtnOrange}>
              Save
            </Button>
          </AccessControl>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default GroupModal;
