import React from 'react';
import styles from '../../styles/archiveui.module.css';
import { RefObject } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface confirmDeleteProps {
  modal: boolean;
  toggle: () => void;
  onConfirm: () => void | Promise<void>;
  ref?: RefObject<HTMLDivElement>;
  headerText?: string;
  bodyText?: string;
}

const ConfirmDelete = ({ modal, toggle, onConfirm, ref, headerText, bodyText }: confirmDeleteProps) => {
  return (
    <div ref={ref}>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{headerText ?? 'Confirm Delete'}</ModalHeader>
        <ModalBody>{bodyText ?? 'Are you sure you want to delete?'}</ModalBody>
        <ModalFooter>
          <Button className={styles.scrbBtnBlue} onClick={toggle}>
            Cancel
          </Button>{' '}
          <Button color="danger" onClick={onConfirm}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmDelete;
