import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useUserContext } from './UserContext';

interface Cart {
  cartItems: string[];
  addToCart: (documents: string | string[]) => void;
  removeFromCart: (documentId: string) => void;
  emptyCart: () => void;
}

export const CartContext = createContext<Cart | undefined>(undefined);

interface CartProviderProps {
  children: React.ReactNode;
}

export const CartProvider = ({ children }: CartProviderProps) => {
  const [cartItems, setItems] = useState<string[]>([]);
  const { accountId } = useUserContext().currentAccount;

  useEffect(() => {
    const storedCart = localStorage.getItem(`archive_cart_${accountId}`);
    if (storedCart) {
      try {
        setItems(JSON.parse(storedCart));
        return;
      } catch (e) {
        console.log('unable to parse stored cart', e);
      }
    }
    setItems([]);
  }, [accountId]);

  const addToCart = (documents: string | string[]) => {
    const docsToAdd = typeof documents === 'string' ? [documents] : documents;
    setItems((prevItems) => {
      docsToAdd.filter((doc) => {
        return !prevItems.includes(doc);
      });
      const newCart = [...prevItems, ...docsToAdd];
      localStorage.setItem(`archive_cart_${accountId}`, JSON.stringify(newCart));
      return newCart;
    });
  };

  const removeFromCart = (documentId: string) => {
    setItems((cartItems) => {
      const newCart = cartItems.filter((item) => item !== documentId);
      return newCart;
    });
  };

  const emptyCart = () => {
    setItems([]);
  };

  useEffect(() => {
    localStorage.setItem(`archive_cart_${accountId}`, JSON.stringify(cartItems));
  }, [cartItems]);

  const value = useMemo(
    () => ({
      cartItems,
      removeFromCart,
      addToCart,
      emptyCart,
    }),
    [cartItems],
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCartContext = () => {
  const context = React.useContext(CartContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};
