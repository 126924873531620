import { FullImageSiloBulkload } from '../types/imagesilo';
import { getAuthToken, getBaseApiUrl } from './helpers';

export const uploadImageSiloBatchFile = async (accountId: string, batchId: string, batchFile: File | Blob, onUploadProgress?: (progress: number) => void) => {
  const beginResponse = await fetch(`${getBaseApiUrl()}/v1/api/archive/${accountId}/imagesilo/conversion/${batchId}`, {
    method: 'PUT',
    headers: {
      Authorization: await getAuthToken(),
    },
  });

  const presignedPostData = await beginResponse.json();

  const form = new FormData();
  Object.keys(presignedPostData.fields).forEach((key) => {
    form.append(key, presignedPostData.fields[key]);
  });

  form.append('file', batchFile);

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        if (xhr.status === 200 || xhr.status === 204) {
          console.log('resolving now');
          resolve(xhr.status);
        } else {
          reject(xhr.status);
        }
      }
    };
    xhr.upload.onerror = () => {
      console.error('Upload failed.', xhr.responseText);
      reject(xhr.status);
    };

    xhr.upload.onprogress = (event) => {
      console.log(`Uploaded ${event.loaded} of ${event.total} bytes`);
      if (onUploadProgress) {
        onUploadProgress(100 * (event.loaded / event.total));
      }
    };

    xhr.open('POST', presignedPostData.url);

    xhr.send(form);
  });
};

export const submitImageSiloBatch = async (accountId: string, batchId: string, batch: FullImageSiloBulkload): Promise<void> => {
  const url = `${getBaseApiUrl()}/v1/api/archive/${accountId}/imagesilo/conversion/${batchId}`;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(batch),
  };
  const response = await fetch(url, requestOptions);
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};
