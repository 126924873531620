import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { Card, CardBody, Form, Row, Col, FormGroup, Input, Button, UncontrolledTooltip } from 'reactstrap';
import styles from '../../../styles/archiveui.module.css';
import { getAccount } from '../../../api/accounts';
import { StatusFilter } from '../apiuserslist';
import { Link } from 'react-router-dom';

interface searchBarProps {
  updateSearchText: Dispatch<SetStateAction<string>>;
  searchText: string;
  accountId: string;
  statusFilter: string;
  updateStatusFilter: Dispatch<SetStateAction<string>>;
}

const SearchBar = ({ updateSearchText, searchText, accountId, statusFilter, updateStatusFilter }: searchBarProps): JSX.Element => {
  const [accountName, setaccountName] = useState('');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const clearFilters = () => {
    updateSearchText('');
  };

  useEffect(() => {
    const getCurrentAccount = async () => {
      const currentAccount = await getAccount(accountId);
      setaccountName(currentAccount.name);
    };
    getCurrentAccount();
  });

  return (
    <div>
      <Card style={{ marginTop: '1em', borderRadius: '.5em', paddingTop: '0em' }}>
        <CardBody className={styles.searchBarHeader}>
          <div>
            <h5 className={styles.searchBarTitle}>API Users for {accountName}</h5>
          </div>
        </CardBody>
        <CardBody className={styles.searchBarBody}>
          <Form>
            <Row form>
              <Col>
                <FormGroup>
                  <Input type="text" id="search" placeholder="Search Name, ID" value={searchText} onChange={(e) => updateSearchText(e.target.value)} />
                </FormGroup>
              </Col>

              <Col md="2">
                <FormGroup>
                  <Input
                    value={statusFilter}
                    type="select"
                    name="status"
                    id="status"
                    onChange={(e) => updateStatusFilter(e.target.value as StatusFilter)}
                    required
                  >
                    <option value="ACTIVE">Active</option>

                    <option value="INACTIVE">Inactive</option>

                    <option value="*">All</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md=".1">
                <Button id="clearFilterBtn" aria-label="Clear Filter Button" className={styles.filterBtn} onClick={clearFilters}>
                  <MdClear />
                </Button>
                <UncontrolledTooltip target="clearFilterBtn">Clear Search</UncontrolledTooltip>
                <Link to={`/apiuserdetail/${accountId}`}>
                  <Button id="scrbBtnBlueMargin" className={styles.scrbBtnOrange} onClick={toggle}>
                    Add API User
                  </Button>
                </Link>
                <UncontrolledTooltip target="scrbBtnBlueMargin">Add API User</UncontrolledTooltip>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default SearchBar;
