import React, { FormEvent, useMemo } from 'react';
import { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { IoIosAdd } from 'react-icons/io';
import { HiOutlineMinusSm } from 'react-icons/hi';
import { DocumentIndex, IndexType, IndexMeta, PicklistItem } from '../../types/meta';
import styles from '../../styles/archiveui.module.css';
import AccessControl from '../../users/userprofile/accesscontrol';

interface IndexModalProps {
  modal: boolean;
  toggle: () => void;
  docIndex?: DocumentIndex;
  saveDocIndexMetaHandler: (docIndex: DocumentIndex, newIndex: boolean) => void;
  accountId: string;
  documentIndexes: DocumentIndex[];
}

const IndexModal = ({ modal, toggle, docIndex, saveDocIndexMetaHandler, accountId, documentIndexes }: IndexModalProps) => {
  const newIndexType: IndexType = 'string';
  const newIndexMeta: IndexMeta[] = [];
  const [pickListType, setpickListType] = useState(false);
  const [pickList, setpickList] = useState<PicklistItem[]>([]);
  const [indexTypeDetail, setIndexTypeDetail] = useState({
    accountId: '',
    id: '',
    name: '',
    description: '',
    created: new Date(),
    modified: new Date(),
    type: newIndexType as IndexType,
    meta: newIndexMeta,
    picklistItems: pickList,
    dbname: '',
    indexRequired: false,
  });
  const [touched, setTouched] = useState({
    name: false,
    description: false,
    type: false,
    dbname: false,
  });
  const [errors, setErrors] = useState({
    name: '',
    description: '',
    type: '',
    dbname: '',
  });
  const setInitialState = () => {
    if (docIndex?.id != null) {
      setIndexTypeDetail({
        ...indexTypeDetail,
        ...docIndex,
      });
      setpickList(docIndex.picklistItems);
    } else {
      setpickList([]);
    }
  };
  useEffect(() => {
    clearForm();
  }, [docIndex]);

  useEffect(() => {
    setInitialState();
  }, [modal, docIndex]);

  const addToPartialIndex = (key: string, value: any) => {
    setIndexTypeDetail((prevValues) => {
      return { ...prevValues, [key]: value };
    });
  };

  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  const validate = (name?: string, description?: string, type?: string, dbname?: string) => {
    const newErrors = {
      name: '',
      description: '',
      type: '',
      dbname: '',
    };
    if (!name || name.trim().length < 1) {
      newErrors.name = 'Type Name is required';
    }

    if (!description || description.trim().length < 1) {
      newErrors.description = 'Description is required';
    }

    if (!type || type.trim().length < 1) {
      newErrors.type = 'Type is required';
    }

    if (!dbname || dbname.trim().length < 1) {
      newErrors.dbname = 'DB Name is required';
    } else {
      documentIndexes.forEach((documentIndex) => {
        if (documentIndex.id === docIndex?.id) {
          return;
        }
        if (documentIndex.dbname && documentIndex.dbname.trim() === dbname.trim()) {
          newErrors.dbname = 'DB Name is already taken';
        }
      });
    }

    setErrors(newErrors);

    return !newErrors.name && !newErrors.dbname && !newErrors.type && !newErrors.description;
  };

  const clearForm = () => {
    setIndexTypeDetail({
      accountId: '',
      id: '',
      name: '',
      description: '',
      created: new Date(),
      modified: new Date(),
      type: newIndexType,
      meta: newIndexMeta,
      picklistItems: pickList,
      dbname: '',
      indexRequired: false,
    });
    setTouched({ name: false, description: false, type: false, dbname: false });
    setErrors({
      name: '',
      description: '',
      type: '',
      dbname: '',
    });
  };

  let renderedTypeInput;

  if (docIndex?.id != null) {
    renderedTypeInput = <Input style={{ textTransform: 'uppercase' }} value={indexTypeDetail?.type} type="text" name="type" id="type" readOnly />;
  } else {
    renderedTypeInput = (
      <div style={{ paddingLeft: '.5em' }}>
        <FormGroup check>
          <Label check>
            <Input
              value="string"
              onChange={(e) => {
                addToPartialIndex('type', e.target.value);
                if (e.target.value === 'picklist') {
                  setpickListType(true);
                } else {
                  setpickListType(false);
                }
              }}
              type="radio"
              name="typeradio"
            />
            String - sequence of characters
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              value="date"
              onChange={(e) => {
                addToPartialIndex('type', e.target.value);
                if (e.target.value === 'picklist') {
                  setpickListType(true);
                } else {
                  setpickListType(false);
                }
              }}
              type="radio"
              name="typeradio"
            />
            Date - date on the calendar
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              value="number"
              onChange={(e) => {
                addToPartialIndex('type', e.target.value);
                if (e.target.value === 'picklist') {
                  setpickListType(true);
                } else {
                  setpickListType(false);
                }
              }}
              type="radio"
              name="typeradio"
            />
            Number - quantity or amount
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              value="picklist"
              onChange={(e) => {
                addToPartialIndex('type', e.target.value);
                if (e.target.value === 'picklist') {
                  setpickListType(true);
                } else {
                  setpickListType(false);
                }
              }}
              type="radio"
              name="typeradio"
            />
            Pick List - pairs of labels and values
          </Label>
        </FormGroup>
      </div>
      // <Input
      //   style={{ textTransform: 'uppercase' }}
      //   value={indexTypeDetail?.type}
      //   type="select"
      //   name="type"
      //   id="type"
      //   required={true}
      //   invalid={formErrors.type !== ''}
      //   onBlur={() => handleBlur('type')}
      //   onChange={(e) => {
      //     addToPartialIndex('type', e.target.value);
      //     if (e.target.value === 'picklist') {
      //       setpickListType(true);
      //     } else {
      //       setpickListType(false);
      //     }
      //   }}
      // >
      //   <option></option>
      //   <option value="string">STRING</option>
      //   <option value="date">DATE</option>
      //   <option value="number">NUMBER</option>
      //   <option value="picklist">PICK LIST</option>
      // </Input>
    );
  }

  const updateKeyValue = (index: number, value: string, key: boolean) => {
    const pickListCopy = pickList;
    if (key) {
      pickListCopy[index].key = value;
    } else {
      pickListCopy[index].value = value;
    }
    addToPartialIndex('picklistItems', pickListCopy);
  };

  const renderedpickListTypeJSX = (
    <div>
      {pickList?.map((keyValue, key) => {
        return (
          <Row key={key} form>
            <Col>
              <FormGroup>
                <Input
                  name="key"
                  type="text"
                  placeholder="KEY"
                  required
                  value={keyValue.key}
                  style={{ fontSize: '0.72rem' }}
                  onChange={(event) => {
                    updateKeyValue(key, event.target.value, true);
                  }}
                />
                <FormFeedback>{}</FormFeedback>
              </FormGroup>
            </Col>
            <div className={styles.colonContainer}>:</div>
            <Col>
              <FormGroup>
                <Input
                  name="value"
                  type="text"
                  placeholder="VALUE"
                  required
                  value={keyValue.value}
                  style={{ fontSize: '0.72rem' }}
                  onChange={(event) => {
                    updateKeyValue(key, event.target.value, false);
                  }}
                />
                <FormFeedback>{}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="1" style={{ paddingLeft: '3px' }}>
              <Button
                size="sm"
                className={styles.scrbBtnRed}
                onClick={() => {
                  const newPickList = [...pickList];
                  newPickList.splice(key, 1);
                  setpickList(newPickList);
                  addToPartialIndex('picklistItems', newPickList);
                }}
              >
                <HiOutlineMinusSm style={{ color: 'white' }} />
              </Button>
            </Col>
          </Row>
        );
      })}
    </div>
  );

  const newIndex = useMemo(() => {
    return !docIndex;
  }, [docIndex]);

  return (
    <div>
      <Modal toggle={toggle} isOpen={modal}>
        <ModalHeader style={{ backgroundColor: '#ceeaf4' }} toggle={toggle}>
          {newIndex ? 'Add Index' : 'Edit Index'}
        </ModalHeader>
        <Form
          id="typeForm"
          onSubmit={(event) => {
            event.preventDefault();
            if (validate(indexTypeDetail.name, indexTypeDetail.description, indexTypeDetail.type, indexTypeDetail.dbname)) {
              console.log('submit');
              saveDocIndexMetaHandler(indexTypeDetail, newIndex);
              clearForm();
              toggle();
            }
          }}
        >
          <ModalBody style={{ backgroundColor: '#F4F4F4' }}>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    value={indexTypeDetail?.name}
                    type="text"
                    name="givenName"
                    id="givenName"
                    aria-label="Given Name"
                    invalid={errors.name !== ''}
                    onBlur={() => handleBlur('name')}
                    onChange={(e) => {
                      const re = /^[a-z\d\-_\s]+$/i;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        addToPartialIndex('name', e.target.value);
                      }
                    }}
                    required={true}
                  />
                  <FormFeedback>{errors.name}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="name">DB Name</Label>
                  <Input
                    value={indexTypeDetail?.dbname}
                    type="text"
                    name="dbName"
                    id="dbName"
                    invalid={errors.dbname !== ''}
                    onBlur={() => handleBlur('dbname')}
                    onChange={(e) => {
                      const re = /^[a-z\d\-_\s]+$/i;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        addToPartialIndex('dbname', e.target.value);
                      }
                    }}
                    required={true}
                  />
                  <FormFeedback>{errors.dbname}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="type">Type</Label>
                  {renderedTypeInput}
                  <FormFeedback>{errors.type}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ display: pickListType || docIndex?.type === 'picklist' ? 'flex' : 'none' }}>
              <Col className={styles.keyValueHeader}>
                <h6>PickList</h6>
                <div>
                  <Button
                    className={styles.scrbBtnOrange}
                    size="sm"
                    style={{ marginLeft: '.5em' }}
                    onClick={() => {
                      setpickList((pickListItems) => [...pickListItems, { key: '', value: '', order: 32000 }]);
                      addToPartialIndex('picklistItems', pickList);
                    }}
                  >
                    <IoIosAdd style={{ color: 'white' }} />
                  </Button>
                </div>
              </Col>
            </Row>
            {pickListType || docIndex?.type === 'picklist' ? renderedpickListTypeJSX : ''}
            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={indexTypeDetail?.indexRequired}
                      onChange={(e) => {
                        addToPartialIndex('indexRequired', e.target.checked);
                      }}
                    />{' '}
                    Index required
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    value={indexTypeDetail?.description}
                    type="textarea"
                    name="description"
                    id="description"
                    invalid={errors.description !== ''}
                    onBlur={() => handleBlur('description')}
                    onChange={(e) => {
                      const re = /^[a-z\d\-_\s]+$/i;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        addToPartialIndex('description', e.target.value);
                      }
                    }}
                  />
                  <FormFeedback>{errors.description}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{ backgroundColor: '#F4F4F4' }}>
            <Button className={styles.scrbBtnBlue} onClick={toggle}>
              Cancel
            </Button>{' '}
            <AccessControl permissionId={'meta'} action={'create'}>
              <Button type="submit" className={styles.scrbBtnOrange}>
                Save
              </Button>
            </AccessControl>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default IndexModal;
