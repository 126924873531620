import React, { Dispatch, SetStateAction } from 'react';
import { MdClear } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import { Card, CardBody, Form, Row, Col, FormGroup, Input, Button, UncontrolledTooltip } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import { Link } from 'react-router-dom';
import AccessControl from '../../users/userprofile/accesscontrol';

interface searchBarProps {
  updateSearchText: Dispatch<SetStateAction<string>>;
  searchText: string;
}

const SearchBar = ({ updateSearchText, searchText }: searchBarProps): JSX.Element => {
  const clearFilters = () => {
    updateSearchText('');
  };

  return (
    <div>
      <Card style={{ marginTop: '1em', borderRadius: '.5em', paddingTop: '0em' }}>
        <CardBody className={styles.searchBarHeader}>
          <div>
            <h5 style={{ textAlign: 'start', fontWeight: 500, marginTop: '.5em' }}>Roles</h5>
          </div>
        </CardBody>
        <CardBody className={styles.searchBarBody}>
          <Form>
            <Row form>
              <AccessControl permissionId={'roles'} action={'read'}>
                <Col>
                  <FormGroup>
                    <Input
                      type="text"
                      id="search"
                      placeholder="Search Name, ID"
                      value={searchText}
                      onChange={(e) => updateSearchText(e.target.value.toLowerCase())}
                    />
                  </FormGroup>
                </Col>
              </AccessControl>
              <Col md=".1">
                <AccessControl permissionId={'roles'} action={'read'}>
                  <>
                    <Button id="clearFilterBtn" aria-label="Clear Filter Button" className={styles.filterBtn} onClick={clearFilters}>
                      <MdClear />
                    </Button>
                    <UncontrolledTooltip target="clearFilterBtn">Clear Search</UncontrolledTooltip>
                  </>
                </AccessControl>
                <AccessControl permissionId={'roles'} action={'create'}>
                  <>
                    <Button id="addUserBtn" aria-label="Add User Button" className={styles.scrbBtnOrange} tag={Link} to="/roledetail" onClick={clearFilters}>
                      <BsPlus color="white" />
                    </Button>
                    <UncontrolledTooltip target="addUserBtn">Add Role</UncontrolledTooltip>
                  </>
                </AccessControl>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default SearchBar;
