import React from 'react';
import { BsPlus } from 'react-icons/bs';
import { CardBody, Form, Button } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import AccessControl from '../../users/userprofile/accesscontrol';

interface SearchBarProps {
  toggleModal: (groupId?: string) => void;
}

const GroupSearchBar = ({ toggleModal }: SearchBarProps): JSX.Element => {
  return (
    <div>
      <div className={styles.filtersCard}>
        <CardBody className={styles.searchBarBody}>
          <Form className={styles.metaForm}>
            <AccessControl permissionId={'meta'} action={'create'}>
              <Button className={styles.scrbBtnBlueMargin} onClick={() => toggleModal()}>
                <BsPlus />
              </Button>
            </AccessControl>
          </Form>
        </CardBody>
      </div>
    </div>
  );
};

export default GroupSearchBar;
