import React, { PropsWithChildren, RefObject, useRef } from 'react';
import { ScanDocument } from '../../types/scanner';
import styles from '../../styles/archiveui.module.css';
import { AiFillEdit } from 'react-icons/ai';
import useOnScreen from '../../componentutils/useonscreen';

interface DocumentContainerProps {
  document: ScanDocument;
  isSelected: boolean;
  updateDocumentContainerRefs: (id: string, ref: RefObject<HTMLDivElement>, isInView: boolean) => void;
  selectDocument: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => void;
  editDocumentMeta: (docId: string, event: React.MouseEvent<SVGElement, MouseEvent>) => void;
  index: number;
  isExistingDocument: boolean;
  error?: string;
}

export const DocumentContainer = ({
  children,
  document,
  isSelected,
  updateDocumentContainerRefs,
  error,
  selectDocument,
  index,
  editDocumentMeta,
  isExistingDocument,
}: PropsWithChildren<DocumentContainerProps>) => {
  const selectedClass = isSelected ? styles.documentContainerSelected : styles.documentContainer;
  const documentContainerRef = useRef<HTMLDivElement>(null);
  const isInView = useOnScreen(documentContainerRef);
  updateDocumentContainerRefs(document.releaseDocument.id, documentContainerRef, isInView);
  return (
    <div
      className={error ? styles.documentContainerError : selectedClass}
      ref={documentContainerRef}
      onClick={(e) => selectDocument(e, document.releaseDocument.id)}
    >
      {!isExistingDocument && (
        <>
          <h5 style={{ marginTop: '2%', marginLeft: '10%', marginRight: '28%', display: 'inline-block' }}>{`Document ${index + 1}`}</h5>
          <AiFillEdit
            id="edit"
            color="#212529"
            style={{ display: 'inline-block', cursor: 'pointer' }}
            onClick={(e) => editDocumentMeta(document.releaseDocument.id, e)}
          />
        </>
      )}
      {error && <h6 style={{ marginTop: '2%', marginLeft: '10%' }}>{error}</h6>}
      {children}
    </div>
  );
};
