import * as PapaParse from 'papaparse';
import { DocumentType } from '../types/meta';
import { ImportGroup } from '../types/scribonline';
import JsZip from 'jszip';
import { parseImageSiloXmlToDocuments, parseImageSiloXmlToObject } from '../utils/imagesilo';

interface ImportRow {
  docclass: string;
  docname: string;
  documentid: string;
}
export const parseScribOnlineFile = (csvInput: string, doctypes: DocumentType[]): ImportGroup[] => {
  const rows: ImportRow[] = PapaParse.parse<ImportRow>(csvInput, {
    header: true,
    skipEmptyLines: true,
  }).data;
  const groupmap = new Map<string, ImportGroup>();
  for (const row of rows) {
    let group = groupmap.get(row.docclass);
    if (!group) {
      group = {
        originalDocumentTypeId: row.docclass,
        documentIds: [],
        originalDocName: row.docname,
        newDocumentTypeId:
          doctypes.find((type) => {
            console.log(`${type.id === row.docclass} [${type.id}] === [${row.docclass}]`);
            return type.id === row.docclass;
          })?.id || '',
      };
      groupmap.set(row.docclass, group);
    }
    group.documentIds.push(row.documentid);
  }

  return Array.from(groupmap, ([k, v]) => {
    console.log(`${JSON.stringify(v)}`);
    return v;
  });
};

export const parseImageSiloFile = async (accountId: string, file: File) => {
  const data = await JsZip.loadAsync(file);
  const xmlFiles = data.file(/datagrp/);
  const xmlHeaderFile = data.files['datagrp.xml'];
  if (!xmlHeaderFile) return;

  const xmlText = await xmlHeaderFile.async('text');
  console.log('got the text for xml', xmlHeaderFile.name);

  const bulkload = parseImageSiloXmlToObject(accountId, xmlText);
  console.log('bulkload', bulkload);
  if (!bulkload) {
    return;
  }

  //getting rest of documents
  if (xmlFiles.length > 1) {
    for (let i = 1; i < xmlFiles.length; i++) {
      const xmlFile = data.files[`datagrp-${i + 1}.xml`];
      console.log('XML FILE', xmlFile);

      const xmlFileText = await xmlFile.async('text');
      const documents = parseImageSiloXmlToDocuments(xmlFileText);

      if (documents) {
        bulkload.documents.push(...documents);
      }
    }
  }

  console.log('bulkload', bulkload);
  return bulkload;
};
