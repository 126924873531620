import React, { FormEvent, useState, useEffect, ChangeEvent } from 'react';
import { Modal, ModalHeader, Form, ModalBody, Row, Col, FormGroup, Label, Input, FormFeedback, ModalFooter, Button, InputGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import styles from '../styles/archiveui.module.css';
import { ExpirationMetric } from '.';

interface UserInviteModalProps {
  modal: boolean;
  toggle: () => void;
  onSubmit: (email: string, expirationQuantity: number, expirationMetric: ExpirationMetric) => void;
  isSubmitting?: boolean;
}

type FormError = { email: string };

const SendExternalModal = ({ modal, toggle, onSubmit, isSubmitting }: UserInviteModalProps) => {
  const [email, setEmail] = useState<string>();
  const [expirationQuantity, setExpirationQuantity] = useState<number>(1);
  const [expirationMetric, setExpirationMetric] = useState<ExpirationMetric>('days');
  const [formErrors, setFormErrors] = useState<FormError>({ email: '' });

  useEffect(() => {
    setEmail(undefined);
    setExpirationQuantity(1);
    setExpirationMetric('days');
    setFormErrors({ email: '' });
  }, [modal]);

  const validateEmail = (errors?: FormError) => {
    let isValid = true;
    let setErrorState = false;
    if (!errors) {
      errors = { ...formErrors };
      setErrorState = true;
    }

    if (!email) {
      errors.email = 'You must provide an email address';
      isValid = false;
    }

    if (
      isValid &&
      email &&
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email.trim(),
      ) === false
    ) {
      errors.email = 'Email provided is not a valid email';
      isValid = false;
    }

    if (isValid) {
      errors.email = '';
    }

    if (setErrorState) {
      setFormErrors(errors);
    }

    return isValid;
  };

  const validate = () => {
    const errors = {
      email: '',
    };
    const validEmail = validateEmail(errors);

    setFormErrors(errors);
    return validEmail;
  };

  return (
    <div>
      <Modal toggle={toggle} isOpen={modal} style={{ minWidth: '40em' }}>
        <ModalHeader style={{ backgroundColor: '#ceeaf4' }} toggle={toggle}>
          Send Externally
        </ModalHeader>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            if (validate() && email && expirationMetric && expirationQuantity) {
              onSubmit(email, expirationQuantity, expirationMetric);
            }
          }}
        >
          <ModalBody style={{ backgroundColor: '#F4F4F4' }}>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    required
                    invalid={formErrors.email !== ''}
                    onBlur={() => validateEmail()}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <FormFeedback>{formErrors.email}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Expiration</Label>
                  <InputGroup>
                    <Input
                      type="select"
                      name="expirationQuantity"
                      id="expirationQuantity"
                      value={expirationQuantity}
                      onChange={(e) => {
                        setExpirationQuantity(parseInt(e.target.value));
                      }}
                      required
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                      <option>16</option>
                      <option>17</option>
                      <option>18</option>
                      <option>19</option>
                      <option>20</option>
                      <option>21</option>
                      <option>22</option>
                      <option>23</option>
                      <option>24</option>
                      <option>25</option>
                      <option>26</option>
                      <option>27</option>
                      <option>28</option>
                      <option>29</option>
                      <option>30</option>
                    </Input>
                    <Input
                      type="select"
                      name="expirationMetric"
                      id="expirationMetric"
                      value={expirationMetric}
                      onChange={(e) => {
                        setExpirationMetric(e.target.value as ExpirationMetric);
                      }}
                      required
                    >
                      <option value="days">Day(s)</option>
                      <option value="weeks">Week(s)</option>
                      <option value="months">Month(s)</option>
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{ backgroundColor: '#F4F4F4' }}>
            <Button className={styles.scrbBtnBlue} onClick={toggle}>
              Cancel
            </Button>{' '}
            <Button type="submit" className={styles.scrbBtnOrange} disabled={isSubmitting}>
              Send Documents
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default SendExternalModal;
