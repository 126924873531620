import React, { ChangeEvent } from 'react';
import { Input } from 'reactstrap';

interface StatusInputProps {
  defaultValue: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  showAll?: boolean;
}

export const StatusInput = ({ defaultValue, onChange, showAll }: StatusInputProps) => {
  return (
    <Input type="select" name="docStatus" id="docStatus" aria-label="Select Status" onChange={onChange} defaultValue={defaultValue} required={true}>
      <option value="ACTIVE">Active</option>
      <option value="INACTIVE">Inactive</option>
      {showAll && <option value="*">Active and Inactive</option>}
    </Input>
  );
};
