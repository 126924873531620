import React, { ChangeEvent, ReactElement, useMemo, useState } from 'react';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import { FormGroup, Input, Label, Row } from 'reactstrap';
import { useMetaProvider } from '../../../contexts/MetaContext';

interface DocumentSearchProps {
  toggleType: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedTypes?: string[];
  showInactive?: boolean;
}

const TypeSelect = ({ toggleType, selectedTypes, showInactive }: DocumentSearchProps) => {
  const { metaState } = useMetaProvider();
  const [openGroups, setOpenGroups] = useState<string[]>([]);

  console.log('selectedTypes', selectedTypes);
  const toggleGroup = (id: string) => {
    if (openGroups.includes(id)) {
      setOpenGroups((prev) => {
        return prev.filter((openId) => openId !== id);
      });
    } else {
      setOpenGroups((prev) => {
        return [...prev, id];
      });
    }
  };

  const typesToShow = useMemo(() => {
    return metaState.allowedDocumentTypes.filter((type) => {
      return type.status === 'active' || (showInactive && type.status === 'inactive');
    });
  }, [metaState, showInactive]);

  const groupMapping: Record<string, ReactElement[]> = useMemo(() => {
    const toReturn: Record<string, ReactElement[]> = {};
    typesToShow.forEach((type, index) => {
      const input = (
        <Row style={{ justifyContent: 'space-between' }} key={`doctype_${index}`}>
          <FormGroup check inline>
            <Label check style={{ paddingLeft: '1em' }}>
              <Input onChange={(e) => toggleType(e)} name={type.id} type="checkbox" checked={selectedTypes?.includes(type.id)} />
              {type.description}
            </Label>
          </FormGroup>
        </Row>
      );
      let groupFound = false;
      metaState.documentTypeGroups.forEach((group) => {
        if (!group.types.includes(type.id)) {
          return;
        }

        groupFound = true;
        const curItemsForGroup = toReturn[group.id] ?? [];
        curItemsForGroup.push(input);
        toReturn[group.id] = curItemsForGroup;
      });

      if (!groupFound) {
        const notGroupedItems = toReturn['none'] ?? [];
        notGroupedItems.push(input);
        toReturn['none'] = notGroupedItems;
      }
    });
    return toReturn;
  }, [metaState, toggleType, selectedTypes]);

  const items = useMemo(() => {
    return (
      <>
        {metaState.documentTypeGroups.map((group) => {
          return (
            <>
              {groupMapping[group.id] && (
                <div style={{ marginBottom: '6px' }}>
                  <div onClick={() => toggleGroup(group.id)} style={{ cursor: 'pointer' }}>
                    <strong>
                      {openGroups.includes(group.id) ? <BsChevronDown /> : <BsChevronRight />}
                      &nbsp;
                      {group.heading}
                    </strong>
                  </div>
                  {openGroups.includes(group.id) && <div style={{ marginLeft: '15px' }}>{groupMapping[group.id].map((item) => item)}</div>}
                </div>
              )}
            </>
          );
        })}
        {groupMapping['none'] && groupMapping['none'].length > 0 && groupMapping['none'].map((item) => item)}
      </>
    );
  }, [metaState, openGroups, toggleType, selectedTypes]);

  return <>{typesToShow && groupMapping && items}</>;
};

export default TypeSelect;
