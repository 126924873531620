import React, { ChangeEvent, RefObject, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import Dynamsoft from 'dwt';
import { WebTwain } from 'dwt/dist/types/WebTwain';
import { UploadManager } from 'dwt/dist/types/Dynamsoft.FileUploader';
import { v4 as uuid } from 'uuid';
import { ScanDocument, ScannerSettings, ScanPage, UploadProgress } from '../../types/scanner';
import { Col, Row, ButtonGroup, Button } from 'reactstrap';
import { ThumbnailViewer } from './thumbnailviewer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  addDocumentType,
  getDocument,
  getPage,
  movePage,
  removeDocumentType,
  updateDocumentIndexValue,
  addPageToDocument,
  releaseDocument,
  barcodeSplit,
  updatePage,
  findDocumentByPage,
  splitDocument,
  removeDocument,
  removePage,
  isDocumentEmpty,
  findFirstPageOfDocument,
  getIndexOfDocument,
  updateDocumentIndexValues,
} from '..';
import MetaModal from './metamodal';

import { Loading } from '../../Loading';
import { toast } from 'react-toastify';
import { toastError, toastSyncError } from '../../toast';
import { Document } from '../../types/document';
import { ESMap } from 'typescript';
import ScannerSettingsModal from './scannersettingsmodal';
import { SourceDetails } from 'dwt/dist/types/WebTwain.Acquire';

interface DWTProps {
  userEmail: string;
  token?: string;
  presetIndexValues?: Record<string, string | Date | number>;
  presetType?: string;
  accountId: string;
}
import LoadingOverlay from 'react-loading-overlay-ts';
import { getBaseApiUrl } from '../../api/helpers';
import ProgressModal from './progressmodal';
import ConfirmDelete from '../../roles/roledetail/confirmDelete';
import { generateScannerLog } from '../../api/logging';
import { useHistory } from 'react-router-dom';
import { useMetaProvider } from '../../contexts/MetaContext';
import { Account } from '../../types/account';
import { getAccount } from '../../api/accounts';
import { organizerLookup } from '../../organizer';

export type ReducerAction = 'select' | 'select_singular' | 'select_range' | 'deselect_singular' | 'deselect_all';
export type ReducerItemType = 'documents' | 'pages';
export interface SelectReducerAction {
  type: ReducerAction;
  itemType: ReducerItemType;
  id: string;
  currentDocuments: ScanDocument[];
}

interface SelectedItems {
  documents: Record<string, boolean>;
  pages: Record<string, boolean>;
  previousSelection: string;
}

function selectReducer(state: SelectedItems, action: SelectReducerAction): SelectedItems {
  let actionTypeItems = { ...(action.itemType === 'documents' ? state.documents : state.pages) };
  let previousSelection = state.previousSelection;
  switch (action.type) {
    case 'select_singular':
      actionTypeItems = { [action.id]: true };
      previousSelection = action.id;
      break;
    case 'select':
      actionTypeItems = { ...actionTypeItems, [action.id]: true };
      previousSelection = action.id;
      break;
    case 'select_range':
      // eslint-disable-next-line no-case-declarations
      let inRange = false;
      action.currentDocuments.forEach((doc) => {
        doc.pages.forEach((page) => {
          const isSelectEdge = page.id === state.previousSelection || page.id === action.id;
          if (isSelectEdge) {
            inRange = !inRange;
          }
          if (inRange || isSelectEdge) {
            actionTypeItems[page.id] = true;
          }
        });
      });
      break;
    case 'deselect_singular':
      actionTypeItems = { ...actionTypeItems, [action.id]: false };
      break;
    case 'deselect_all':
      actionTypeItems = {};
      previousSelection = '';
      break;
    default: {
      actionTypeItems = {};
    }
  }

  let documents: Record<string, boolean> = {};
  let pages: Record<string, boolean> = {};
  if (action.itemType === 'documents') documents = { ...actionTypeItems };
  if (action.itemType === 'pages') pages = { ...actionTypeItems };

  return { documents, pages, previousSelection };
}

export const DWT = ({ userEmail, presetIndexValues, presetType, accountId }: DWTProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [sources, setSources] = useState<SourceDetails[]>([]);
  const [documents, _setDocuments] = useState<ScanDocument[]>([]);
  const documentRef = useRef(documents);
  const [documentContainerRefs, setDocumentContainerRefs] = useState<
    ESMap<
      string,
      {
        ref: RefObject<HTMLDivElement>;
        isInView: boolean;
      }
    >
  >(new Map());
  const [thumbnailRefs, setThumbnailRefs] = useState<
    ESMap<
      string,
      {
        ref: RefObject<HTMLDivElement>;
        isInView: boolean;
      }
    >
  >(new Map());
  const history = useHistory();
  const [DWObject, setDWObject] = useState<WebTwain>();

  const [uploadManager, setUploadManager] = useState<UploadManager>();

  const [selectedItems, dispatchSelectedItems] = useReducer(selectReducer, { documents: {}, pages: {}, previousSelection: '' });

  const [showMetaModal, setShowMetaModal] = useState<boolean>(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);

  const [acquireAfterSettingsSave, setAcquireAfterSettingsSave] = useState<boolean>(false);
  const [imageEditDisabled, setImageEditDisabled] = useState<boolean>(true);
  const [metaEditDocument, setMetaEditDocument] = useState<Partial<Document>>();
  const [metaEditErrors, setMetaEditErrors] = useState<Record<string, string>>({});
  const [showThinking, setShowThinking] = useState<boolean>(false);
  const [thinkingText, setThinkingText] = useState<string>();
  const [progressTracker, setProgressTracker] = useState<Record<string, UploadProgress>>({});
  const [showProgressModal, setShowProgressModal] = useState<boolean>(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<boolean>(false);
  const [confirmModalBodyText, setConfirmModalBodyText] = useState<string>();
  const [documentErrors, setDocumentErrors] = useState<Record<string, string | undefined>>({});
  const scanningStarted = useRef<boolean>(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const existingDocumentId = params.get('existingDocumentId');
  const { documentIndexes } = useMetaProvider().metaState;
  const [currentAccount, setCurrentAccount] = useState<Account>();

  let initSettings = {
    colorscale: '0',
    dpi: '150',
    duplex: false,
    source: 'none',
    creation: new Date(0),
  };
  const storedSettings = localStorage.getItem('scrib_scannersettings');
  if (storedSettings) {
    initSettings = JSON.parse(storedSettings);
    initSettings.creation = new Date(initSettings.creation);
  }
  const [scannerSettings, setScannerSettings] = useState<ScannerSettings>(initSettings);
  //look for stored scannerSettings
  useEffect(() => {
    console.log('scanner settings updated');
    if (acquireAfterSettingsSave) {
      console.log('going to acquire after save');
      setAcquireAfterSettingsSave(false);
      acquireImage();
    }
  }, [scannerSettings]);

  const updateScannerSettings = (settings: ScannerSettings) => {
    console.log(`settings submitted ${JSON.stringify(settings)}`);
    settings.creation = new Date();
    setScannerSettings(() => settings);
    localStorage.setItem('scrib_scannersettings', JSON.stringify(settings));
    toggleSettingsModal();
  };

  const setDocuments = (docs: ScanDocument[] | ((docs: ScanDocument[]) => ScanDocument[])) => {
    if (Array.isArray(docs)) {
      documentRef.current = docs;
      _setDocuments(docs);
    } else {
      _setDocuments((oldDocs) => {
        const newDocs = docs(oldDocs);
        documentRef.current = newDocs;
        return newDocs;
      });
    }
  };
  // const dwtProductKey = `${process.env.REACT_APP_DWT_LICENSE}`;

  useEffect(() => {
    if (DWObject) {
      const selectedIndices: number[] = [];

      for (const entry of Object.entries(selectedItems.pages)) {
        if (entry[1]) {
          selectedIndices.push(DWObject.ImageIDToIndex(Number(entry[0])));
        }
      }
      console.log(`telling dw to select ${JSON.stringify(selectedIndices)}`);
      DWObject.SelectImages(selectedIndices);
      if (selectedIndices.length > 0) {
        setImageEditDisabled(false);
      } else {
        setImageEditDisabled(true);
      }
    }
  }, [selectedItems]);
  const containerId = 'dwtcontrolContainer';

  const init = async () => {
    // need to set this before dwt init as we need the container to render
    setLoading(false);
    Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
      Dynamsoft_OnReady();
    });

    Dynamsoft.DWT.organizationID = '100834239';
    // Dynamsoft.DWT.ProductKey =
    //   't01679QMAABfGnXJDoMjBdVSO2MXqxNk6sBNa8awDsF/KCrUwfN8bVZ5V2pbBYV8TD17crJnn2XwLJwydSpgFfJT77e5wfCELRmxD0TaMgUEqIm4lvbjO8BvMBUgF6CNHUP5yAovfv44KHWALiAHEmgNZYLCKqFvkVs3nugoaAraAfAHUzmsfjLZl57HNb0ZujU8xbPIfmnJ/sx7VnU3+oZmCJAmeXx740UQ=';
    Dynamsoft.DWT.ResourcesPath = 'dwt/dist';
    Dynamsoft.DWT.Containers = [
      {
        WebTwainId: 'dwtObject',
        ContainerId: containerId,
        Width: '100%',
        Height: '100%',
      },
    ];
    Dynamsoft.DWT.Load();
    generateScannerLog(accountId, userEmail, 'INIT');
  };

  const fetchCurrentAccount = async () => {
    const account = await getAccount(accountId);
    setCurrentAccount(account);
  };

  useEffect(() => {
    init();
    window.addEventListener('beforeunload', checkUnload);
    window.addEventListener('unload', () => {
      Dynamsoft.DWT.Unload();
    });
    fetchCurrentAccount();
    return () => {
      Dynamsoft.DWT.CloseDialog();
      window.removeEventListener('beforeunload', checkUnload);
      window.removeEventListener('unload', () => {
        Dynamsoft.DWT.Unload();
      });
    };
  }, []);

  const checkUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    if (!wrapperRef.current || !modalRef.current) {
      return;
    }

    document.addEventListener('mousedown', (e) => handleClickOutside(e));
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, modalRef, loading]);

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node) && modalRef.current && !modalRef.current.contains(event.target as Node)) {
      dispatchSelectedItems({ id: '', type: 'deselect_all', itemType: 'documents', currentDocuments: documents });
    }
  };

  const Dynamsoft_OnReady = () => {
    console.log('on ready?', containerId);
    window.onbeforeunload = null;
    const dwObject = Dynamsoft.DWT.GetWebTwain(containerId);

    //we need to lookup each page we have that has not been barcoded and use that to split docs... preferably they will be barcoded as they come in
    // or should we just do this on any page addition to the buffer and not worry about all this? (this is how it currently is)
    // dwObject.RegisterEvent('OnPostAllTransfers', () => {
    //   if (dwObject) {
    //     //scan job has finished
    //     console.log('!!OnPostAllTransfers!!');
    //   }
    // });
    // dwObject.RegisterEvent('OnPostLoad', () => {
    //   if (dwObject) {
    //     //a file has been loaded

    //     console.log(`!!OnPostLoad!! ${documents[0].pages.length}`);
    //   }
    // });
    dwObject.RegisterEvent('OnTopImageInTheViewChanged', (index) => {
      if (dwObject && index >= 0) {
        const id = dwObject.IndexToImageID(index);
        const ref = thumbnailRefs.get(`${id}`)?.ref;
        if (id && ref && ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
      }
    });

    dwObject.RegisterEvent('OnBufferChanged', (bufferChangeInfo) => {
      if (!scanningStarted.current) {
        generateScannerLog(accountId, userEmail, 'SCAN');
        scanningStarted.current = true;
      }
      if (!dwObject) {
        console.log('not initialized');
        return;
      }
      if (bufferChangeInfo.action === 'modify') {
        //get selected ids and update the thumbnails
        for (const id of bufferChangeInfo.selectedIds) {
          setDocuments((oldDocs) => {
            const page = getPage(oldDocs, '' + id);
            if (page) {
              const imageIndex = dwObject.ImageIDToIndex(bufferChangeInfo.currentId);
              const url = dwObject.GetImageURL(imageIndex, 200, 200);
              page.thumbnailURL = new URL(url);
              return updatePage(oldDocs, page);
            } else {
              console.log('couldnt find page');
            }
            return oldDocs;
          });
        }
      }
      if (bufferChangeInfo.action === 'add') {
        //scan page for barcode...
        const imageIndex = dwObject.ImageIDToIndex(bufferChangeInfo.currentId);
        const url = dwObject.GetImageURL(imageIndex, 200, 200);
        const newPage: ScanPage = {
          id: '' + bufferChangeInfo.currentId,
          currentIndex: imageIndex,
          thumbnailURL: new URL(url),
        };
        if (!existingDocumentId) {
          const currentIndex = dwObject.ImageIDToIndex(bufferChangeInfo.currentId);
          setShowThinking(true);
          setThinkingText('Checking for barcodes...');
          dwObject.Addon.BarcodeReader.decode(currentIndex).then((results) => {
            // console.log(JSON.stringify(results, null, 2));

            //dont add image to docs until after the scan... then we can create the correct docs
            //if results
            //create new document and add to that
            //else add to existing

            if (results.length > 0) {
              //are the results ours?
              //if so parse results, create doc, set indexes, etc
              newPage.barcodeResults = [results[0].barcodeText];
              try {
                const decodedDocContent = JSON.parse(results[0].barcodeText);
                console.log('decodedDocContent', decodedDocContent);
                if (!decodedDocContent.indexes || !decodedDocContent.types || !decodedDocContent.tenantId) {
                  toast.success('Barcode does not match Scribbles, ignoring');
                } else {
                  console.log(JSON.stringify(decodedDocContent, null, 2));
                  let newDocs = barcodeSplit(documentRef.current, { ...newPage });
                  if (newDocs.length > 1) {
                    const prevDoc = newDocs[newDocs.length - 2];
                    const prevDocEmpty = isDocumentEmpty(prevDoc);
                    if (prevDocEmpty) {
                      DWObject?.RemoveImage(DWObject.ImageIDToIndex(Number(prevDoc.pages[0].id)));
                      newDocs = removeDocument(newDocs, prevDoc);
                    }
                  }
                  setDocuments(newDocs);
                }
              } catch (e) {
                console.log('error parsing', e);
                toast.success('Barcode does not match Scribbles, ignoring');
              }
            }
            if (currentIndex === dwObject.HowManyImagesInBuffer - 1) {
              setShowThinking(false);
            }
            // console.log(`finished barcode decode ${documents[0].pages.length}`);
          });
        }
        // setDocuments((oldDocs) => {
        //   console.log(`adding page ${newPage.id} ${documents[0].pages.length}`);
        //   console.log(`adding page ${newPage.id} ${oldDocs[0].pages.length}`);
        //   const updatedDocs = addPageToDocument(oldDocs, newPage);
        //   console.log(`after adding page ${newPage.id} ${updatedDocs[0].pages.length}`);
        //   return updatedDocs;
        // });
        setDocuments(addPageToDocument(accountId, documentRef.current, newPage, undefined, userEmail, presetIndexValues, presetType));
      }
      //need to compare this to the currently selected in the thumbnail viewer
      //bufferChangeInfo.selectedIds;
    });

    Dynamsoft.FileUploader.Init(
      '',
      (manager) => {
        setUploadManager(manager);
      },
      function (errCode, errStr) {
        console.log(errStr);
      },
    );
    if (dwObject.Addon && dwObject.Addon.PDF) {
      dwObject.Addon.PDF.SetResolution(200);
      //dwObject.Addon.PDF.SetConvertMode(Dynamsoft.DWT.EnumDWT_ConvertMode.CM_IMAGEONLY);
    } else {
      console.log('didnt find addon');
    }
    setDWObject(dwObject);
  };

  const firstDocumentInView = () => {
    let toReturn;
    documentContainerRefs.forEach((value, key) => {
      if (value.isInView && value.ref.current) {
        toReturn = key;
      }
    });
    return toReturn;
  };

  const getFirstPageInView = useCallback(
    (documentId: string) => {
      const document = getDocument(documents, documentId);
      if (!document || !document.pages || document.pages.length === 0) return;
      for (const page of document.pages) {
        const pageRef = thumbnailRefs.get(page.id);
        if (pageRef?.isInView && pageRef.ref.current) return page.id;
      }
    },
    [documents, thumbnailRefs],
  );

  const scrollToFirstDocument = () => {
    if (documents.length === 0) {
      return;
    }
    const ref = documentContainerRefs.get(`${documents[0].releaseDocument.id}`)?.ref;
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  };

  const scrollToLastDocument = () => {
    if (documents.length === 0) {
      return;
    }
    const ref = documentContainerRefs.get(`${documents[documents.length - 1].releaseDocument.id}`)?.ref;
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  };

  const scrollToNextDocument = () => {
    const currentIndex = getIndexOfDocument(documents, `${firstDocumentInView()}`);
    if (currentIndex === undefined || currentIndex === documents.length - 1) {
      return;
    }
    documentContainerRefs
      .get(`${documents[currentIndex + 1].releaseDocument.id}`)
      ?.ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  };

  const scrollToPreviousDocument = () => {
    const document = getDocument(documents, `${firstDocumentInView()}`);
    if (!document) return;
    const firstPageId = findFirstPageOfDocument(documents, document);
    const { ref } = { ...documentContainerRefs.get(`${document.releaseDocument.id}`) };
    if (!firstPageId || !ref || !ref.current) return;
    const { isInView } = { ...thumbnailRefs.get(firstPageId) };
    if (!isInView) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      return;
    }

    const indexOfDocument = getIndexOfDocument(documents, document);
    if (!indexOfDocument || indexOfDocument === 0) {
      // first document in the list, so no previous to scroll to
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      return;
    }

    const previousDocument = documents[indexOfDocument - 1];
    documentContainerRefs.get(`${previousDocument.releaseDocument.id}`)?.ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  };

  const loadImage = () => {
    if (!DWObject || !uploadManager) {
      console.log('not initialized');
      return;
    }

    DWObject.LoadImageEx('/Users/karlweeks/development/typescript/scantest/public/logo512.png', 5, undefined, function (errorCode, errorString) {
      alert('Load Image:' + errorString);
    });
  };

  const acquireImage = () => {
    console.log('start aquire');
    if (scannerSettings.creation.getTime() === 0) {
      console.log('no ctime set');
      //never been set... open settings modal
      setAcquireAfterSettingsSave(true);
      toggleSettingsModal();
      //need to acquire if settings are saved
    } else {
      console.log('got settings');
      if (DWObject) {
        console.log('has dwobject');
        //lookup the current sources and find a match
        const sourceDetails = DWObject.GetSourceNames() as string[];
        const sourceIndex = sourceDetails.findIndex((sourceName) => {
          return sourceName.endsWith(scannerSettings.source);
        });
        if (sourceIndex > -1) {
          DWObject.SelectSourceByIndex(sourceIndex);
          DWObject.OpenSource();
          DWObject.AcquireImage(
            { IfShowUI: true, Resolution: Number(scannerSettings.dpi), PixelType: Number(scannerSettings.colorscale), IfDuplexEnabled: true },
            () => {
              console.log('success callback');
            },
            (confg, errorCode, errorString) => {
              console.log(`Error at acquire image ${JSON.stringify(errorCode, null, 2)} : ${errorString}`);
              toastSyncError(errorString);
            },
          );
        } else {
          console.log('unable to find previously set source');
          setAcquireAfterSettingsSave(true);
          toggleSettingsModal();
        }
      } else {
        console.log('no dwobject');
      }
    }
  };

  const internalMovePage = useCallback(
    (fromDocumentIndex: number, toDocumentIndex: number, fromPageIndex: number, toPageIndex: number) => {
      if (DWObject) {
        const docs = [...documents];
        const pageToMove = docs[fromDocumentIndex].pages[fromPageIndex];
        const destinationDocument = toDocumentIndex === fromDocumentIndex ? undefined : docs[toDocumentIndex];
        const moveFrom = DWObject.ImageIDToIndex(Number(pageToMove.id));
        const moveToDoc = docs[toDocumentIndex];

        let moveTo = 0;
        if (toPageIndex === moveToDoc.pages.length) {
          if (fromDocumentIndex > toDocumentIndex) {
            moveTo = DWObject.ImageIDToIndex(Number(moveToDoc.pages[toPageIndex - 1].id)) + 1;
          } else {
            moveTo = DWObject.ImageIDToIndex(Number(moveToDoc.pages[toPageIndex - 1].id));
          }
        } else {
          moveTo = DWObject.ImageIDToIndex(Number(moveToDoc.pages[toPageIndex].id));
        }
        DWObject.MoveImage(moveFrom, moveTo);
        DWObject.SelectImages([moveTo]);
        setDocuments(movePage(docs, pageToMove, toPageIndex, destinationDocument));
      }
    },
    [selectedItems, documents],
  );

  const internalMoveSelectedPages = useCallback(
    (toPageIndex: number, toDocumentIndex: number) => {
      if (DWObject) {
        const docs = [...documents];
        const pagesToMove: ScanPage[] = [];
        Object.keys(selectedItems.pages).forEach((key) => {
          if (selectedItems.pages[key]) {
            const page = getPage(docs, key);
            if (page) pagesToMove.push(page);
          }
        });
        //find the buffer index for insert to\
        const moveToIndex = DWObject.ImageIDToIndex(Number(docs[toDocumentIndex].pages[toPageIndex].id));
        //reverse move image ids to that index
        setDocuments(movePage(documentRef.current, pagesToMove, toPageIndex, docs[toDocumentIndex]));
        [...pagesToMove].reverse().forEach((page) => {
          const pageIndex = DWObject.ImageIDToIndex(Number(page.id));
          DWObject.MoveImage(pageIndex, moveToIndex);
        });
      }
    },
    [selectedItems, documents],
  );

  const updateDocumentContainerRefs = (id: string, ref: RefObject<HTMLDivElement>, isInView: boolean) => {
    setDocumentContainerRefs(documentContainerRefs.set(id, { ref, isInView }));
    console.log('documentRefs', documentContainerRefs);
  };

  const updateRefs = (id: string, ref: RefObject<HTMLDivElement>, isInView: boolean) => {
    setThumbnailRefs(thumbnailRefs.set(id, { ref, isInView }));
  };

  const selectItem = useCallback(
    (id: string, itemType: ReducerItemType, action: ReducerAction) => {
      dispatchSelectedItems({ id, type: action, itemType, currentDocuments: documents });
    },
    [selectedItems, documents],
  );

  const handleItemClick = (id: string, itemType: ReducerItemType, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    const isRangeSelect = event.shiftKey;
    if (isRangeSelect && selectedItems.previousSelection) {
      selectItem(id, itemType, 'select_range');
      return;
    }
    const isMultiSelect = event.ctrlKey || event.metaKey;
    const currentlySelectedItems = itemType === 'documents' ? selectedItems.documents : selectedItems.pages;
    const multiSelectAction = currentlySelectedItems[id] ? 'deselect_singular' : 'select';
    const action = isMultiSelect ? multiSelectAction : 'select_singular';
    selectItem(id, itemType, action);
  };

  const editDocumentMeta = (docId: string, event: React.MouseEvent<SVGElement, MouseEvent>) => {
    event.stopPropagation();
    setMetaEditDocument(getDocument(documents, docId)?.releaseDocument);
    setShowMetaModal(true);
  };

  const toggleMetaModal = () => {
    setShowMetaModal(false);
    setMetaEditDocument(undefined);
  };

  const organizerLookupInternal = async () => {
    if (!currentAccount) {
      console.error('Account not found');
      return;
    }
    if (!metaEditDocument || !metaEditDocument.indexes || !metaEditDocument.id) {
      console.error('No meta edit document');
      return;
    }
    const primaryIndex = documentIndexes.find((index) => index.id === currentAccount.organizerPrimaryIndex);
    if (!primaryIndex) {
      console.error('Primary index not found');
      return;
    }
    const lookupValue = metaEditDocument.indexes[primaryIndex.id];
    const newValues = await organizerLookup(currentAccount.accountId, lookupValue, { ...metaEditDocument.indexes });
    if (newValues) {
      setDocuments(updateDocumentIndexValues([...documents], metaEditDocument.id, newValues));
    }
  };

  const toggleSettingsModal = () => {
    if (!showSettingsModal) {
      //about to show settings so get current sources
      if (DWObject) {
        try {
          setSources(DWObject.GetSourceNames(true) as SourceDetails[]);
        } catch (error) {
          console.log(error);
        }
      }
    }
    setShowSettingsModal(!showSettingsModal);
  };

  const updateIndexValue = (event: ChangeEvent<HTMLInputElement>) => {
    setDocuments(updateDocumentIndexValue([...documents], metaEditDocument?.id ?? '', event.target.name, event.target.value));
  };

  const toggleSelectedType = (event: ChangeEvent<HTMLInputElement>) => {
    const typeId = event.target.name;
    const isSet = event.target.checked;

    setDocuments(
      isSet ? addDocumentType([...documents], metaEditDocument?.id ?? '', typeId) : removeDocumentType([...documents], metaEditDocument?.id ?? '', typeId),
    );
  };

  const releaseDocumentMetaEdit = async () => {
    if (!metaEditDocument || !metaEditDocument.id || !DWObject || !uploadManager) {
      toast.error('Unable to release document, please try again.');
      return;
    }
    const errors = { ...metaEditErrors };
    console.log('type', metaEditDocument.types);
    const missingTypes = !metaEditDocument || !metaEditDocument.types || metaEditDocument.types.length === 0;
    errors[metaEditDocument.id] = missingTypes ? 'At least one type must be selected.' : '';
    setMetaEditErrors(errors);
    if (missingTypes) {
      return;
    }

    const scanDocument = getDocument(documents, metaEditDocument.id);
    if (!scanDocument) {
      toast.error('Unable to release document, please try again.');
      return;
    }
    setShowThinking(true);
    setThinkingText('Preparing document for release...');
    try {
      toggleMetaModal();
      //const newDocs = await releaseDocument(documents, scanDocument);
      //generate upload and attach success, error, progress
      if (!DWObject || !uploadManager) {
        toast.error('DW Object is undefined');
      }
      const releaseDocument = scanDocument.releaseDocument;
      if (!releaseDocument.id || !releaseDocument.accountId || !releaseDocument.types || releaseDocument.types.length === 0 || !releaseDocument.indexes) {
        toast.error('Missing required fields in document');
      }
      await releaseSingleDocument(scanDocument);
    } catch (e) {
      console.log(e);
      // TODO: some sort of error handling here
    } finally {
      setShowThinking(false);
    }
  };

  const releaseExistingDocument = async () => {
    if (documents.length > 0) {
      await releaseSingleDocument(documents[0]);
    }
  };

  const releaseSingleDocument = async (scanDocument: ScanDocument) => {
    console.log('scanDocument', scanDocument);
    if (!DWObject || !uploadManager) {
      toast.error('Unable to release document, please try again.');
      return;
    }
    const releaseDocument = scanDocument.releaseDocument;
    console.log('releaseDocument', releaseDocument);
    const ids = scanDocument.pages.map((page) => Number(page.id));
    const indices = ids.map((id) => DWObject.ImageIDToIndex(id));
    releaseDocument.pages = indices.length;
    releaseDocument.status = 'ACTIVE';
    await new Promise((resolve, reject) => {
      DWObject.GenerateURLForUploadData(
        indices,
        4,
        (resultURL, newIndices, enumImageType) => {
          const serverUrl = `${process.env.NODE_ENV === 'development' ? getBaseApiUrl() : `https://${window.location.hostname}`}/server/${
            releaseDocument.accountId
          }/scannerupload`;
          const jobtemp = uploadManager.CreateJob();
          jobtemp.ServerUrl = serverUrl;
          jobtemp.FormField.Add('document', btoa(JSON.stringify(releaseDocument)));
          if (existingDocumentId) {
            jobtemp.FormField.Add('existingDocumentId', existingDocumentId);
          }
          jobtemp.SourceValue.Add(resultURL, new Date().getTime() + 'uploadedFile.pdf');
          jobtemp.OnRunFailure = (job, errorCode, errorString) => {
            console.log(`error ${errorCode}: ${errorString}`);
            toast.error(`Error uploading ${errorString}`);
            setProgressTracker((prevProg) => {
              return { ...prevProg, [releaseDocument.id + '']: { ...prevProg[releaseDocument.id + ''], error: errorString } };
            });
          };

          jobtemp.OnRunSuccess = (job) => {
            DWObject.SelectImages(ids.map((id) => DWObject.ImageIDToIndex(id)));
            DWObject.RemoveAllSelectedImages();
            const newDocs = removeDocument(documentRef.current, scanDocument);
            setDocuments(newDocs);
            toast.success(releaseDocument.id + ' finished uploading');
          };

          const startTime = new Date();
          jobtemp.OnUploadTransferPercentage = (job, percentage) => {
            //update something with the percentage

            setProgressTracker((prevProg) => {
              return { ...prevProg, [releaseDocument.id + '']: { ...prevProg[releaseDocument.id + ''], percentageComplete: percentage, startTime } };
            });

            console.log(`${releaseDocument.id} : ${percentage}`);
          };
          uploadManager.Run(jobtemp);
          resolve(true);
        },
        (errorCode, errorString) => {
          console.log(`Error generating url ${errorCode}`, errorString);
          toast.error('Unable to prepare document for upload');
          reject('Unable to upload document.');
        },
      );
    });
  };
  const rotateClockwise = () => {
    if (DWObject) {
      //get indexes
      for (const selectedIndex of DWObject.SelectedImagesIndices) {
        DWObject.RotateRight(selectedIndex, () => {
          console.log('rotation success');
        });
      }
    }
  };
  const rotateCounterClockwise = () => {
    if (DWObject) {
      //get indexes
      for (const selectedIndex of DWObject.SelectedImagesIndices) {
        DWObject.RotateLeft(selectedIndex, () => {
          console.log('rotation success');
        });
      }
    }
  };
  const mirrorImage = () => {
    console.log('mirror');
    if (DWObject) {
      //get indexes
      for (const selectedIndex of DWObject.SelectedImagesIndices) {
        DWObject.Mirror(selectedIndex, () => {
          console.log('rotation success');
        });
      }
    }
  };
  const flipImage = () => {
    console.log('flip');
    if (DWObject) {
      //get indexes
      for (const selectedIndex of DWObject.SelectedImagesIndices) {
        DWObject.Flip(selectedIndex, () => {
          console.log('rotation success');
        });
      }
    }
  };

  const createDocument = () => {
    // take all selected pages and create a new document from that
    setDocuments((oldDocs) => {
      // create a document after the first
      //get the first page id
      const pageId = Object.entries(selectedItems.pages)[0][0];
      const docToSplit = findDocumentByPage(oldDocs, pageId);
      // internalMoveSelectedPages to doc X at index 0
      if (docToSplit) {
        const splitResults = splitDocument(oldDocs, docToSplit, 100000);
        //get all the selected pages
        const pagesToMove: ScanPage[] = [];
        for (const entry of Object.entries(selectedItems.pages)) {
          if (entry[1]) {
            const page = getPage(splitResults.documents, entry[0]);
            if (page) {
              pagesToMove.push(page);
            }
          }
        }
        return movePage(splitResults.documents, pagesToMove, 0, splitResults.document);
      }
      return oldDocs;
    });
  };

  const beginDeleteImage = useCallback(() => {
    const barcodePages = [];
    Object.keys(selectedItems.pages).forEach((pageId) => {
      const page = getPage(documents, pageId);
      if (page?.barcodeResults) {
        barcodePages.push(page);
      }
    });
    if (barcodePages.length === 0) {
      setConfirmModalBodyText('Are you sure you want to delete the selected pages?');
    } else {
      setConfirmModalBodyText(
        `Are you sure you want to delete the selected page(s)? Your selection includes ${barcodePages.length} barcode lead sheet page(s). Deleting barcode pages will delete the associated document as well as all pages associated with the document.`,
      );
    }
    setShowDeleteConfirmModal(true);
  }, [documents, selectedItems]);

  const deleteImage = useCallback(() => {
    if (!DWObject) return;
    let newDocs = [...documents];
    Object.keys(selectedItems.pages).forEach((id) => {
      const page = getPage(newDocs, id);
      if (!page) {
        // page was removed during removal of document, ignoring
        return;
      }
      if (page?.barcodeResults) {
        const document = findDocumentByPage(newDocs, id);
        document?.pages.forEach((page) => {
          newDocs = removePage(newDocs, `${page.id}`);
          DWObject.RemoveImage(DWObject.ImageIDToIndex(parseInt(page.id)));
        });
      } else {
        newDocs = removePage(newDocs, `${id}`);
        DWObject.RemoveImage(DWObject.ImageIDToIndex(parseInt(id)));
      }
    });
    DWObject.SelectImages([]);
    setDocuments(newDocs);
    setShowDeleteConfirmModal(false);
  }, [documents, selectedItems]);

  const releaseAll = useCallback(async () => {
    // validate all before we start releasing any
    const newErrors = { ...documentErrors };
    for (const document of documents) {
      const releaseDocument = document.releaseDocument;
      console.log('checking', releaseDocument.id);
      if (!releaseDocument.id) {
        // this should never happen, satisfying typescript
        continue;
      }
      const validTypes = releaseDocument.types && releaseDocument.types?.length > 0;
      if (!validTypes) {
        newErrors[releaseDocument.id] = 'No Document Type';
        continue;
      }

      if (!releaseDocument.indexes) {
        newErrors[releaseDocument.id] = 'Missing Required Indexes';
        continue;
      }
      // const requiredIndexKeys = requiredIndexesForDocument(documentTypes, documentIndexes, releaseDocument.types);
      // let validIndexes = true;
      // console.log('requiredIndexKeys', requiredIndexKeys);
      // console.log('releaseDocument.indexes', releaseDocument.indexes);
      // requiredIndexKeys.forEach((indexKey) => {
      //   if (!releaseDocument.indexes || !releaseDocument.indexes[indexKey]) {
      //     validIndexes = false;
      //   }
      // });

      // if (!validIndexes) {
      //   newErrors[releaseDocument.id] = 'Missing Required Indexes';
      //   continue;
      // }

      newErrors[releaseDocument.id] = undefined;
    }

    setDocumentErrors(newErrors);
    const invalidDocuments = Object.keys(newErrors).filter((key) => newErrors[key]);
    if (invalidDocuments.length > 0) {
      const errorDoc = documents.find((doc) => doc.releaseDocument.id === invalidDocuments[0]);
      if (errorDoc) {
        const pageId = errorDoc.pages[0].id;
        const ref = thumbnailRefs.get(`${pageId}`)?.ref;
        if (pageId && ref && ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
      }
      return;
    }

    // we release all
    setShowThinking(true);
    setThinkingText('Preparing documents for release...');
    try {
      for (const document of documents) {
        await releaseSingleDocument(document);
      }
    } finally {
      setShowThinking(false);
    }
  }, [documents, documentErrors, thumbnailRefs]);

  const documentReleaseDisabled = useMemo(() => {
    return !documents || documents.length === 0;
  }, [documents]);

  const metaModalError = metaEditDocument && metaEditDocument.id ? metaEditErrors[metaEditDocument.id] : undefined;
  return loading ? (
    <Loading />
  ) : (
    <LoadingOverlay active={showThinking} spinner text={thinkingText}>
      <div style={{ maxWidth: '98%', height: '100%' }} ref={wrapperRef}>
        <Row>
          <Col md="12">
            <ButtonGroup className="scanner-buttons">
              {existingDocumentId && (
                <Button color="primary" onClick={() => history.goBack()} aria-label="Go Back">
                  Back
                </Button>
              )}
              <Button onClick={() => acquireImage()} aria-label="Scan Document">
                <i className="bi bi-file-arrow-down"></i> Scan
              </Button>
              <Button onClick={() => loadImage()} aria-label="Open Document">
                <i className="bi bi-folder2-open"></i> Open
              </Button>
              {!existingDocumentId && (
                <>
                  <Button disabled={documents.length < 2} onClick={() => scrollToFirstDocument()} color="info" aria-label="Scroll To First Document">
                    <i className="bi bi-chevron-double-up"></i>
                  </Button>
                  <Button disabled={documents.length < 2} onClick={() => scrollToPreviousDocument()} color="info" aria-label="Scroll To Previous Document">
                    <i className="bi bi-chevron-up"></i>
                  </Button>
                  <Button disabled={documents.length < 2} onClick={() => scrollToNextDocument()} color="info" aria-label="Scroll To Next Document">
                    <i className="bi bi-chevron-down"></i>
                  </Button>
                  <Button disabled={documents.length < 2} onClick={() => scrollToLastDocument()} color="info" aria-label="Scroll To Last Document">
                    <i className="bi bi-chevron-double-down"></i>
                  </Button>
                  <Button disabled={documentReleaseDisabled} color="success" onClick={() => releaseAll()} aria-label="Release All">
                    <i className="bi bi-send"></i> Release All
                  </Button>
                  <Button disabled={imageEditDisabled} color="success" onClick={createDocument} aria-label="Create">
                    <i className="bi bi-file-earmark-plus"></i> Create
                  </Button>
                </>
              )}
              <Button disabled={imageEditDisabled} color="primary" onClick={rotateClockwise} aria-label="Rotate Clock wise">
                <i className="bi bi-arrow-clockwise"></i>
              </Button>
              <Button disabled={imageEditDisabled} color="primary" onClick={rotateCounterClockwise} aria-label="Rotate Counter Clock wise">
                <i className="bi bi-arrow-counterclockwise"></i>
              </Button>
              <Button disabled={imageEditDisabled} color="primary" onClick={mirrorImage} aria-label="Mirror Image">
                <i className="bi bi-symmetry-vertical"></i>
              </Button>
              <Button disabled={imageEditDisabled} color="danger" onClick={beginDeleteImage} aria-label="Begin Delete Image">
                <i className="bi bi-trash"></i>
              </Button>
              {existingDocumentId && (
                <Button color="success" disabled={documentReleaseDisabled} onClick={releaseExistingDocument} aria-label="Release Existing Document">
                  Release
                </Button>
              )}
            </ButtonGroup>
            <ButtonGroup className="float-right">
              <Button disabled={Object.keys(progressTracker).length === 0} onClick={() => setShowProgressModal(true)} aria-label="Progress Modal">
                <i className="bi bi-collection"></i> Progress
              </Button>
              <Button onClick={() => toggleSettingsModal()} aria-label="Toggle Settings Modal">
                <i className="bi bi-gear"></i> Settings
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <Row style={{ height: '90vh' }}>
          <Col md="8">
            <div id={containerId} style={{ width: '100%', height: '100%' }}>
              {' '}
            </div>
          </Col>
          <Col md="4" style={{ maxHeight: '100%' }}>
            <DndProvider backend={HTML5Backend}>
              <ThumbnailViewer
                documents={documents}
                movePage={internalMovePage}
                handleItemClick={handleItemClick}
                selectedDocuments={selectedItems.documents}
                selectedPages={selectedItems.pages}
                moveSelectedPages={internalMoveSelectedPages}
                editDocumentMeta={editDocumentMeta}
                updateRefs={updateRefs}
                updateDocumentContainerRefs={updateDocumentContainerRefs}
                documentErrors={documentErrors}
                isExistingDocument={!!existingDocumentId}
              />
            </DndProvider>
          </Col>
        </Row>
        <MetaModal
          modal={showMetaModal}
          toggle={toggleMetaModal}
          onIndexEdit={updateIndexValue}
          onTypeEdit={toggleSelectedType}
          onSubmit={releaseDocumentMetaEdit}
          editDocument={metaEditDocument}
          error={metaModalError}
          organizerLookupFunction={organizerLookupInternal}
          indexValues={metaEditDocument?.indexes}
          currentAccount={currentAccount}
        />
        <ScannerSettingsModal
          modal={showSettingsModal}
          onSubmit={updateScannerSettings}
          sources={sources}
          toggle={toggleSettingsModal}
          settings={scannerSettings}
        />
        <ProgressModal
          progressTracker={Object.values(progressTracker)}
          modal={showProgressModal}
          toggle={() => {
            setShowProgressModal((prev) => !prev);
          }}
        />
        <ConfirmDelete
          ref={modalRef}
          modal={showDeleteConfirmModal}
          toggle={() => setShowDeleteConfirmModal(!showDeleteConfirmModal)}
          onConfirm={deleteImage}
          bodyText={confirmModalBodyText}
        />
      </div>
    </LoadingOverlay>
  );
};

export default DWT;
