import { SourceDetails } from 'dwt/dist/types/WebTwain.Acquire';
import React, { useState } from 'react';
import { Button, Col, Form, Modal, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { ScannerSettings } from '../../types/scanner';
import styles from '../../styles/archiveui.module.css';

interface ScannerSettingsProps {
  modal: boolean;
  settings: ScannerSettings;
  sources: SourceDetails[];
  toggle: () => void;
  onSubmit: (settings: ScannerSettings) => void;
}

const ScannerSettingsModal = ({ modal, toggle, sources, onSubmit, settings }: ScannerSettingsProps) => {
  const [scannerSettings, updateSettings] = useState<ScannerSettings>(settings);
  const formChange = (key: string, value: string) => {
    updateSettings((settings) => {
      return { ...settings, [key]: value };
    });
  };

  if (sources.length > 0) {
    if (scannerSettings.source === 'none') {
      if (sources[0].ProductName) {
        scannerSettings.source = sources[0].ProductName;
      }
    }
    return (
      <div>
        <Modal toggle={toggle} isOpen={modal}>
          <ModalHeader>Scanner Settings</ModalHeader>
          <Form style={{ margin: '50px' }}>
            <Row>
              <Col sm={12}>
                <div className="form-group">
                  <label htmlFor="">Source</label>
                  <select
                    className="form-control"
                    aria-label="Select Source"
                    value={scannerSettings.source}
                    name="source"
                    onChange={(e) => formChange(e.target.name, e.target.value)}
                  >
                    {sources.map((source, index) => {
                      return <option key={index}>{source.ProductName}</option>;
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="">DPI</label>
                  <select
                    name="dpi"
                    aria-label="Select DPI"
                    className="form-control"
                    value={scannerSettings.dpi}
                    onChange={(e) => formChange(e.target.name, e.target.value)}
                  >
                    <option value="150">150</option>
                    <option value="200">200</option>
                    <option value="250">250</option>
                    <option value="300">300</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="">Color Scale</label>
                  <select
                    name="colorscale"
                    id=""
                    aria-label="Select Color Scale"
                    className="form-control"
                    value={scannerSettings.colorscale}
                    onChange={(e) => formChange(e.target.name, e.target.value)}
                  >
                    <option value="0">B&W</option>
                    <option value="1">Gray</option>
                    <option value="2">Color</option>
                  </select>
                </div>
              </Col>
            </Row>
          </Form>
          <ModalFooter>
            <Row>
              <Col sm={6}>
                <Button className={styles.scrbBtnBlue} onClick={toggle} block>
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button className={styles.scrbBtnOrange} onClick={() => onSubmit(scannerSettings)} type="submit" block>
                  Save
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  return (
    <div>
      <Modal toggle={toggle} isOpen={modal}>
        <ModalHeader>Scanner Settings</ModalHeader>
        <Row>
          <Col sm={12} style={{ textAlign: 'center' }}>
            <h4>No devices found</h4>
          </Col>
        </Row>
        <ModalFooter>
          <Row>
            <Col sm={12}>
              <Button onClick={toggle} block>
                Close
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ScannerSettingsModal;
