import React, { useCallback } from 'react';
import { Switch } from 'react-router-dom';
import styles from '../styles/archiveui.module.css';
import AuthService from '../auth/authservice';
import { UserProvider } from '../contexts/UserContext';
import SideNav from './SideNav';
import { UIProvider } from '../contexts/UIContext';
import { MetaProvider } from '../contexts/MetaContext';
import ErrorBoundary from '../errorboundary';
import { CartProvider } from '../contexts/CartContext';
import Toast from '../toast';
import { Routes } from './routes';

const Main = () => {
  const logout = useCallback(async () => {
    const auth = await AuthService.getInstance();
    await auth.logout();
  }, []);
  const mainContent = (
    <div>
      <UserProvider>
        <CartProvider>
          <MetaProvider>
            <UIProvider>
              <div className={styles.mainWrapper}>
                <SideNav logout={logout} />
                <div className={styles.mainContainer}>
                  <div className={styles.mainContainerBody}>
                    <Switch>
                      <ErrorBoundary>
                        <Routes />
                      </ErrorBoundary>
                    </Switch>
                  </div>
                </div>
              </div>
            </UIProvider>
          </MetaProvider>
        </CartProvider>
      </UserProvider>
      <Toast />
    </div>
  );

  return mainContent;
};

export default Main;
