import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, Progress } from 'reactstrap';

interface IndexModalProps {
  modal?: boolean;
  progress: number;
}

const TypeModal = ({ modal, progress }: IndexModalProps) => {
  return (
    <Modal isOpen={modal}>
      <ModalHeader style={{ backgroundColor: '#ceeaf4' }}>Processing Upload</ModalHeader>
      <ModalBody>
        <Progress value={progress} min={0} max={100} label={`${progress}%`} color="info" />
      </ModalBody>
    </Modal>
  );
};

export default TypeModal;
