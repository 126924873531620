import React, { useState } from 'react';
import { CellProps, Column, HeaderGroup, useSortBy, useTable } from 'react-table';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { getDocumentIndexesFromXML, ImportedDocumentIndex, IndexGroup, saveImportGroupsToMeta } from '.';
import { getMeta } from '../../api/meta';
import styles from '../../styles/archiveui.module.css';

interface ImportTypesProps {
  accountId: string;
}

const parseIndexesFromXML = (xmlString: string): ImportedDocumentIndex[] => {
  const xmlDocument = new DOMParser().parseFromString(xmlString.replace(/dc:/g, ''), 'application/xml');
  return getDocumentIndexesFromXML(xmlDocument);
};

const ImportIndexes = ({ accountId }: ImportTypesProps): JSX.Element => {
  const [importedDocumentIndexes, setIndexes] = useState<IndexGroup[]>([]);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [importURL, setImportURL] = useState('');

  const startImagingImportFile = async () => {
    if (!selectedFile) {
      toast.error('You must upload an xml file to import.');
      return;
    }
    const xmlString = await selectedFile.text();
    startImagingImport(xmlString);
  };

  const startImagingImportURL = async () => {
    if (!importURL) {
      toast.error('You must enter a url to import from.');
      return;
    }
    const resp = await fetch(importURL);
    const xmlString = await resp.text();
    startImagingImport(xmlString);
  };

  const startImagingImport = async (xmlString: string) => {
    const incomingIndexes = parseIndexesFromXML(xmlString);
    //get current meta... loop to check for existing types and set
    const curMeta = await getMeta(accountId);

    //create the index groupings
    const indexMap = new Map<string, IndexGroup>();
    for (const index of incomingIndexes) {
      let indexGroup = indexMap.get(index.dbname);
      if (!indexGroup) {
        indexGroup = {
          dbname: index.dbname,
          indexes: [],
        };
        indexMap.set(index.dbname, indexGroup);
      }
      indexGroup.indexes.push(index);
    }
    const groups: IndexGroup[] = [];
    for (const group of indexMap.values()) {
      groups.push(group);
    }
    const updatedGroups = groups.map((indexGroup) => {
      for (const existingType of curMeta.documentIndexes) {
        if (indexGroup.dbname === existingType.dbname) {
          indexGroup.alreadyExists = true;
          return indexGroup;
        }
      }
      return indexGroup;
    });
    setIndexes(updatedGroups);
  };

  const clearIndexes = () => {
    setIndexes([]);
  };

  const createIndexes = async () => {
    toggleConfirmation();
    const selectedIndexes: IndexGroup[] = importedDocumentIndexes.filter((index) => index.selectedForImport);
    //convert groups to indexes

    await saveImportGroupsToMeta(accountId, selectedIndexes);
    clearIndexes();
  };

  const updateIndexesSelected = (dbname: string | null | undefined) => {
    if (!dbname) {
      return;
    }
    setIndexes((oldIndexes) => {
      return oldIndexes.map((index) => {
        if (index.dbname === dbname) {
          return { ...index, selectedForImport: !index.selectedForImport };
        }
        return index;
      });
    });
  };
  const [modal, setModal] = useState(false);

  const fileChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      if (e.currentTarget.files.length > 0) {
        const f = e.currentTarget.files.item(0);
        if (f) {
          setSelectedFile(f);
        }
      }
    }
  };

  const toggleConfirmation = () => setModal(!modal);
  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'DB Name',
          accessor: 'dbname',
        },
        {
          Header: 'Name(s)',
          accessor: (val) => `${val.dbname}_name`,
          // eslint-disable-next-line react/display-name
          Cell: (cellProps: CellProps<IndexGroup>) => {
            return (
              <span>
                {cellProps.rowsById[cellProps.row.id].original.indexes.map((index) => (
                  <div key={index.id}>
                    <strong>{index.name}</strong>
                  </div>
                ))}
              </span>
            );
          },
        },
        {
          Header: 'Type(s)',
          accessor: (val) => `${val.dbname}_types`,
          // eslint-disable-next-line react/display-name
          Cell: (cellProps: CellProps<IndexGroup>) => {
            return (
              <span>
                {cellProps.rowsById[cellProps.row.id].original.indexes.map((index) => (
                  <div key={index.id}>
                    <strong>{index.type}</strong>
                  </div>
                ))}
              </span>
            );
          },
        },
        {
          Header: 'Parent',
          accessor: 'indexes',
          // eslint-disable-next-line react/display-name
          Cell: (cellProps) => {
            return (
              <span>
                {cellProps.rowsById[cellProps.row.id].original.indexes.map((index) => (
                  <div key={index.id}>
                    <strong>{index.parentName}</strong>
                  </div>
                ))}
              </span>
            );
          },
        },
        {
          Header: 'Import',
          accessor: 'selectedForImport',
          // eslint-disable-next-line react/display-name
          Cell: (cellProps) => {
            let display = '❌';
            let click = () => {
              ('');
            };
            if (!cellProps.rowsById[cellProps.row.id].original.alreadyExists) {
              display = cellProps.value ? '✅' : '⛔️';
              click = () => {
                updateIndexesSelected(cellProps.rowsById[cellProps.row.id].original.dbname);
              };
            }
            return <span onClick={click}>{display}</span>;
          },
        },
      ] as Column<IndexGroup>[],
    [],
  );
  const data = React.useMemo(() => importedDocumentIndexes, [importedDocumentIndexes]);
  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );
  const renderSort = (column: HeaderGroup<IndexGroup>): JSX.Element => {
    if (column.isSorted) {
      return <span>{column.isSortedDesc ? ' 🔽' : '🔼'}</span>;
    }
    return <span></span>;
  };

  const table = document.getElementsByClassName('table-responsive');

  if (table != undefined) {
    for (let i = 0; i < table.length; i++) {
      const slide = table[i] as HTMLElement;
      slide.style.borderRadius = '.5em';
    }
  }

  return (
    <div>
      <CardBody className={styles.searchBarBody}>
        <div className="row">
          <div className="col">
            <InputGroup>
              <Input placeholder="Input ScribOnline URL" onChange={(e) => setImportURL(e.target.value)}></Input>
              <InputGroupAddon addonType="append">
                <Button className={styles.scrbBtnBlue} onClick={startImagingImportURL}>
                  Load
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>
          <div className="col">
            <InputGroup>
              <CustomInput id="fileInput" type="file" onChange={fileChange} accept=".xml" />
              <InputGroupAddon addonType="append">
                <Button className={styles.scrbBtnBlue} onClick={startImagingImportFile}>
                  Load
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>
          <div className="col-md-auto">
            <ButtonGroup>
              <Button className={styles.scrbBtnOrange} onClick={toggleConfirmation}>
                Import Selected Indexes
              </Button>
              <Button onClick={clearIndexes}>Clear</Button>
            </ButtonGroup>
          </div>
        </div>
        <br />
      </CardBody>
      {data.length > 0 ? (
        <Card className={styles.tableContainer}>
          <Table striped hover size="sm" id="auditTable" responsive>
            <thead className={styles.tableHeader}>
              {headerGroups.map((headerGroup) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      if (column.Header === 'DB Name') {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.IdHeader}>
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                          </th>
                        );
                      } else if (column.Header === 'Actions') {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.actionsHeader}>
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                          </th>
                        );
                      } else {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.th}>
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                          </th>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.column.Header === 'DB Name') {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td style={{ paddingLeft: '1.25em' }} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      } else {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      ) : (
        <Alert color="warning" className={styles.noResultsAlert}>
          No indexes imported...
        </Alert>
      )}

      <Modal isOpen={modal} toggle={toggleConfirmation}>
        <ModalHeader style={{ backgroundColor: '#ceeaf4' }}>Import the select document indexes?</ModalHeader>
        <ModalBody style={{ backgroundColor: '#F4F4F4' }}>
          Import the follwing indexes? <br />
          {importedDocumentIndexes.map((index) => {
            if (index.selectedForImport && !index.alreadyExists) {
              return (
                <div key={index.dbname}>
                  <strong>{index.dbname}</strong>
                </div>
              );
            }
            return '';
          })}
        </ModalBody>
        <ModalFooter style={{ backgroundColor: '#F4F4F4' }}>
          <Button className={styles.scrbBtnOrange} onClick={createIndexes}>
            Import
          </Button>{' '}
          <Button className={styles.scrbBtnBlue} onClick={toggleConfirmation}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ImportIndexes;
