import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../styles/archiveui.module.css';
import logo from './scribonline_blue.png';
import birdHead from './bird-logo-head.png';
import { useUIContext } from '../contexts/UIContext';

const Navigation = () => {
  const location = useLocation();
  let pageTitle = '';
  if (location.pathname === '/') {
    pageTitle = 'Search Documents';
  } else if (location.pathname === '/document') {
    pageTitle = 'Add Document';
  } else if (location.pathname === '/users') {
    pageTitle = 'Users';
  } else if (location.pathname === '/importusers') {
    pageTitle = 'Import Users';
  } else if (location.pathname === '/roles') {
    pageTitle = 'Roles';
  } else if (location.pathname === '/meta') {
    pageTitle = 'Manage Documents';
  } else if (location.pathname === '/batch') {
    pageTitle = 'Manage Batches';
  } else if (location.pathname === '/accountlist') {
    pageTitle = 'Manage Accounts';
  } else if (location.pathname === '/auditlog') {
    pageTitle = 'Audit Log';
  }
  const { sideBarOpen } = useUIContext();
  return (
    <div>
      <div className={styles.mainNav} style={{ maxHeight: '56px', padding: 'none' }}>
        <img src={birdHead} style={{ height: '45px', top: 0, left: 0, transform: 'scaleX(-1)' }} alt="" />
        <img src={logo} style={{ height: '56px', top: 0, left: 0, display: sideBarOpen ? 'flex' : 'none' }} alt="" />
      </div>
    </div>
  );
};

export default Navigation;
