import React, { useEffect, useState } from 'react';
import UserTable from '../apiuserlisttable/usertable';
import { useParams } from 'react-router';
import { Loading, LoadingComponent } from '../../../Loading';
import { APIUser } from '../../../types/user';
import { getAPIUsersForAccount } from '../../../api/users';
import { toastError } from '../../../toast';

export type StatusFilter = 'ACTIVE' | 'INACTIVE' | '*';

interface APIUserListParams {
  accountId: string;
}

const APIUserList = () => {
  const [users, updateUsers] = useState<APIUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { accountId } = useParams<APIUserListParams>();
  const usersPerPage = 26;

  const setUsers = async () => {
    const users = await toastError(getAPIUsersForAccount(accountId), 'Unable to fetch API users');
    updateUsers(users);
    setLoading(false);
  };

  useEffect(() => {
    setUsers();
  }, []);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstAccount = indexOfLastUser - usersPerPage;
  const currentAccounts = users.slice(indexOfFirstAccount, indexOfLastUser);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <LoadingComponent isLoading={loading}>
      <div>
        <UserTable users={currentAccounts} accountId={accountId} />
      </div>
    </LoadingComponent>
  );
};

export default APIUserList;
