import React, { useCallback } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AiFillEdit } from 'react-icons/ai';
import { Column, Row, useSortBy, useTable } from 'react-table';
import { Alert, Card, Table } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import moment from 'moment';
import { FaSort } from 'react-icons/fa';
import { DocumentType } from '../../types/meta';
import update from 'immutability-helper';
import AccessControl from '../../users/userprofile/accesscontrol';
import { userHasAccess } from '../../users/userprofile/user';

interface IndexTableProps {
  documentTypes: DocumentType[];
  onRowClick: (cell: any) => void;
  setDocumentTypes: (indexes: DocumentType[]) => void;
}

export const TypeTable = ({ documentTypes, onRowClick, setDocumentTypes }: IndexTableProps) => {
  const getTypeColumns = () =>
    React.useMemo(() => {
      const cols = [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Created',
          accessor: 'created',
          Cell: (cellProps) => {
            const created = new Date(cellProps.value);
            return `${created.getMonth() + 1}/${created.getDate()}/${created.getFullYear()}`;
          },
        },
        {
          Header: 'Updated',
          accessor: 'modified',
          Cell: (cellProps) => {
            const modified = new Date(cellProps.value);
            return `${modified.getMonth() + 1}/${modified.getDate()}/${modified.getFullYear()}`;
          },
        },
        {
          Header: 'Status',
          accessor: 'status',
        },
        {
          Header: 'Short Code',
          accessor: 'shortCode',
        },
      ] as Column<DocumentType>[];

      if (userHasAccess([{ permissionId: 'meta', action: 'create' }])) {
        cols.push({
          Header: 'Actions',
          // eslint-disable-next-line react/display-name
          Cell: (props: any) => (
            <span
              className={styles.actionsCell}
              onClick={() => {
                onRowClick(props.cell);
              }}
            >
              <AiFillEdit id="edit" color="#212529" />
            </span>
          ),
        });
      }
      return cols;
    }, []);

  const typeColumns: Column<DocumentType>[] = getTypeColumns();

  if (!typeColumns) return null;
  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);

  const table = useTable({
    columns: typeColumns,
    data: documentTypes,
    getRowId,
  });

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = documentTypes[dragIndex];
      setDocumentTypes(
        update(documentTypes, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        }),
      );
    },
    [documentTypes],
  );

  const responsiveTable = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (responsiveTable != undefined) {
    responsiveTable.style.borderRadius = '.5em';
  }

  return (
    <>
      {documentTypes.length > 0 ? (
        <Card className={styles.tableContainer}>
          <DndProvider backend={HTML5Backend}>
            <Table striped hover size="sm" id="auditTable" responsive style={{ backgroundColor: 'white' }} {...table.getTableProps()}>
              <thead className={styles.tableHeader}>
                {table.headerGroups.map((headerGroup) => (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th className={styles.th}>Move Up/Down</th>
                    {headerGroup.headers.map((column) => {
                      if (column.Header === 'Name') {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <th {...column.getHeaderProps()} className={styles.th}>
                            {column.render('Header')}
                          </th>
                        );
                      } else if (column.Header === 'Actions') {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <th {...column.getHeaderProps()} className={styles.actionsHeader}>
                            {column.render('Header')}
                          </th>
                        );
                      } else {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <th {...column.getHeaderProps()} className={styles.th}>
                            {column.render('Header')}
                          </th>
                        );
                      }
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...table.getTableBodyProps()}>
                {table.rows.map((row, index) => {
                  table.prepareRow(row);
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <AccessControl permissionId={'meta'} action={'create'} alternateChildren={<RowWithoutDrag row={row} />}>
                      <RowWithDrag index={index} row={row} moveRow={moveRow} {...row.getRowProps()} />
                    </AccessControl>
                  );
                })}
              </tbody>
            </Table>
          </DndProvider>
        </Card>
      ) : (
        <Alert color="warning" className={styles.noResultsAlert}>
          No results found...
        </Alert>
      )}
    </>
  );
};

export default TypeTable;
const DND_ITEM_TYPE = 'row';

const BaseRow = ({ row }: { row: Row<DocumentType> }) => {
  return (
    <>
      {row.cells.map((cell: any, key: any) => {
        if (cell.column.Header === 'Created' || cell.column.Header === 'Updated') {
          return (
            <td key={key} {...cell.getCellProps()}>
              {moment(cell.render('Cell')).format('MM/DD/YYYY')}
            </td>
          );
        } else if (cell.column.Header === 'Actions') {
          return (
            <td key={key} {...cell.getCellProps()} className={styles.metaActionsCell}>
              {cell.render('Cell')}
            </td>
          );
        } else {
          return (
            <td key={key} {...cell.getCellProps()}>
              {cell.render('Cell')}
            </td>
          );
        }
      })}
    </>
  );
};

const RowWithDrag = ({ row, index, moveRow }: any) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item: any, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Object is possibly 'null'.
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Object is possibly 'null'.
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      item.index = hoverIndex;
      moveRow(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DND_ITEM_TYPE,
      item: { type: DND_ITEM_TYPE, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [index],
  );

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr ref={dropRef} style={{ opacity }}>
      <td ref={dragRef}>
        <FaSort />
      </td>
      <BaseRow row={row} />
    </tr>
  );
};

const RowWithoutDrag = ({ row }: { row: Row<DocumentType> }) => {
  return (
    <tr>
      <td></td>
      <BaseRow row={row} />
    </tr>
  );
};
