import React, { useEffect, useRef } from 'react';
import PSPDFKitWeb from 'pspdfkit';
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

export const PSPDFKit = (props) => {
  const containerRef = useRef();
  const history = useHistory();

  useEffect(() => {
    (async function () {
      return load();
    })();
  }, []);

  const createStamp = (stampConfig) => {
    let width = 300;
    let height = 100;
    if (stampConfig.width) {
      width = stampConfig.width;
    }
    if (stampConfig.height) {
      height = stampConfig.height;
    }
    return new PSPDFKitWeb.Annotations.StampAnnotation({
      stampType: 'Revised',
      title: stampConfig.text,
      color: new PSPDFKitWeb.Color({ r: 64, g: 0, b: 64 }),
      boundingBox: new PSPDFKitWeb.Geometry.Rect({
        left: 0,
        top: 0,
        width,
        height,
      }),
    });
  };

  const annotationTooltipCallback = (annotation) => {
    if (annotation && annotation.creatorName && annotation.creatorName !== '') {
      const userNameItem = {
        type: 'custom',
        title: annotation.creatorName,
        id: 'tooltip-userid-annotation',
        className: 'TooltipItem-UserId',
      };
      return [userNameItem];
    } else {
      return [];
    }
  };

  const load = async () => {
    const stampAnnotationTemplates = [...PSPDFKitWeb.defaultStampAnnotationTemplates];
    const customStamps = [
      { text: 'Unofficial' },
      { text: 'NOT ACCREDITED' },
      { text: 'ELECTRONIC' },
      { text: 'VERIFIED' },
      { text: 'DAMAGED' },
      { text: 'Degree in the Field' },
      { text: 'DPI Official' },
      {
        text: 'Duplicate Transcript',
        height: 150,
        width: 400,
      },
      { text: 'Expired' },
      { text: 'Max 30sch' },
      { text: 'Need IEE' },
      { text: 'No Change' },
      { text: 'R/12' },
      { text: 'See O/T' },
      { text: 'Tested-Out' },

      { text: 'Time Stamp' },
      { text: 'Stop Payment' },
    ];
    const stamps = [];
    customStamps.forEach((element) => {
      stamps.push(createStamp(element));
    });

    let toolbarItems = PSPDFKitWeb.defaultToolbarItems;
    console.log('toolbar', toolbarItems);
    let pagerIndex = toolbarItems.findIndex((item) => item.type === 'pager');
    toolbarItems.splice(pagerIndex + 1, 0, { type: 'layout-config' });
    toolbarItems.push({
      type: 'custom',
      id: 'close_viewer',
      title: 'Close',
      onPress: () => {
        history.push('/');
      },
    });

    const container = containerRef.current;
    let config = {
      disableWebAssemblyStreaming: true,
      document: props.pdfUrl,
      baseUrl: props.baseUrl,
      licenseKey: props.licenseKey,
      container,
      printMode: PSPDFKitWeb.PrintMode.EXPORT_PDF,
      stampAnnotationTemplates: stamps.concat(stampAnnotationTemplates),
      toolbarItems,
      annotationTooltipCallback,
    };

    if (!props.editAnnotations) {
      config.isEditableAnnotation = () => {
        return false;
      };
    }

    if (props.instantJSON) {
      config.instantJSON = props.instantJSON;
    }

    if (!props.editAnnotations) {
      config.toolbarItems = config.toolbarItems.filter((item) => {
        return (
          item.type !== 'annotate' &&
          item.type !== 'ink' &&
          item.type !== 'highlighter' &&
          item.type !== 'text-highlighter' &&
          item.type !== 'signature' &&
          item.type !== 'image' &&
          item.type !== 'stamp' &&
          item.type !== 'note' &&
          item.type !== 'text' &&
          item.type !== 'line' &&
          item.type !== 'arrow' &&
          item.type !== 'rectangle' &&
          item.type !== 'polygon' &&
          item.type !== 'polyline' &&
          item.type !== 'ellipse' &&
          item.type !== 'ink-eraser'
        );
      });
    }
    console.log('config.toolbarItems', config.toolbarItems);

    if (props.hideToolbar) {
      config.toolbarItems = [{ type: 'pager' }, { type: 'zoom-in' }, { type: 'zoom-out' }, { type: 'zoom-mode' }, { type: 'print' }];
    }

    const tempInstance = await PSPDFKitWeb.load(config);
    tempInstance.addEventListener('annotations.didSave', async () => {
      props.onSaveBegin();
      const promises = [];
      const instantJSON = await tempInstance.exportInstantJSON();
      if (props.saveAnnotations) {
        if (instantJSON.pdfId) {
          instantJSON.pdfId = null;
        }
        promises.push(props.saveAnnotations(instantJSON));
      }
      const flattenedPdf = await tempInstance.exportPDF({ flatten: true });
      promises.push(props.saveDocumentEdit(flattenedPdf, true));
      await toast.promise(Promise.all(promises), {
        pending: 'Saving Updates...',
        success: 'Updates Saved.',
        error: 'Unable to Save Document Updates',
      });
      props.onSaveEnd();
    });

    if (props.userId) {
      tempInstance.setAnnotationCreatorName(props.userId);
    }

    tempInstance.addEventListener('document.change', async () => {
      if (props.saveDocumentEdit) {
        props.onSaveBegin();
        const newPdf = await tempInstance.exportPDF();
        const flattenedPdf = await tempInstance.exportPDF({ flatten: true });
        const promises = [props.saveDocumentEdit(newPdf), props.saveDocumentEdit(flattenedPdf, true)];
        if (props.saveAnnotations) {
          const instantJSON = await tempInstance.exportInstantJSON();
          if (instantJSON.pdfId) {
            instantJSON.pdfId = null;
          }
          promises.push(props.saveAnnotations(instantJSON));
        }

        await toast.promise(Promise.all(promises), {
          pending: 'Saving Updates...',
          success: 'Updates Saved.',
          error: 'Unable to Save Document Updates',
        });
        props.onSaveEnd();
      }
    });

    const documentEditorToolbarItems = PSPDFKitWeb.defaultDocumentEditorToolbarItems;
    const newItems = documentEditorToolbarItems.filter((item) => {
      if (!props.showInsertPages) {
        if (item.type === 'add' || item.type === 'duplicate' || item.type === 'import-document') {
          return false;
        }
      }

      if (!props.showDeletePages) {
        if (item.type === 'remove') {
          return false;
        }
      }
      return true;
    });

    tempInstance.setDocumentEditorToolbarItems(newItems);
    tempInstance.setStampAnnotationTemplates((templates) => [...templates]);
    return () => PSPDFKit && PSPDFKit.unload(container);
  };

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: 'gray',
      }}
    />
  );
};

PSPDFKit.propTypes = {
  baseUrl: PropTypes.string,
  pdfUrl: PropTypes.any,
  saveAnnotations: PropTypes.func,
  saveDocumentEdit: PropTypes.func,
  onSaveBegin: PropTypes.func,
  onSaveEnd: PropTypes.func,
  instantJSON: PropTypes.any,
  userId: PropTypes.string,
  licenseKey: PropTypes.string,
  hideToolbar: PropTypes.bool,
  showInsertPages: PropTypes.bool,
  showDeletePages: PropTypes.bool,
  editAnnotations: PropTypes.bool,
};

export default withRouter(PSPDFKit);
