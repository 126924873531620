import React, { createContext, useCallback, useState } from 'react';

interface UIContextProps {
  sideBarOpen: boolean;
  toggleSidebar: () => void;
  accountSearch?: string;
  setAccountSearch: (newSearch: string) => void;
}

const UIContext = createContext<UIContextProps | undefined>(undefined);

type UIProviderProps = { children: React.ReactNode };
export const UIProvider = ({ children }: UIProviderProps) => {
  const [sideBarOpen, setSidebarOpen] = useState<boolean>(true);
  const [accountSearch, setAccountSearch] = useState<string>();
  const toggleSidebar = useCallback(() => {
    setSidebarOpen((isOpen) => {
      return !isOpen;
    });
  }, []);
  return <UIContext.Provider value={{ sideBarOpen, toggleSidebar, accountSearch, setAccountSearch }}>{children}</UIContext.Provider>;
};

export const useUIContext = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error('useMetaReducer must be used within a MetaProvider');
  }
  return context;
};
