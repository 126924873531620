import React, { ChangeEvent } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { PicklistItem } from '../../../../types/meta';

interface IndexInputProps {
  indexName: string;
  indexId: string;
  pickListOptions: PicklistItem[];
  value?: string;
  isRequired?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const PicklistInput = ({ indexName, indexId, pickListOptions, value, isRequired, onChange }: IndexInputProps) => {
  return (
    <FormGroup>
      <Label for={indexId}>{indexName}</Label>
      <Input type="select" name={indexId} id={indexId} onChange={onChange} value={value} required={isRequired}>
        <option></option>
        {pickListOptions?.map((option, index) => {
          return (
            <option key={index} value={option.key}>
              {option.value}
            </option>
          );
        })}
      </Input>
    </FormGroup>
  );
};
