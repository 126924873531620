import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { getDocument, saveDocument } from '../../api/document';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Alert, Button, Col, Container, Form, Row } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import { LoadingComponent } from '../../Loading';
import TypeSelect from '../metacomponents/type/typeselect';
import { IndexFields } from '../metacomponents/index/indexfields';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import { StatusInput } from './statusinput';
import { Document, DocumentStatus } from '../../types/document';
import { sanitizeDates } from '../metacomponents/metautils';
import { useMetaProvider } from '../../contexts/MetaContext';
import { useUserContext } from '../../contexts/UserContext';
import ProgressModal from './uploadprogressmodal';
import AccessControl from '../../users/userprofile/accesscontrol';
import { Link } from 'react-router-dom';
import { doOrganizerLookup } from '../../api/organizer';
import { toastError } from '../../toast';
import { organizerLookup } from '../../organizer';

interface DocumentDetailProps {
  accountId: string;
}

export const DocumentDetail = () => {
  const history = useHistory();
  const { currentAccount } = useUserContext();
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [indexValues, setIndexValues] = useState<Record<string, string | number | Date>>({});
  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState<Document>();
  const [uploadError, setUploadError] = useState<string>();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>();
  const [documentStatus, setDocumentStatus] = useState<DocumentStatus>('ACTIVE');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [lookingUp, setLookingUp] = useState<boolean>(false);
  const { documentId } = useParams<{ documentId?: string }>();
  const { metaState } = useMetaProvider();

  const resetDetail = () => {
    setSelectedTypes([]);
    setIndexValues({});
    setDocument(undefined);
    setUploadError(undefined);
    setUploadedFiles(undefined);
    setDocumentStatus('ACTIVE');
    setProgress(0);
  };

  useEffect(() => {
    const init = async () => {
      if (documentId) {
        const existingDoc = await getDocument(currentAccount.accountId, documentId);
        console.log('got document ' + JSON.stringify(existingDoc, null, 2));
        if (existingDoc) {
          setDocument(existingDoc);
          setIndexValues(existingDoc?.indexes);
          setSelectedTypes(existingDoc?.types);
          setDocumentStatus(existingDoc.status);
        }
      }
      setLoading(false);
    };

    init();
  }, [currentAccount]);

  const _organizerLookupInternal = async () => {
    const primaryIndex = metaState.documentIndexes.find((index) => index.id === currentAccount.organizerPrimaryIndex);
    if (!primaryIndex) {
      console.error('Primary index not found');
      return;
    }
    const lookupValue = indexValues[primaryIndex.id];
    const newValues = await organizerLookup(currentAccount.accountId, lookupValue, { ...indexValues });
    if (newValues) {
      setIndexValues((prevValues) => {
        return { ...prevValues, ...newValues };
      });
    }
  };

  const organizerLookupInternal = async () => {
    setLookingUp(true);
    try {
      await _organizerLookupInternal();
    } catch (e) {
      console.error(e);
    } finally {
      setLookingUp(false);
    }
  };

  const toggleSelectedType = (event: ChangeEvent<HTMLInputElement>) => {
    let currentTypes = [...selectedTypes];
    const typeId = event.target.name;
    const isSet = event.target.checked;

    if (isSet) {
      currentTypes.push(typeId);
    } else {
      currentTypes = currentTypes.filter((type) => {
        return type !== typeId;
      });
    }

    setSelectedTypes(currentTypes);
    console.log(currentTypes);
  };

  const updateIndexValue = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValues = { ...indexValues };
    const indexId = event.target.name;
    const value = event.target.value;
    currentValues[indexId] = value;
    setIndexValues(currentValues);
  };

  const onDrop = useCallback((acceptedFiles) => {
    console.log('on drop', acceptedFiles);
    imageUploaded(acceptedFiles);
  }, []);

  const imageUploaded = (files: File[]) => {
    if (!files || files.length === 0) {
      setUploadedFiles(undefined);
      return false;
    }

    setUploadedFiles(files);
    setUploadError(undefined);
  };

  const _onSubmit = async () => {
    if (selectedTypes.length === 0) {
      setUploadError('Please select at least one document type.');
      return;
    }
    let docToSubmit: Document;
    if (document) {
      docToSubmit = {
        ...document,
        types: selectedTypes,
        indexes: sanitizeDates(metaState.documentIndexes, indexValues),
        modified: new Date(),
        status: documentStatus,
      };
    } else {
      if (!uploadedFiles || uploadedFiles.length === 0) {
        setUploadError('You must upload a file.');
        return;
      }

      docToSubmit = {
        id: uuid(),
        accountId: currentAccount.accountId,
        created: new Date(),
        modified: new Date(),
        version: 1,
        contentType: 'application/pdf',
        pages: 0,
        types: selectedTypes,
        indexes: sanitizeDates(metaState.documentIndexes, indexValues),
        status: documentStatus,
        createdBy: '',
      };
    }
    const response = await saveDocument(currentAccount.accountId, docToSubmit, uploadedFiles, setProgress);
    if (response === 200 || response === 204) {
      toast.success(`Document successfully ${documentId ? 'updated' : 'uploaded'}.`);
      documentId ? history.push('/') : resetDetail();
    }
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      await _onSubmit();
    } catch (e) {
      console.log(e);
      toast.error(`Unable to ${documentId ? 'update' : 'upload'} document, please try again.`);
    } finally {
      setIsSubmitting(false);
      setProgress(0);
    }
  };

  const updateDocumentStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setDocumentStatus(event.target.value as DocumentStatus);
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({ onDrop, accept: 'image/png,image/jpeg,image/tiff,application/pdf' });
  const fileRejectionItems = fileRejections.map(({ file, errors }, index) => (
    <li key={`rejection_${index}`}>
      {file.name} - {errors[0].message}
    </li>
  ));

  return (
    <LoadingComponent isLoading={loading}>
      <LoadingOverlay active={lookingUp}>
        <Container className={styles.detailContainer}>
          <Form
            style={{ marginTop: '10px' }}
            onSubmit={async (event) => {
              event.preventDefault();
              await onSubmit();
            }}
          >
            <Row>
              <Col sm="4">
                <h3>Status</h3>
                <StatusInput onChange={updateDocumentStatus} defaultValue={documentStatus} />
                <h3>Document Types</h3>
                <TypeSelect toggleType={toggleSelectedType} selectedTypes={selectedTypes} />
              </Col>
              <Col sm="8">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <h3>Indexes</h3>
                </div>

                <IndexFields
                  onChange={updateIndexValue}
                  editDocument={document}
                  organizerLookupFunction={organizerLookupInternal}
                  indexValues={{ ...indexValues }}
                  currentAccount={currentAccount}
                  selectedTypes={[...selectedTypes]}
                ></IndexFields>
              </Col>
            </Row>
            <br />
            {uploadError && (
              <Alert color="danger" style={{ display: 'block', marginBottom: '0px' }}>
                {uploadError}
              </Alert>
            )}
            {fileRejections && fileRejections.length > 0 && (
              <Alert color="danger" style={{ display: 'block', marginBottom: '0px' }}>
                <h6>The following uploaded files were rejected:</h6>
                {fileRejectionItems}
              </Alert>
            )}
            <br />
            <Row>
              {document ? (
                <AccessControl
                  requiredPermissionList={[
                    { permissionId: 'scanner', action: 'create' },
                    { permissionId: 'document', action: 'update' },
                  ]}
                >
                  {currentAccount.activateScanner ? (
                    <Col sm="4">
                      <Link to={`/scan?existingDocumentId=${document.id}`}>
                        <Button className={styles.scrbBtnBlue} block>
                          Scan Additional Pages
                        </Button>
                      </Link>
                    </Col>
                  ) : (
                    <></>
                  )}
                </AccessControl>
              ) : (
                <Col sm="4">
                  <div {...getRootProps()}>
                    <div className={styles.scrbBtnBlueRadius}>
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <>Drop the file here ...</>
                      ) : uploadedFiles ? (
                        <>
                          {uploadedFiles[0].name.length < 20 ? uploadedFiles[0].name : `${uploadedFiles[0].name.substring(0, 20)}...`}
                          {uploadedFiles.length > 1 ? ` and ${uploadedFiles.length - 1} other${uploadedFiles.length > 2 ? 's' : ''}` : ''}
                        </>
                      ) : (
                        <>Drop your file here, or click to select a file</>
                      )}
                    </div>
                  </div>
                </Col>
              )}
              <AccessControl permissionId={'document'} action={documentId ? 'update' : 'create'}>
                <Col>
                  <Button className={styles.scrbBtnOrange} type="submit" disabled={isSubmitting} block>
                    Submit
                  </Button>
                </Col>
              </AccessControl>
            </Row>
          </Form>
        </Container>
        <ProgressModal modal={isSubmitting && uploadedFiles && uploadedFiles.length > 0} progress={progress} />
      </LoadingOverlay>
    </LoadingComponent>
  );
};
