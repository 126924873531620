import React, { ChangeEvent, useState } from 'react';
import { Alert, Button, ButtonGroup, Card, CardBody, Col, Form, Row } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import Switch from 'react-switch';
import TypeSelect from '../../document/metacomponents/type/typeselect';
import { IndexFields } from '../../document/metacomponents/index/indexfields';
import { useUserContext } from '../../contexts/UserContext';
import { organizerLookup } from '..';
import { useMetaProvider } from '../../contexts/MetaContext';
import LoadingOverlay from 'react-loading-overlay-ts';
import { toast } from 'react-toastify';
import { printLeadSheets } from '../../api/organizer';
import { toastError } from '../../toast';

const PrintLeadSheets = (): JSX.Element => {
  const { metaState } = useMetaProvider();
  const { currentAccount } = useUserContext();
  const [clearTypesOnPrint, setClearTypesOnPrint] = useState<boolean>(true);
  const [clearIndexesOnPrint, setClearIndexesOnPrint] = useState<boolean>(true);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [error, setError] = useState<string>();
  const [indexValues, setIndexValues] = useState<Record<string, string | number | Date>>({});
  const [loading, setLoading] = useState<boolean>(false);

  const _organizerLookupInternal = async () => {
    const primaryIndex = metaState.documentIndexes.find((index) => index.id === currentAccount.organizerPrimaryIndex);
    if (!primaryIndex) {
      console.error('Primary index not found');
      return;
    }
    const lookupValue = indexValues[primaryIndex.id];
    const newValues = await organizerLookup(currentAccount.accountId, lookupValue, { ...indexValues });
    if (newValues) {
      setIndexValues((prevValues) => {
        return { ...prevValues, ...newValues };
      });
    }
  };

  const organizerLookupInternal = async () => {
    setLoading(true);
    try {
      await _organizerLookupInternal();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const toggleSelectedType = (event: ChangeEvent<HTMLInputElement>) => {
    let currentTypes = [...selectedTypes];
    const typeId = event.target.name;
    const isSet = event.target.checked;

    if (isSet) {
      currentTypes.push(typeId);
    } else {
      currentTypes = currentTypes.filter((type) => {
        return type !== typeId;
      });
    }

    setSelectedTypes(currentTypes);
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      await _onSubmit();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const _onSubmit = async () => {
    if (selectedTypes.length === 0) {
      setError('Please select at least one document type.');
      return;
    }
    if (Object.keys(indexValues).length === 0) {
      setError('Please provide at least one index value.');
      return;
    }
    setError(undefined);

    await toastError(printLeadSheets(currentAccount.accountId, selectedTypes, indexValues), 'Unable to print lead sheets');
    if (clearIndexesOnPrint) setIndexValues({});
    if (clearTypesOnPrint) setSelectedTypes([]);
  };

  const updateIndexValue = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValues = { ...indexValues };
    const indexId = event.target.name;
    const value = event.target.value;
    currentValues[indexId] = value;
    setIndexValues(currentValues);
  };

  const clearForm = () => {
    setIndexValues({});
    setSelectedTypes([]);
  };

  return (
    <div>
      <LoadingOverlay active={loading} spinner>
        <Card style={{ marginTop: '1em', borderRadius: '.5em' }}>
          <CardBody className={styles.searchBarHeader}>
            <h5 style={{ textAlign: 'start', fontWeight: 500, marginTop: '.5em' }}>Print Lead Sheets</h5>
          </CardBody>
          <CardBody className={styles.searchBarBody}>
            <Row>
              <Col sm="4">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Switch checked={clearTypesOnPrint} onChange={(checked) => setClearTypesOnPrint(checked)} />
                  <span>&nbsp;Clear doc types after print</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '3px' }}>
                  <Switch checked={clearIndexesOnPrint} onChange={(checked) => setClearIndexesOnPrint(checked)} />
                  <span>&nbsp;Clear indexes after print</span>
                </div>
              </Col>
              <Col sm="8" className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button className={styles.scrbBtnOrange} onClick={clearForm} style={{ width: '170px' }}>
                    Clear Form
                  </Button>
                  <Button className={styles.scrbBtnBlue} onClick={onSubmit} style={{ width: '110px' }}>
                    Print
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </CardBody>
          <CardBody>
            {error && (
              <Alert color="danger" style={{ display: 'block', marginBottom: '0px' }}>
                {error}
              </Alert>
            )}
            <Form
              style={{ marginTop: '10px' }}
              onSubmit={async (event) => {
                event.preventDefault();
                await onSubmit();
              }}
            >
              <Row>
                <Col sm="4">
                  <h3>Document Types</h3>
                  <TypeSelect toggleType={toggleSelectedType} selectedTypes={selectedTypes} />
                </Col>
                <Col sm="8">
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h3>Indexes</h3>
                  </div>

                  <IndexFields
                    onChange={updateIndexValue}
                    editDocument={document}
                    organizerLookupFunction={organizerLookupInternal}
                    indexValues={{ ...indexValues }}
                    currentAccount={currentAccount}
                    selectedTypes={[...selectedTypes]}
                  ></IndexFields>
                </Col>
              </Row>
              <br />

              <br />
            </Form>
          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  );
};

export default PrintLeadSheets;
