import { UploadManager } from 'dwt/dist/types/Dynamsoft.FileUploader';
import { WebTwain } from 'dwt/dist/types/WebTwain';
import { ScanDocument, ScanPage } from '../types/scanner';
import { getBaseApiUrl } from '../api/helpers';
import { Document } from '../types/document';

const findAndUpdateDocument = (documents: ScanDocument[], document: ScanDocument | string, update: (doc: ScanDocument) => ScanDocument): ScanDocument[] => {
  const documentId = typeof document === 'string' ? document : document.releaseDocument.id;
  return documents.map((doc) => {
    if (doc.releaseDocument.id === documentId) {
      return update(doc);
    }
    return doc;
  });
};

export const getDocument = (documents: ScanDocument[], id: string): ScanDocument | undefined => {
  return documents.find((doc) => doc.releaseDocument.id === id);
};
export const findDocumentByPage = (documents: ScanDocument[], page: ScanPage | string): ScanDocument | undefined => {
  const pageId = typeof page === 'string' ? page : page.id;
  return documents.find((doc) => {
    if (doc.pages.findIndex((docPage) => docPage.id === pageId) > -1) {
      return doc;
    }
  });
};

export const isDocumentEmpty = (document: ScanDocument) => {
  if (document.pages.length === 0) return true;
  if (document.pages.length > 1) return false;
  // document only has one page, is it a barcode?
  const page = document.pages[0];
  if (page.barcodeResults) {
    return true;
  }
  return false;
};

export const updatedDocument = (documents: ScanDocument[], document: ScanDocument): ScanDocument[] | undefined => {
  return findAndUpdateDocument(documents, document, () => document);
};

export const createDocument = (
  documents: ScanDocument[],
  accountId: string,
  userEmail?: string,
  documentPages?: ScanPage[],
  documentContent?: Partial<Document>,
): ScanDocument[] => {
  // need to create the new document and add to the _documents
  //if pages are passed in... try to find them attached to existing docs... if they are... filter them out
  let returnDocs = [...documents];
  if (documentPages) {
    returnDocs = documents.map((doc) => {
      doc.pages = doc.pages.filter((page) => {
        //return true if this page is not
        return documentPages.findIndex((docPage) => docPage.id === page.id) === -1;
      });
      return doc;
    });
  }
  const newDoc = {
    pages: documentPages ? documentPages : [],
    releaseDocument: {
      ...documentContent,
      id: `${accountId}_${new Date().getTime()}`,
      accountId,
      contentType: 'application/pdf',
      created: new Date(),
      modified: new Date(),
      version: 1,
      createdBy: userEmail ?? '',
    },
  };
  returnDocs = [...returnDocs, newDoc];
  return returnDocs;
};

export const releaseDocument = async (
  documents: ScanDocument[],
  document: ScanDocument,
  DWObject: WebTwain,
  uploadManager: UploadManager,
  existingDocumentId?: string,
): Promise<ScanDocument[]> => {
  return new Promise((resolve, reject) => {
    if (!DWObject || !uploadManager) {
      reject('DW Object is undefined');
    }
    const releaseDocument = document.releaseDocument;
    if (!releaseDocument.id || !releaseDocument.accountId || !releaseDocument.types || releaseDocument.types.length === 0 || !releaseDocument.indexes) {
      reject('Missing required fields in document');
    }
    const ids = document.pages.map((page) => Number(page.id));
    const indices = ids.map((id) => DWObject.ImageIDToIndex(id));
    releaseDocument.pages = indices.length;
    releaseDocument.status = 'ACTIVE';
    console.log('indices', indices);
    DWObject.GenerateURLForUploadData(
      indices,
      4,
      (resultURL, newIndices, enumImageType) => {
        const serverUrl = `${process.env.NODE_ENV === 'development' ? getBaseApiUrl() : `https://${window.location.hostname}`}/server/${
          releaseDocument.accountId
        }/scannerupload${existingDocumentId ? `existingDocumentId=${existingDocumentId}` : ''}`;
        const jobtemp = uploadManager.CreateJob();
        jobtemp.ServerUrl = serverUrl;
        jobtemp.FormField.Add('document', JSON.stringify(releaseDocument));
        jobtemp.SourceValue.Add(resultURL, new Date().getTime() + 'uploadedFile.pdf');

        jobtemp.OnRunFailure = (job, errorCode, errorString) => {
          console.log(`error ${errorCode}: ${errorString}`);
        };

        jobtemp.OnRunSuccess = (job) => {
          console.log('success?');
        };

        uploadManager.Run(jobtemp);
        console.log('running job');
        const newDocs = removeDocument(documents, document);
        try {
          DWObject.SelectImages(ids.map((id) => DWObject.ImageIDToIndex(id)));
          DWObject.RemoveAllSelectedImages();
        } catch (e) {
          console.log('error', e);
        }
        resolve(newDocs);
      },
      (errorCode, errorString) => {
        console.log(`Error generating url ${errorCode}`, errorString);
        reject('Unable to prepare document for upload');
      },
    );
  });
};

export const updatePage = (documents: ScanDocument[], page: ScanPage): ScanDocument[] => {
  //for each document
  const newDocs = [...documents];
  for (const document of documents) {
    document.pages = document.pages.map((oldPage) => {
      if (page.id === oldPage.id) {
        return { ...page };
      }
      return oldPage;
    });
  }
  return newDocs;
};

export const getPage = (documents: ScanDocument[], id: string): ScanPage | undefined => {
  //for each document
  for (const document of documents) {
    const page = document.pages.find((page) => page.id === id);
    if (page) {
      return page;
    }
  }
};

export const getPages = (documents: ScanDocument[]): ScanPage[] => {
  return documents.flatMap((doc) => doc.pages);
};

export const addPageToDocument = (
  accountId: string,
  documents: ScanDocument[],
  page: ScanPage,
  document?: ScanDocument,
  userEmail?: string,
  indexes?: Record<string, string | Date | number>,
  type?: string,
) => {
  if (document) {
    return findAndUpdateDocument(documents, document, (doc) => {
      doc.pages = [...doc.pages, page];
      return doc;
    });
  } else {
    let newArray = documents.slice();
    if (newArray.length < 1) newArray = createEmptyDocument(accountId, userEmail, indexes, type);
    newArray[newArray.length - 1].pages = [...newArray[newArray.length - 1].pages, page];
    return newArray;
  }
};

export const barcodeSplit = (documents: ScanDocument[], barcodePage: ScanPage): ScanDocument[] => {
  if (barcodePage.barcodeResults) {
    // find the doc this page belongs to
    let docToSplit: ScanDocument;
    let pageIndex: number;
    documents.forEach((doc) => {
      const tempIndex = doc.pages.findIndex((page) => page.id === barcodePage.id);
      if (tempIndex > -1) {
        pageIndex = tempIndex;
        docToSplit = doc;
      }
    });
    // get the page index in that document
    // get the doc values
    const documentValues = JSON.parse(barcodePage.barcodeResults[0]);
    const tempDocuments = documents.flatMap((document) => {
      if (document.releaseDocument.id === docToSplit.releaseDocument.id) {
        if (pageIndex === 0) {
          return [{ pages: docToSplit.pages, releaseDocument: { ...docToSplit.releaseDocument, ...documentValues, accountId: documentValues.tenantId } }];
        }
        return splitDoc(docToSplit, pageIndex, documentValues);
      } else {
        return document;
      }
    });
    return tempDocuments;
  } else {
    return documents;
  }
};
export const removePage = (documents: ScanDocument[], id: string) => {
  const newDocs = documents.map((doc) => {
    doc.pages = doc.pages.filter((page) => page.id !== id);
    return doc;
  });

  return newDocs.filter((doc) => doc.pages.length > 0);
};

const splitDoc = (docToSplit: ScanDocument, splitAtIndex: number, documentValues?: Partial<Document>): ScanDocument[] => {
  const returnArray: ScanDocument[] = [
    { pages: [], releaseDocument: { ...docToSplit.releaseDocument } },
    { pages: [], releaseDocument: { ...docToSplit.releaseDocument, id: `id${new Date().getTime()}`, ...documentValues } },
  ];
  for (let i = 0; i < docToSplit.pages.length; i++) {
    const curPage = docToSplit.pages[i];
    if (i < splitAtIndex) {
      returnArray[0].pages = [...returnArray[0].pages, curPage];
    } else {
      returnArray[1].pages = [...returnArray[1].pages, curPage];
    }
  }
  return returnArray;
};

export const splitDocument = (
  documents: ScanDocument[],
  docToSplit: ScanDocument,
  splitAtIndex: number,
): { documents: ScanDocument[]; document: ScanDocument | undefined } => {
  let newDocument: ScanDocument | undefined = undefined;
  const newDocs = documents.flatMap((document) => {
    if (document.releaseDocument.id === docToSplit.releaseDocument.id) {
      const splitDocs = splitDoc(docToSplit, splitAtIndex);
      newDocument = splitDocs[1];
      return splitDocs;
    } else {
      return document;
    }
  });
  return {
    document: newDocument,
    documents: newDocs,
  };
};

export const movePage = (documents: ScanDocument[], movePages: ScanPage[] | ScanPage, moveTo: number, destinationDocument?: ScanDocument): ScanDocument[] => {
  //find and remove the page
  let returnDocuments = documents;
  const totalPages = Array.isArray(movePages) ? movePages : [movePages];
  let newMoveTo = moveTo;
  if (destinationDocument) {
    //find the original page and remove it from its current document
    //then move it to the last page of the new document
    returnDocuments = documents.map((doc) => {
      totalPages.forEach((pageToMove) => {
        const pageIndex = doc.pages.findIndex((findPage) => findPage.id === pageToMove.id);
        doc.pages = doc.pages.filter((page) => page.id !== pageToMove.id);
        if (destinationDocument.releaseDocument.id === doc.releaseDocument.id) {
          //if this is the destination add the page
          doc.pages = [...doc.pages, pageToMove];
          if (pageIndex > -1 && pageIndex < moveTo) newMoveTo--;
        }
      });
      return doc;
    });
  }

  if (newMoveTo < moveTo) newMoveTo++;

  returnDocuments = returnDocuments.map((doc) => {
    //does this have the page we are looking for?
    totalPages.forEach((pageToMove) => {
      const pageIndex = doc.pages.findIndex((findPage) => findPage.id === pageToMove.id);
      if (pageIndex > -1) {
        //remove page and insert at new location
        doc.pages.splice(newMoveTo, 0, doc.pages.splice(pageIndex, 1)[0]);
        doc.pages = [...doc.pages];
        newMoveTo++;
      }
    });
    return doc;
  });

  return returnDocuments.filter((doc) => doc.pages.length > 0);
};

export const removeDocument = (documents: ScanDocument[], document: ScanDocument): ScanDocument[] => {
  return documents.filter((doc) => doc.releaseDocument.id !== document.releaseDocument.id);
};

export const updateDocumentIndexValue = (documents: ScanDocument[], documentId: string, indexKey: string, indexValue: string | Date | number) => {
  return updateDocumentIndexValues(documents, documentId, { [indexKey]: indexValue });
};

export const updateDocumentIndexValues = (documents: ScanDocument[], documentId: string, newValues: Record<string, string | Date | number>) => {
  return findAndUpdateDocument(documents, documentId, (doc) => {
    doc.releaseDocument.indexes = { ...doc.releaseDocument.indexes, ...newValues };
    return doc;
  });
};

export const addDocumentType = (documents: ScanDocument[], documentId: string, type: string) => {
  return findAndUpdateDocument(documents, documentId, (doc) => {
    const types = doc.releaseDocument.types;
    types ? types.push(type) : (doc.releaseDocument.types = [type]);
    return doc;
  });
};

export const removeDocumentType = (documents: ScanDocument[], documentId: string, type: string) => {
  return findAndUpdateDocument(documents, documentId, (doc) => {
    doc.releaseDocument.types = doc.releaseDocument.types?.filter((existingType) => type !== existingType);
    return doc;
  });
};

export const createEmptyDocument = (accountId: string, userEmail?: string, indexValues?: Record<string, string | Date | number>, type?: string) => {
  const doc: Partial<Document> = {};
  if (indexValues) {
    doc.indexes = indexValues;
  }
  if (type) {
    doc.types = [type];
  }
  return createDocument([], accountId, userEmail, undefined, doc);
};

export const findFirstPageOfDocument = (documents: ScanDocument[], document: ScanDocument | string | undefined) => {
  if (typeof document === 'string') {
    document = documents.find((doc) => doc.releaseDocument.id === document);
  }
  if (!document || !document.pages || document.pages.length === 0) {
    return;
  }

  return document.pages[0].id;
};

export const getIndexOfDocument = (documents: ScanDocument[], document: ScanDocument | string | undefined) => {
  if (!document) return;
  if (typeof document !== 'string') {
    document = document.releaseDocument.id;
  }

  for (let i = 0; i < documents.length; i++) {
    const curDocument = documents[i];
    if (curDocument.releaseDocument.id === document) return i;
  }
  return 0;
};
