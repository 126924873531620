import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Column, usePagination, useTable } from 'react-table';
import { Button, Card, CustomInput, Table } from 'reactstrap';
import { DocumentType } from '../../types/meta';
import { AiFillEdit } from 'react-icons/ai';
import { Document } from '../../types/document';
import moment from 'moment';
import { useCartContext } from '../../contexts/CartContext';
import { BsFillCartPlusFill } from 'react-icons/bs';
import { BsFillCartXFill } from 'react-icons/bs';
import { useMetaProvider } from '../../contexts/MetaContext';
import { LoadingComponent } from '../../Loading';
import { formatDate } from '../../utils/date';
import { SortField } from '../documentsearch';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import AccessControl from '../../users/userprofile/accesscontrol';
import { findHiddenIndexesForDocTypes } from '../metacomponents/metautils';

interface DocumentTableProps {
  documents: Document[];
  accountId: string;
  thumbnails: Record<string, string>;
  isCart?: boolean;
  selectDocument?: (documentid: string, selected: boolean) => void;
  selectedDocuments?: string[];
  pageSize: number;
  totalPages: number;
  updatePage?: (page: number) => void;
  controlledPage?: number;
  showInactiveTypes?: boolean;
  currentSort?: SortField;
  updateSort?: (newSort: SortField) => void;
}

const DocumentTable = ({
  documents,
  accountId,
  thumbnails,
  isCart,
  selectDocument,
  selectedDocuments,
  pageSize,
  totalPages,
  updatePage,
  controlledPage,
  showInactiveTypes,
  currentSort,
  updateSort,
}: DocumentTableProps): JSX.Element => {
  const { metaState } = useMetaProvider();

  const mappedDocumentTypes = useMemo(() => {
    return new Map(
      metaState.allowedDocumentTypes
        .filter((type) => {
          return type.status === 'active' || (showInactiveTypes && type.status === 'inactive');
        })
        .map((type) => {
          return [type.id, type];
        }),
    );
  }, [metaState, showInactiveTypes]);

  const { cartItems, addToCart, removeFromCart } = useCartContext();

  const updateSortInternal = useCallback(
    (field: string) => {
      if (!updateSort) return;
      let ascending = true;
      if (currentSort && currentSort.field === field && currentSort.ascending) ascending = false;
      updateSort({ field: field, ascending });
    },
    [currentSort, updateSort],
  );

  const getSortIndicator = useCallback(
    (field: string) => {
      if (!currentSort) return null;
      let sortIndicator = null;
      if (currentSort.field === field) {
        sortIndicator = currentSort.ascending ? <FaArrowUp /> : <FaArrowDown />;
      }

      return sortIndicator;
    },
    [currentSort],
  );
  const columns = React.useMemo(
    () =>
      [
        {
          Header: '',
          accessor: 'id',
          id: 'viewerlink',
          // eslint-disable-next-line react/display-name
          Cell: (cellProps) => {
            const thumbnail = thumbnails[cellProps.value];
            return (
              <>
                {thumbnail && (
                  <Link to={`/viewer/${cellProps.value}`}>
                    <img src={`data:image/png;base64,${thumbnail}`}></img>
                  </Link>
                )}
              </>
            );
          },
        },
        {
          Header: () => {
            const sortFieldName = 'types';
            const sortIndicator = getSortIndicator(sortFieldName);
            return (
              <div
                style={updateSort ? { cursor: 'pointer' } : {}}
                onClick={() => {
                  updateSortInternal(sortFieldName);
                }}
              >
                Document Types {sortIndicator}
              </div>
            );
          },
          accessor: 'types',
          // eslint-disable-next-line react/display-name
          Cell: (cellProps) => {
            const types = cellProps.value;
            return (
              <>
                {types.map((type, pos) => {
                  const documentType = mappedDocumentTypes.get(type);
                  if (documentType) {
                    return (
                      <>
                        <span key={`typelabel_${pos}`}>
                          <strong>{documentType.description}</strong>
                        </span>
                        <br />
                      </>
                    );
                  }
                })}
              </>
            );
          },
        },
        {
          Header: () => {
            const sortFieldName = 'indexes';
            const sortIndicator = getSortIndicator(sortFieldName);
            return (
              <div
                style={updateSort ? { cursor: 'pointer' } : {}}
                onClick={() => {
                  updateSortInternal(sortFieldName);
                }}
              >
                Indexes {sortIndicator}
              </div>
            );
          },
          accessor: 'indexes',
          // eslint-disable-next-line react/display-name
          Cell: (cellProps) => {
            const indexValues = cellProps.value;
            const types = cellProps.row.original.types;
            const hiddenIndexes = findHiddenIndexesForDocTypes(metaState.documentTypes, types);
            return (
              <>
                {metaState.documentIndexes.map((documentIndex, pos) => {
                  if (hiddenIndexes.includes(documentIndex.id)) return null;
                  let value = indexValues[documentIndex.id];
                  if (!value) return;
                  if (documentIndex.type === 'date') {
                    const date = moment(value).utc();
                    value = date.format('MM/DD/YYYY');
                  }
                  if (documentIndex.type === 'picklist') {
                    const picklistItems = documentIndex.picklistItems;
                    picklistItems.forEach((item) => {
                      if (item.key === value) {
                        value = item.value;
                      }
                    });
                  }
                  return (
                    <div key={`indexlabel_${pos}`} style={{ marginBottom: '5px' }}>
                      <strong>{documentIndex.description}: </strong>
                      {value}
                    </div>
                  );
                })}
                {/* {Object.keys(indexes).map((indexKey, pos) => {
                  const documentIndex = mappedDocumentIndexes[indexKey];
                  let value = indexes[indexKey];
                  if (documentIndex.type === 'date') {
                    const date = new Date(value);
                    value = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
                  }
                  if (documentIndex.type === 'picklist') {
                    const picklistItems = documentIndex.picklistItems;
                    picklistItems.forEach((item) => {
                      if (item.key === value) {
                        value = item.value;
                      }
                    });
                  }
                  if (documentIndex && value) {
                    return (
                      <div key={`indexlabel_${pos}`} style={{ marginBottom: '5px' }}>
                        <strong>{documentIndex.description}: </strong>
                        {value}
                      </div>
                    );
                  }
                })} */}
              </>
            );
          },
        },
        {
          Header: () => {
            const sortFieldName = 'created';
            const sortIndicator = getSortIndicator(sortFieldName);
            return (
              <div
                style={updateSort ? { cursor: 'pointer' } : {}}
                onClick={() => {
                  updateSortInternal(sortFieldName);
                }}
              >
                Created {sortIndicator}
              </div>
            );
          },
          accessor: 'created',
          Cell: (cellProps) => {
            return formatDate(cellProps.value);
          },
        },
        {
          Header: () => {
            const sortFieldName = 'modified';
            const sortIndicator = getSortIndicator(sortFieldName);
            return (
              <div
                style={updateSort ? { cursor: 'pointer' } : {}}
                onClick={() => {
                  updateSortInternal(sortFieldName);
                }}
              >
                Modified {sortIndicator}
              </div>
            );
          },
          accessor: 'modified',
          Cell: (cellProps) => {
            return formatDate(cellProps.value);
          },
        },
        {
          Header: '',
          accessor: 'id',
          id: 'editcell',
          // eslint-disable-next-line react/display-name
          Cell: (cellProps) => {
            const actionBtns = (
              <>
                {!isCart && (
                  <AccessControl permissionId={'document'} action={'update'}>
                    <Link to={`/document/${cellProps.value}`}>
                      <AiFillEdit size={25} style={{ marginBottom: '10px' }}></AiFillEdit>
                    </Link>
                  </AccessControl>
                )}
                <br />
                {!cartItems.includes(cellProps.value) ? (
                  <BsFillCartPlusFill
                    size={22}
                    color="#007bff"
                    onClick={() => {
                      addToCart(cellProps.value);
                    }}
                    style={{ cursor: 'pointer', marginTop: '5px' }}
                  />
                ) : (
                  <BsFillCartXFill size={22} color="#007bff" onClick={() => removeFromCart(cellProps.value)} style={{ cursor: 'pointer', marginTop: '5px' }} />
                )}
              </>
            );

            const selector = (
              <>
                {isCart && (
                  <>
                    <CustomInput
                      name={`document_select_${cellProps.value}`}
                      type="checkbox"
                      id={`document_select_${cellProps.value}`}
                      checked={selectedDocuments && selectedDocuments.includes(cellProps.value)}
                      onChange={(e) => {
                        if (selectDocument) {
                          selectDocument(cellProps.value, e.target.checked);
                        }
                      }}
                    />
                  </>
                )}
              </>
            );
            return (
              <>
                {selector}
                {actionBtns}
              </>
            );
          },
        },
      ] as Column<Document>[],
    [cartItems, thumbnails, selectedDocuments],
  );
  const initPage = controlledPage ? controlledPage - 1 : 0;
  const data = documents;
  const {
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      manualPagination: !isCart,
      pageCount: totalPages,
      initialState: { pageSize, pageIndex: initPage },
    },
    usePagination,
  );

  useEffect(() => {
    if (!updatePage || !controlledPage || pageIndex + 1 === controlledPage) return;
    updatePage(pageIndex + 1);
  }, [pageIndex]);

  const table = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (table != undefined) {
    table.style.borderRadius = '.5em';
  }

  return (
    <>
      <Card>
        <Table striped hover size="sm" id="auditTable" responsive>
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} key={column.getHeaderProps().key}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.original.id}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} key={cell.getCellProps().key}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
      {pageCount > 1 && (
        <div className="pagination" style={{ justifyContent: 'end', marginTop: '.5em' }}>
          <div style={{ marginRight: '.5em' }}>
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </Button>{' '}
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </Button>{' '}
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </Button>{' '}
            <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </Button>{' '}
          </div>
          <div style={{ marginRight: '.5em' }}>
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentTable;
