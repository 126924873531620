import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { getAccounts } from '../api/accounts';
import { getUserProfile } from '../api/userprofile';
import { Loading } from '../Loading';
import { Account } from '../types/account';
import { UserProfile } from '../users/userprofile';
import { NoAccounts } from '../main/NoAccounts';
import { Role } from '../types/role';
import { getRoles } from '../api/roles';
import { parseDocumentTypePermissionsFromRoles } from '../utils/permissions';
import { PermissionStatus } from '../types/role';

interface UserProvider {
  profile: UserProfile;
  currentAccount: Account;
  userAccounts: Account[];
  setCurrentAccount: (account: Account) => void;
  setAccounts: () => void;
  documentTypePermissions: Record<string, PermissionStatus>;
}

export const UserContext = createContext<UserProvider | undefined>(undefined);

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [currentAccount, _setCurrentAccount] = useState<Account>();
  const [userAccounts, setUserAccounts] = useState<Account[]>([]);
  const [profile, setProfile] = useState<UserProfile>();
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [initializing, setInitializing] = useState<boolean>(false);
  const history = useHistory();

  const setCurrentAccount = (account: Account) => {
    localStorage.setItem('lastAccountId', account.accountId);
    if (initialLoad) {
      setInitialLoad(false);
    } else {
      history.push('/');
    }
    _setCurrentAccount(account);
  };

  const setAccounts = async () => {
    setInitializing(true);
    const userProfileFromAPI = await getUserProfile();

    let activeAccounts: Account[] = [];

    setProfile(userProfileFromAPI);
    if (userProfileFromAPI.user.isScribblesAdmin) {
      activeAccounts = await getAccounts();
    } else {
      activeAccounts = userProfileFromAPI.associatedAccounts.filter((account) => account.status === 'active');
    }

    if (activeAccounts.length > 0) {
      setUserAccounts(activeAccounts);
      let curAccountId = '';
      const storedAccountId = localStorage.getItem('lastAccountId');
      if (storedAccountId) {
        curAccountId = storedAccountId;
      }
      let curTent = activeAccounts.find((ten) => ten.accountId === curAccountId);
      if (!curTent) {
        curTent = activeAccounts[0];
        curAccountId = curTent.accountId;
      }
      if (curTent) setCurrentAccount(curTent);
    }
    setInitializing(false);
  };

  useEffect(() => {
    setAccounts();
  }, []);

  const documentTypePermissions: Record<string, PermissionStatus> | undefined = useMemo(() => {
    if (!profile || !currentAccount) return undefined;
    if (profile.user.isScribblesAdmin) {
      return {
        '*': 'ALLOW',
      };
    }

    return profile.documentTypePermissions[currentAccount.accountId];
  }, [currentAccount, profile]);

  if (initializing) {
    return <Loading />;
  }

  if (userAccounts.length === 0 && profile?.user.isScribblesAdmin) {
    return <NoAccounts />;
  }

  return currentAccount && profile && documentTypePermissions ? (
    <UserContext.Provider value={{ profile, currentAccount, setCurrentAccount, userAccounts, setAccounts, documentTypePermissions }}>
      {children}
    </UserContext.Provider>
  ) : (
    <Loading />
  );
};

export const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};
function setUser(userProfileFromAPI: UserProfile) {
  throw new Error('Function not implemented.');
}
