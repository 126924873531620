import { type } from 'os';
import React, { useEffect, useState } from 'react';
import { Column, HeaderGroup, useSortBy, useTable } from 'react-table';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CustomInput,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { parseScribOnlineFile } from '.';
import { getAuthToken, getBaseApiUrl } from '../api/helpers';
import { submitConversionBatchWithId } from '../api/scribonline';
import { useMetaProvider } from '../contexts/MetaContext';
import { useUserContext } from '../contexts/UserContext';
import styles from '../styles/archiveui.module.css';

import { ImportGroup } from '../types/scribonline';

const ScribOnlineImport = (): JSX.Element => {
  const [scribOnlineHostURL, setScribOnlineHostURL] = useState<string>('');
  const [batchName, setBatchName] = useState<string>('');
  const [batchNameError, setBatchNameError] = useState<string>();
  const [importGroups, setImportGroups] = useState<ImportGroup[]>([]);
  const [conversionModal, setConversionModal] = useState<boolean>(false);
  const { currentAccount } = useUserContext();
  useEffect(() => {
    fetch(`${getBaseApiUrl()}/v1/api/archive/${currentAccount.accountId}/scribonline/config`)
      .then((resp) => {
        return resp.json();
      })
      .then((config) => {
        if (Array.isArray(config)) {
          const found = config.find((elem) => {
            return elem.name === 'scribonline_hosturl';
          });
          if (found) {
            setScribOnlineHostURL(found.value);
          }
        }
      });
  }, [currentAccount]);

  const updateHost = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScribOnlineHostURL(e.target.value);
  };
  const updateBatchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.indexOf('_') < 0 && value.indexOf(' ') < 0) {
      setBatchName(e.target.value);
      setBatchNameError(undefined);
    } else {
      setBatchNameError('Invalid Character: _ or space');
    }
  };

  const testHostURL = () => {
    //call the iamgingexport url
    //if we get back som meta display goodness
    const checkPromise = fetch(scribOnlineHostURL + '/imagingexport')
      .then((resp) => resp.text())
      .then((txt) => {
        if (txt.indexOf('dc:documentImaging') === -1) {
          throw new Error('not a valid xml');
        }
      });
    toast.promise(checkPromise, {
      pending: `Checking url ${scribOnlineHostURL}`,
      success: 'URL Check Successful',
      error: 'Failed testing scribonline url',
    });
  };

  const saveHostURL = async () => {
    const postPromise = fetch(`${getBaseApiUrl()}/v1/api/archive/${currentAccount.accountId}/scribonline/config`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
      body: JSON.stringify([
        {
          name: 'scribonline_hosturl',
          value: scribOnlineHostURL,
        },
      ]),
    })
      .then((resp) => {
        return resp.json();
      })
      .then((config) => {
        if (config.configurations) {
          if (Array.isArray(config.configurations)) {
            const found = config.configurations.find((elem: Record<string, string>) => {
              return elem.name === 'scribonline_hosturl';
            });
            if (found) {
              setScribOnlineHostURL(found.value);
            }
          }
        }
      });
    toast.promise(postPromise, {
      pending: 'Saving Configuration.',
      success: 'Configuartion Saved',
      error: 'Failed saving configuration',
    });
  };

  const toggleConversionModal = () => {
    setConversionModal((oldVal) => {
      return !oldVal;
    });
  };

  const { metaState } = useMetaProvider();

  const clearGroups = () => {
    setImportGroups([]);
  };

  const destinationSelected = (conversionId: string, convertToType: string) => {
    setImportGroups((oldGroups) => {
      for (const group of oldGroups) {
        if (group.originalDocumentTypeId === conversionId) {
          group.newDocumentTypeId = convertToType;
          break;
        }
      }
      return [...oldGroups];
    });
  };

  const sendConversionDocs = () => {
    //split import docs... 5000 records per upload?
    const splitGroupArrays = [];
    let currentGroupArray: ImportGroup[] = [];
    let currentGroup: ImportGroup | null = null;
    let docCount = 0;
    for (const group of importGroups) {
      currentGroup = { ...group, documentIds: [] };
      currentGroupArray.push(currentGroup);
      for (const docid of group.documentIds) {
        currentGroup.documentIds.push(docid);
        docCount++;
        if (docCount == 1000) {
          //hit 1000... create new import group array
          splitGroupArrays.push(currentGroupArray);
          currentGroupArray = [];
          currentGroup = { ...group, documentIds: [] };
          currentGroupArray.push(currentGroup);
          docCount = 0;
        }
      }
    }
    splitGroupArrays.push(currentGroupArray);
    const batchId = `${new Date().getTime()}`;
    //change to for loop?
    const allUploads = [];
    console.log(`Batch Name: ${batchName}`);
    console.log(`Batch Id: ${batchId}`);
    for (let index = 0; index < splitGroupArrays.length; index++) {
      console.log(`about to submit ${index}`);
      console.log(`${currentAccount.accountId}, ${batchId}, ${batchName}_${index}`);
      allUploads.push(submitConversionBatchWithId(currentAccount.accountId, batchId, batchName, index, splitGroupArrays[index]));
    }
    toast.promise(Promise.all(allUploads), {
      pending: 'Submitting conversion documents',
      success: 'Conversion Submitted',
      error: 'Unable to submit conversion request',
    });
    toggleConversionModal();
    //on success clear screen? redirect to batches?
  };

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Original',
          accessor: 'originalDocumentTypeId',
          Cell: (cellProps) => {
            if (cellProps.value) {
              return <span>{cellProps.row.original.originalDocName ? `${cellProps.row.original.originalDocName} (${cellProps.value})` : cellProps.value}</span>;
            } else {
              return <span></span>;
            }
          },
        },
        {
          Header: 'Maps To',
          accessor: 'newDocumentTypeId',
          // eslint-disable-next-line react/display-name
          Cell: (cellProps) => {
            return (
              <span>
                <select
                  aria-label="Select Document ID"
                  value={cellProps.value}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    destinationSelected(cellProps.row.original.originalDocumentTypeId, event.target.value);
                  }}
                >
                  <option></option>
                  {metaState.documentTypes
                    .filter((type) => type.status == 'active')
                    .sort((a, b) => {
                      return ('' + a.name).localeCompare(b.name);
                    })
                    .map((type) => {
                      return (
                        <option data-typeid={cellProps.row.original.originalDocumentTypeId} key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      );
                    })}
                </select>
              </span>
            );
          },
        },
        {
          Header: 'Document Count',
          accessor: 'documentIds',
          Cell: (cellProps) => {
            if (cellProps.value) {
              return <span>{cellProps?.value.length}</span>;
            }
          },
        },
      ] as Column<ImportGroup>[],
    [metaState],
  );
  const data = React.useMemo(() => importGroups, [importGroups]);
  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );
  const renderSort = (column: HeaderGroup<ImportGroup>): JSX.Element => {
    if (column.isSorted) {
      return <span>{column.isSortedDesc ? ' 🔽' : ' 🔼'}</span>;
    }
    return <span></span>;
  };

  const fileChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      if (e.currentTarget.files.length > 0) {
        const f = e.currentTarget.files.item(0);
        if (f) {
          setSelectedFile(f);
        }
      }
    }
  };

  //read csv into doc class : superid[]
  //lookup map to doc class
  const parseImportFile = async () => {
    if (selectedFile) {
      const fileText = await selectedFile?.text();
      const parsedGroups = parseScribOnlineFile(fileText, metaState.documentTypes);
      setImportGroups(parsedGroups);
    }
  };

  const [selectedFile, setSelectedFile] = useState<File>();

  const table = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (table != undefined) {
    table.style.borderRadius = '.5em';
  }
  return (
    <div>
      <Card style={{ marginTop: '1em', borderRadius: '.5em' }}>
        <CardBody className={styles.searchBarHeader}>
          <h5 style={{ textAlign: 'start', fontWeight: 500, marginTop: '.5em' }}>Start ScribOnline Conversion</h5>
        </CardBody>
        <CardBody className={styles.searchBarBody}>
          <div className="row mb-2">
            <div className="col">
              <Label for="hostURL">ScribOnline Host:</Label>
              <InputGroup>
                <Input
                  id="hostURL"
                  value={scribOnlineHostURL}
                  placeholder="https://somesite.scribonline.com/imagingexport"
                  type="text"
                  onChange={updateHost}
                ></Input>
                <InputGroupAddon addonType="append">
                  <Button className={styles.scrbBtnBlue} onClick={testHostURL}>
                    Test Host
                  </Button>
                  <Button className={styles.scrbBtnOrange} onClick={saveHostURL}>
                    Save Host
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </div>
          <Form
            onSubmit={(event) => {
              event.preventDefault();
              toggleConversionModal();
            }}
          >
            <div className="row">
              <div className="col">
                <Label for="batchName">Batch Name:</Label>
                <Input value={batchName} type="text" name="batchName" id="batchName" onChange={updateBatchName} required={true} />
                {batchNameError && <Alert color="danger">{batchNameError}</Alert>}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <CustomInput id="fileInput" type="file" onChange={fileChange} accept=".csv" />
              </div>
            </div>
            <div className="row" style={{ marginTop: '1em' }}>
              <div className="col">
                <Button className={styles.scrbBtnOrange} disabled={selectedFile ? false : true} onClick={parseImportFile}>
                  Load From File
                </Button>
                <Button className={styles.scrbBtnMrgnL} onClick={clearGroups}>
                  Clear
                </Button>
              </div>
              <div className="col-md-auto">
                <Button className={styles.scrbBtnOrange}>Submit Conversion</Button>
              </div>
            </div>
          </Form>

          <br />
        </CardBody>
      </Card>
      <Card style={{ marginTop: '1em', borderRadius: '.5em' }}>
        <Table striped hover size="sm" id="auditTable" responsive>
          <thead className={styles.tableHeader}>
            {headerGroups.map((headerGroup) => {
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    if (column.Header === 'Original') {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.IdHeader}>
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                        </th>
                      );
                    } else {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.th}>
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                        </th>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (cell.column.Header === 'Original') {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <td style={{ paddingLeft: '1.25em' }} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    } else {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
      <Modal toggle={toggleConversionModal} isOpen={conversionModal}>
        <ModalHeader>Start Conversion</ModalHeader>
        <ModalBody>
          <div>Convert </div>
          {importGroups.map((group) => {
            return (
              <div key={group.originalDocumentTypeId}>
                Import {group.documentIds.length} {group.originalDocName} Documents to{' '}
                {
                  metaState.documentTypes.find((type) => {
                    return type.id === group.newDocumentTypeId;
                  })?.name
                }
              </div>
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Button className={styles.filterBtn} onClick={toggleConversionModal}>
            Cancel
          </Button>{' '}
          <Button type="submit" className={styles.scrbBtnOrange} onClick={sendConversionDocs}>
            Submit Doc(s)
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ScribOnlineImport;
