import React, { useState, useEffect, ChangeEvent, FormEvent, KeyboardEvent } from 'react';
import AccountContact from './accountContact';
import styles from '../../styles/archiveui.module.css';
import { Col, Row, Button, Form, FormGroup, FormFeedback, Label, Input, ListGroup, Alert, CardBody } from 'reactstrap';
import Toast from '../../toast';
import { withRouter, useParams } from 'react-router';
import { updateAccount } from '../../api/accounts';
import { getAccount } from '../../api/accounts';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Account, Contact } from '../../types/account';
import { LoadingComponent } from '../../Loading';
import { useUserContext } from '../../contexts/UserContext';
import { validatePhoneNumber } from '../../utils/phone';
import { useMetaProvider } from '../../contexts/MetaContext';
import { DocumentIndex } from '../../types/meta';
import { getMeta } from '../../api/meta';

interface DetailParams {
  accountId: string | undefined;
}

interface DetailProps {
  accountId?: string;
  initialAccount?: boolean;
}

export const AccountDetail = ({ initialAccount, accountId }: DetailProps) => {
  const history = useHistory();
  const [documentIndexes, setDocumentIndexes] = useState<DocumentIndex[]>([]);
  const [contactErrors, setContactErrors] = useState<Record<number, string | undefined>>({});
  let setAccounts: () => void;
  try {
    setAccounts = useUserContext().setAccounts;
  } catch (e) {
    console.log('User provider not available...');
  }
  const contacts: Contact[] = [];
  const [fullAccount, setAllValues] = useState<{
    status: string;
    shortName: string;
    name: string;
    description: string;
    contacts: Contact[];
    activateScanner: boolean;
    activateOrganizer: boolean;
    organizerPrimaryIndex?: string;
  }>({
    status: '',
    shortName: '',
    name: '',
    description: '',
    contacts: contacts,
    activateScanner: false,
    activateOrganizer: false,
    organizerPrimaryIndex: '',
  });
  const [error, setError] = useState<string | null>(null);

  const [partialAccount, setPartialAccount] = useState<Partial<Account>>({
    accountId: accountId ? accountId : '',
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getCurrentAccount = async () => {
    if (!accountId) return;
    const currentAccount = await getAccount(accountId);
    setAllValues(() => {
      return {
        ...fullAccount,
        name: currentAccount.name,
        shortName: currentAccount.shortName,
        description: currentAccount.description,
        status: currentAccount.status,
        contacts: currentAccount.contacts,
        activateScanner: currentAccount.activateScanner ?? false,
        activateOrganizer: currentAccount.activateOrganizer ?? false,
        organizerPrimaryIndex: currentAccount.organizerPrimaryIndex ?? undefined,
      };
    });
  };

  const setInitialState = async () => {
    if (accountId && accountId !== '' && fullAccount.name === '') {
      await getCurrentAccount();
      await fetchIndexes();
    } else if (fullAccount.contacts.length < 1) {
      addContactHandler();
    }
    setLoading(false);
  };

  const fetchIndexes = async () => {
    if (!accountId) return;
    const meta = await getMeta(accountId);
    setDocumentIndexes(meta.documentIndexes);
  };

  useEffect(() => {
    setInitialState();
  }, []);

  const addToPartialAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean = event.target.value;
    if (value === 'true' || value === 'false') {
      value = value === 'true';
    }
    setPartialAccount((prevValues) => {
      return { ...prevValues, [event.target.name]: value };
    });
    setAllValues((prevValues) => {
      return { ...prevValues, [event.target.name]: value };
    });
  };

  const _saveAccountHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newAccount = !accountId || accountId === '';
    if (!fullAccount.contacts || fullAccount.contacts.length <= 0) {
      setError('You must add at least one contact.');
      return;
    }

    let primarySet = false;
    let validPhones = true;
    fullAccount.contacts.forEach((contact, index) => {
      if (contact.isPrimary) primarySet = true;
      if (!validatePhone(contact.phone, index)) validPhones = false;
    });

    if (!primarySet) {
      setError('One contact must be designated as the primary contact.');
      return;
    }

    if (!validPhones) return;

    console.log('partial', partialAccount);
    const response = await toast.promise(updateAccount(newAccount, partialAccount), {
      pending: 'Updating Account',
      success: 'Account Updated',
      error: 'There was an error',
    });
    if (response === 200) {
      if (initialAccount) {
        window.location.reload();
      } else {
        history.push('/accountlist');
      }
    } else {
      setError('There was an error updating this account, please try again.');
    }
  };

  const saveAccountHandler = async (event: FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    try {
      await _saveAccountHandler(event);
    } catch (e) {
      toast.error('There was an error saving this account, please try again.');
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const contactUpdateHandler = (targetName: string, value: string, index: number) => {
    const contactsCopy = [...fullAccount.contacts];
    const nameOfInput = targetName;
    const objectToUpdate = contactsCopy[index];
    if (nameOfInput == 'name') {
      objectToUpdate.name = value;
    } else if (nameOfInput == 'email') {
      objectToUpdate.email = value;
    } else if (nameOfInput == 'phone') {
      objectToUpdate.phone = value;
    } else if (nameOfInput == 'role') {
      objectToUpdate.role = value;
    }
    contactsCopy[index] = objectToUpdate;
    setAllValues((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
    setPartialAccount((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
  };

  const updatePrimaryContact = (isPrimary: boolean, indexOfContact: number) => {
    const updatedValues = [...fullAccount.contacts].map((contact, index) => {
      if (index !== indexOfContact) {
        contact.isPrimary = false;
      } else {
        contact.isPrimary = isPrimary;
      }
      return contact;
    });

    setAllValues((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: updatedValues,
      };
    });
    setPartialAccount((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: updatedValues,
      };
    });
  };

  const validatePhone = (phoneNumber: string, index: number) => {
    console.log('going to validate', phoneNumber, index);
    if (validatePhoneNumber(phoneNumber)) {
      console.log('is valid');
      setContactErrors((prev) => {
        return {
          ...prev,
          [index]: undefined,
        };
      });
      return true;
    } else {
      console.log('not valid');
      setContactErrors((prev) => {
        return {
          ...prev,
          [index]: 'Invalid Phone Number',
        };
      });
      return false;
    }
  };

  const addContactHandler = () => {
    const contactsCopy = [...fullAccount.contacts];
    if (contactsCopy.length < 1) {
      contactsCopy.push({
        name: '',
        email: '',
        phone: '',
        role: '',
        isPrimary: true,
      });
    } else {
      contactsCopy.push({
        name: '',
        email: '',
        phone: '',
        role: '',
        isPrimary: false,
      });
    }
    setAllValues((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });

    setPartialAccount((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
  };

  const removeContactHandler = (indexOfContact: number) => {
    const contactsCopy = [...fullAccount.contacts];
    contactsCopy.splice(indexOfContact, 1);
    setAllValues((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
    setPartialAccount((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
  };

  let errorMessage = null;
  if (error) {
    errorMessage = (
      <Alert color="danger" style={{ marginTop: '3px' }}>
        {error}
      </Alert>
    );
  }

  const [touched, setTouched] = useState({
    status: false,
    shortName: false,
    name: false,
    description: false,
  });

  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  const validate = (status: string, shortName: string, name: string, description: string) => {
    const errors = {
      status: '',
      shortName: '',
      name: '',
      description: '',
    };

    if (touched.status && status.trim().length < 1) {
      errors.status = 'Status is a required field';
    }
    if (touched.shortName && shortName.trim().length < 1) {
      errors.shortName = 'Short Name is a required field';
    }
    if (touched.name && name.trim().length < 1) {
      errors.name = 'Name is a required field';
    }
    if (touched.description && description.trim().length < 1) {
      errors.description = 'Description is a required field';
    }

    return errors;
  };

  const formErrors = validate(fullAccount.status, fullAccount.shortName, fullAccount.name, fullAccount.description);

  return (
    <LoadingComponent isLoading={loading}>
      <div>
        {initialAccount && (
          <Alert style={{ marginTop: '15px' }} color="info">
            No accounts created yet, you must create one to continue...
          </Alert>
        )}
        <div className={styles.detailContainer}>
          <CardBody className={styles.searchBarHeader}>
            <h5 className={styles.searchBarTitle}>Client Detail</h5>
          </CardBody>
          <CardBody>
            <Form onSubmit={(e) => saveAccountHandler(e)}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      value={fullAccount.name}
                      type="text"
                      name="name"
                      id="name"
                      invalid={formErrors.name !== ''}
                      onBlur={() => handleBlur('name')}
                      onChange={addToPartialAccount}
                      required
                    />
                    <FormFeedback>{formErrors.name}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="shortName">Short Name</Label>
                    <Input
                      value={fullAccount.shortName}
                      type="text"
                      name="shortName"
                      id="shortName"
                      invalid={formErrors.shortName !== ''}
                      onBlur={() => handleBlur('shortName')}
                      onChange={addToPartialAccount}
                      required
                    />
                    <FormFeedback>{formErrors.shortName}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="status">Status</Label>
                    <Input
                      value={fullAccount.status}
                      type="select"
                      name="status"
                      id="status"
                      invalid={formErrors.status !== ''}
                      onBlur={() => handleBlur('status')}
                      onChange={addToPartialAccount}
                      required
                    >
                      <option></option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Input>
                    <FormFeedback>{formErrors.status}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="status">Scanning</Label>
                    <Input
                      value={`${fullAccount.activateScanner ?? false}`}
                      type="select"
                      name="activateScanner"
                      id="activateScanner"
                      onChange={addToPartialAccount}
                    >
                      <option value="false">Inactive</option>
                      <option value="true">Active</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="activateOrganizer">Organizer</Label>
                    <Input
                      value={fullAccount.activateOrganizer ? 'true' : 'false'}
                      type="select"
                      name="activateOrganizer"
                      id="activateOrganizer"
                      onChange={addToPartialAccount}
                      required
                    >
                      <option></option>
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="organizerPrimaryIndex">
                      Organizer Primary Index <sup>(this index value will be used to lookup the other values)</sup>
                    </Label>
                    <Input
                      value={fullAccount.organizerPrimaryIndex}
                      type="select"
                      name="organizerPrimaryIndex"
                      id="organizerPrimaryIndex"
                      onChange={addToPartialAccount}
                      required={fullAccount.activateOrganizer}
                      disabled={!accountId}
                    >
                      <option></option>
                      {documentIndexes.map((index) => {
                        if (index.type !== 'string' && index.type !== 'number') return null;
                        return (
                          <option key={`organizerIndexSelect_${index.id}`} value={index.id}>
                            {index.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="description">Description</Label>
                    <Input
                      value={fullAccount.description}
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder=""
                      invalid={formErrors.description !== ''}
                      onBlur={() => handleBlur('description')}
                      onChange={addToPartialAccount}
                    ></Input>
                    <FormFeedback>{formErrors.description}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <div className={styles.contactsHeader}>
                <h3>Contacts</h3>
                <div>
                  <Button className={styles.scrbBtnPrimary} size="sm" onClick={addContactHandler}>
                    Add Contact
                  </Button>
                </div>
              </div>
              <ListGroup>
                {fullAccount.contacts.map((contact, index) => {
                  return (
                    <AccountContact
                      name={contact.name}
                      email={contact.email}
                      phone={contact.phone}
                      role={contact.role}
                      isPrimary={contact.isPrimary}
                      key={index}
                      indexOfContact={index}
                      newAccount={accountId && accountId !== '' ? false : true}
                      updateFunction={contactUpdateHandler}
                      updatePrimaryContact={updatePrimaryContact}
                      removeContactHandler={removeContactHandler}
                      error={contactErrors[index]}
                    />
                  );
                })}
                {errorMessage}
              </ListGroup>
              <div className={styles.detailBtnContainer}>
                {!initialAccount && (
                  <Button
                    className={styles.scrbBtnBlueMargin}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button disabled={isSubmitting} className={styles.scrbBtnOrange}>
                  Save
                </Button>
              </div>
            </Form>
          </CardBody>
        </div>
      </div>
    </LoadingComponent>
  );
};

const AccountDetailRoute = () => {
  const { accountId } = useParams<DetailParams>();
  return <AccountDetail accountId={accountId} />;
};

export default withRouter(AccountDetailRoute);
