import React, { useState, useEffect, FormEvent, useMemo, useCallback, useRef } from 'react';
import { Col, CardBody, Row, Button, Form, FormGroup, Label, Input, Alert, CustomInput, ListGroup, ListGroupItem } from 'reactstrap';
import { useParams } from 'react-router';
import {
  getUser,
  setUsersForRole,
  removeUserForRole,
  updateAccountMembershipStatus,
  updateUserAdmin,
  updateUserRoles,
  resendTemporaryPassword,
} from '../../api/users';
import { getRoles } from '../../api/roles';
import styles from '../../styles/archiveui.module.css';
import { AccountMembership, Status } from '../../types/user';
import { useHistory } from 'react-router-dom';
import Toast from '../../toast';
import 'react-phone-input-2/lib/bootstrap.css';
import { toast } from 'react-toastify';
import AccessControl from '../userprofile/accesscontrol';
import { PermissionStatus, Role } from '../../types/role';
import { Loading, LoadingComponent } from '../../Loading';
import { useUserContext } from '../../contexts/UserContext';
import { useMetaProvider } from '../../contexts/MetaContext';
import TypePermissionDisplay from './TypePermissionDisplay';
import { parseDocumentTypePermissionsFromRoles } from '../../utils/permissions';

interface UserDetail {
  id: string;
  accountMembership: AccountMembership[];
  cognitoIds: string[];
  familyName: string;
  givenName: string;
  email: string;
  phone: string;
  status: Status;
  thirdPartyAuth: boolean;
}
interface UserDetailParams {
  id?: string;
  adminList?: string;
}

const UserDetail = (): JSX.Element => {
  const history = useHistory();
  const documentTypes = useMetaProvider().metaState.documentTypes;
  const { currentAccount } = useUserContext();
  const { id, ...params } = useParams<UserDetailParams>();
  const adminList = params.adminList === 'true';
  const [loading, setLoading] = useState<boolean>(true);
  const accountMembership: AccountMembership[] = [{ accountId: currentAccount.accountId, roles: [], status: 'ACTIVE' }];
  const cognitoIds: Array<string> = [];
  const userStatus: Status = 'ACTIVE';
  const [roles, updateRoles] = useState<Role[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [userDetail, setUserDetail] = useState<UserDetail>({
    id: '',
    accountMembership: accountMembership,
    cognitoIds: cognitoIds,
    familyName: '',
    givenName: '',
    email: '',
    phone: '',
    status: userStatus,
    thirdPartyAuth: false,
  });
  const [partialUser, setPartialUser] = useState({
    id: id ? id : '',
  });
  const [error, setError] = useState<string | null>(null);
  const initialStatusRef = useRef<Status | undefined>();

  const setInitialState = async () => {
    if (id && id !== '') {
      const currentUser = await (adminList ? getUser(id) : getUser(id, currentAccount.accountId));
      setUserDetail(() => {
        return {
          ...userDetail,
          id: currentUser.id,
          accountMembership: currentUser.accountMembership,
          cognitoIds: currentUser.cognitoIds,
          familyName: currentUser.familyName,
          givenName: currentUser.givenName,
          email: currentUser.email,
          phone: currentUser.phone,
          status: currentUser.status,
          thirdPartyAuth: currentUser.thirdPartyAuth,
        };
      });
      initialStatusRef.current = currentUser.accountMembership.find((tmem) => tmem.accountId === currentAccount.accountId)?.status;
      const roles = await getRoles(currentAccount.accountId);
      updateRoles(roles);
      setLoading(false);
    }
  };

  useEffect(() => {
    setInitialState();
    const setRoles = async () => {
      const roles = await getRoles(currentAccount.accountId);
      updateRoles(roles);
    };
    setRoles();
  }, [currentAccount]);

  const currentAccountMembership = useMemo(() => {
    return userDetail.accountMembership.find((e) => e.accountId === currentAccount.accountId);
  }, [userDetail, currentAccount.accountId]);

  const addToPartialUser = (key: string, value: any) => {
    setUserDetail((prevValues) => {
      return { ...prevValues, [key]: value };
    });
    setPartialUser((prevValues) => {
      return { ...prevValues, [key]: value };
    });
  };

  const updateAccountStatus = useCallback(
    (newStatus: string) => {
      if (adminList) {
        setUserDetail((userDetail) => {
          return { ...userDetail, status: newStatus as Status };
        });
        return;
      }

      const newMemberships = [...userDetail.accountMembership];
      const currentMembership = newMemberships.find((e) => e.accountId === currentAccount.accountId);
      if (!currentMembership) {
        return;
      }
      currentMembership.status = newStatus as Status;
      setUserDetail((userDetail) => {
        return { ...userDetail, accountMembership: newMemberships };
      });
    },
    [userDetail, adminList],
  );

  const addRoleIdHandler = (val: string) => {
    const accountMembershipCopy = userDetail.accountMembership;
    const membershipToEdit = accountMembershipCopy.filter((e) => e.accountId === currentAccount.accountId);
    membershipToEdit[0].roles.push(val);
    setUserDetail((prevValues) => {
      return { ...prevValues, ['accountMembership']: accountMembershipCopy };
    });
    setPartialUser((prevValues) => {
      return { ...prevValues, ['accountMembership']: accountMembershipCopy };
    });
  };

  const removeRoleIdHandler = (val: string) => {
    const accountMembershipCopy = userDetail.accountMembership;
    const membershipToEdit = accountMembershipCopy.filter((e) => e.accountId === currentAccount.accountId);
    for (let i = 0; i < membershipToEdit[0].roles.length; i++) {
      if (membershipToEdit[0].roles[i] === val) {
        membershipToEdit[0].roles.splice(i, 1);
      }
    }
    setUserDetail((prevValues) => {
      return { ...prevValues, ['accountMembership']: accountMembershipCopy };
    });
    setPartialUser((prevValues) => {
      return { ...prevValues, ['accountMembership']: accountMembershipCopy };
    });
  };

  const addUserRole = async (roleId: string) => {
    const response = await setUsersForRole(currentAccount.accountId, roleId, [userDetail.id]);
    if (response === 204 || response === 200) {
      // do nothing
    } else {
      setError('There was an error updating this user, please try again.');
    }
  };

  const removeUserRole = async (roleId: string) => {
    const response = await removeUserForRole(currentAccount.accountId, roleId, userDetail.id);
    if (response === 204 || response === 200) {
      // do nothing
    } else {
      setError('There was an error updating this user, please try again.');
    }
  };

  const sendNewPassword = async () => {
    if (id) {
      await toast.promise(resendTemporaryPassword(currentAccount.accountId, id), {
        pending: 'Sending Password',
        success: 'Temporary Password Sent',
        error: 'Unable to Send Temporary Password',
      });
    }
  };

  const _saveUserHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (adminList) {
      await toast.promise(updateUserAdmin(userDetail), {
        pending: 'Updating user',
        success: 'User updated',
        error: 'Unable to update user',
      });
      history.push('/adminusers');
      return;
    }
    if (initialStatusRef.current && currentAccountMembership && initialStatusRef.current !== currentAccountMembership.status) {
      await toast.promise(updateAccountMembershipStatus(currentAccount.accountId, userDetail.id, currentAccountMembership.status), {
        pending: 'Updating user',
        success: 'User updated',
        error: 'Unable to update user',
      });
    }
    const selectedRoles = userDetail.accountMembership.filter((e) => e.accountId === currentAccount.accountId)[0].roles;
    if (!selectedRoles || selectedRoles.length === 0) {
      setError('You must select at least one role.');
      return;
    }
    await updateUserRoles(currentAccount.accountId, userDetail.id, selectedRoles);
    history.push('/users');
  };

  const saveUserHandler = async (event: FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    try {
      await _saveUserHandler(event);
    } catch (e) {
      toast.error('Unable to save this user, please try again.');
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };
  let errorMessage = null;
  if (error) {
    errorMessage = (
      <Alert color="danger" style={{ marginTop: '3px' }}>
        {error}
      </Alert>
    );
  }

  const membershipRoleIds = userDetail.accountMembership.find((membership) => {
    return membership.accountId === currentAccount.accountId;
  })?.roles;

  const documentTypePermissions: Record<string, PermissionStatus> = useMemo(() => {
    return parseDocumentTypePermissionsFromRoles(roles, membershipRoleIds);
  }, [roles, documentTypes, userDetail]);

  console.log('documentTypePermissions', documentTypePermissions);
  return (
    <LoadingComponent isLoading={loading}>
      <div className={styles.detailContainer}>
        <CardBody className={styles.searchBarHeader}>
          <h5 className={styles.searchBarTitle}>User Detail</h5>
        </CardBody>
        <CardBody>
          <Form
            onSubmit={async (event) => {
              await saveUserHandler(event);
            }}
          >
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="name">First Name</Label>
                  <Input value={userDetail.givenName} type="text" name="givenName" id="givenName" aria-label="Given Name" readOnly />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="name">Last Name</Label>
                  <Input value={userDetail.familyName} type="text" name="name" id="name" readOnly />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input value={userDetail.email} type="text" name="email" id="email" readOnly />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="phone">Phone</Label>
                  <Input value={userDetail.phone} type="text" name="phone" id="phone" readOnly />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="status">Status</Label>
                  <Input
                    value={adminList ? userDetail.status : currentAccountMembership?.status}
                    type="select"
                    name="status"
                    id="status"
                    onChange={(e) => updateAccountStatus(e.target.value)}
                    required
                  >
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Col md={6} style={{ display: userDetail.id == '' ? 'flex' : 'none' }}>
              <FormGroup>
                <Row>
                  <ListGroupItem style={{ border: '1px solid #fff' }}>
                    <CustomInput
                      checked={userDetail.thirdPartyAuth}
                      onChange={(e) => {
                        if (userDetail.thirdPartyAuth) {
                          addToPartialUser('thirdPartyAuth', false);
                        } else {
                          addToPartialUser('thirdPartyAuth', true);
                        }
                      }}
                      type="checkbox"
                      label="Logging in with 3rd Party/Cognito"
                      id="thirdPartyLogin"
                    />
                  </ListGroupItem>
                </Row>
              </FormGroup>
            </Col>
            {!adminList && (
              <Row>
                <Col>
                  <FormGroup>
                    <div>
                      <Row>
                        <Col md="9">
                          <h3>Roles</h3>
                        </Col>
                        <Col md="3">
                          <h3>Type Permissions</h3>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col md="9">
                        <ListGroup style={{ maxHeight: '25em', overflow: 'auto', border: '1px solid #ced4da' }}>
                          {roles?.map((role, index) => {
                            return (
                              <ListGroupItem key={index} className={styles.roleListItemContainer}>
                                <CustomInput
                                  onChange={(e) => {
                                    if (e.currentTarget.checked) {
                                      addRoleIdHandler(e.target.value);
                                    } else {
                                      removeRoleIdHandler(e.target.value);
                                    }
                                  }}
                                  type="checkbox"
                                  value={role.id}
                                  key={index}
                                  label={`${role.name}`}
                                  id={index}
                                  checked={membershipRoleIds?.includes(role.id) ? true : false}
                                />
                              </ListGroupItem>
                            );
                          })}
                        </ListGroup>
                      </Col>
                      <Col md="3">
                        <TypePermissionDisplay permissions={documentTypePermissions} />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            )}
            {errorMessage}
            <div className={styles.detailBtnContainer}>
              {userDetail && userDetail.status === 'PENDING_INVITE' && (
                <Button className={styles.scrbBtnGreenMargin} onClick={sendNewPassword}>
                  Send New Temporary Password
                </Button>
              )}

              <Button
                className={styles.scrbBtnBlueMargin}
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <AccessControl permissionId={'users'} action={id ? 'update' : 'create'}>
                <Button className={styles.scrbBtnOrange} disabled={isSubmitting}>
                  Save
                </Button>
              </AccessControl>
            </div>
          </Form>
        </CardBody>
      </div>
    </LoadingComponent>
  );
};

export default UserDetail;
