import React, { useContext, useEffect, useMemo, useState } from 'react';
import RoleTable from '../rolelisttable/roletable';
import { getRoles } from '../../api/roles';
import { Loading, LoadingComponent } from '../../Loading';
import { UserContext, useUserContext } from '../../contexts/UserContext';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Role } from '../../types/role';

const RolesList = () => {
  const {
    profile: { permissions },
    currentAccount,
  } = useUserContext();
  const history = useHistory();
  const [roles, updateRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchText, updateSearchText] = useState<string>('');

  useEffect(() => {
    const setRoles = async () => {
      let roles: Role[] = [];
      try {
        roles = await getRoles(currentAccount.accountId);
      } catch (e) {
        console.log('Unable to fetch roles', e);
      }
      updateRoles(roles);
      setLoading(false);
    };
    setRoles();
  }, [currentAccount]);

  const filteredRoles = useMemo(() => {
    const search = searchText.trim().toLowerCase();
    return roles.filter((role) => {
      return !searchText || role.description.toLowerCase().includes(search) || role.name.toLowerCase().includes(search);
    });
  }, [searchText, roles]);

  return (
    <LoadingComponent isLoading={loading}>
      <div>
        <RoleTable roles={filteredRoles} searchText={searchText} updateSearchText={updateSearchText} />
      </div>
    </LoadingComponent>
  );
};

export default RolesList;
