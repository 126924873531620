import * as React from 'react';
import { Spinner } from 'reactstrap';

export const Loading = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 56px)', flexGrow: 1 }}>
      <Spinner style={{ height: '10em', width: '10em', color: '#1C76A7' }} />
    </div>
  );
};

interface LoadingComponentProps {
  children: React.ReactNode;
  isLoading: boolean;
  styling?: React.CSSProperties;
}
export const LoadingComponent = ({ children, isLoading, styling }: LoadingComponentProps) => {
  let style: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexGrow: 1,
    marginTop: '5%',
  };
  if (styling) {
    style = {
      ...style,
      ...styling,
    };
  }
  return isLoading ? (
    <div style={style}>
      <Spinner style={{ height: '5em', width: '5em', color: '#1C76A7' }} />
    </div>
  ) : (
    <>{children}</>
  );
};
