import PSPDFKit from './pspdfkit';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../Loading';
import { getAnnotations, getDocumentContent, saveAnnotations, saveDocumentContent } from '../api/document';
import Toast, { toastError } from '../toast';
import { UserContext, useUserContext } from '../contexts/UserContext';
import { userAccessPermitted } from '../users/userprofile/user';
import { toast } from 'react-toastify';

interface DocumentViewerParams {
  documentId: string;
}

const DocumentViewer = () => {
  const [pdfContent, setPdfContent] = useState<ArrayBuffer>();
  const [loading, setLoading] = useState<boolean>(true);
  const [annotations, setAnnotations] = useState<any>();
  const [hideToolbar, setHideToolbar] = useState(true);
  const { documentId } = useParams<DocumentViewerParams>();
  const {
    profile: { permissions, user },
    currentAccount,
  } = useUserContext();

  const base64ToArrayBuffer = (base64: string) => {
    const binary = window.atob(base64);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const setToolbarVisibilty = () => {
    if (user.isScribblesAdmin) {
      return false;
    }

    const accountPermissions = permissions[currentAccount.accountId];
    if (accountPermissions) {
      const documentPermisssions = accountPermissions['1'];
      if (documentPermisssions) {
        return !(documentPermisssions.actions.create === 'ALLOW' || documentPermisssions.actions.update === 'ALLOW');
      }
    }
    return false;
  };

  const load = async () => {
    const content = await toastError(getDocumentContent(currentAccount.accountId, documentId), 'Error loading pdf document');
    if (!content) {
      return;
    }
    setPdfContent(content);

    try {
      const tmpAnnotations = await getAnnotations(currentAccount.accountId, documentId);
      if (tmpAnnotations && tmpAnnotations.instantJson && tmpAnnotations.instantJson.annotationJSON) {
        if (tmpAnnotations.instantJson.annotationJSON.pdfId) {
          tmpAnnotations.instantJson.annotationJSON.pdfId = null;
        }
        setAnnotations(tmpAnnotations.instantJson.annotationJSON);
      }
    } catch (e) {
      console.log('error loading annotations', e);
    }

    setHideToolbar(setToolbarVisibilty());
    setLoading(false);
  };

  const internalSaveAnnotations = async (instantJson: any) => {
    await saveAnnotations(currentAccount.accountId, documentId, instantJson);
  };

  const saveDocumentEdit = async (newPdf: ArrayBuffer, isFlat?: boolean) => {
    await saveDocumentContent(currentAccount.accountId, `${documentId}${isFlat ? '_flat' : ''}`, newPdf);
  };

  const onSaveBegin = () => {
    window.addEventListener('beforeunload', beforeunload);
  };

  const onSaveEnd = () => {
    window.removeEventListener('beforeunload', beforeunload);
  };

  useEffect(() => {
    load();
  }, [currentAccount]);

  const licenseKey = process.env.NODE_ENV === 'development' ? undefined : process.env.REACT_APP_PSPDFKIT_LICENSE;

  const { showInsertPages, showDeletePages, editAnnotations } = useMemo(() => {
    const showInsertPages = userAccessPermitted(currentAccount.accountId, user, permissions, [{ permissionId: 'pages', action: 'create' }]);
    const showDeletePages = userAccessPermitted(currentAccount.accountId, user, permissions, [{ permissionId: 'pages', action: 'delete' }]);
    const editAnnotations = userAccessPermitted(currentAccount.accountId, user, permissions, [{ permissionId: 'annotations', action: 'update' }]);
    return {
      showInsertPages,
      showDeletePages,
      editAnnotations,
    };
  }, [currentAccount, permissions, user]);

  const beforeunload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };
  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <PSPDFKit
            pdfUrl={pdfContent}
            baseUrl={`${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`}
            saveAnnotations={internalSaveAnnotations}
            instantJSON={annotations}
            userId={user.email}
            saveDocumentEdit={saveDocumentEdit}
            licenseKey={licenseKey}
            hideToolbar={hideToolbar}
            showInsertPages={showInsertPages}
            showDeletePages={showDeletePages}
            editAnnotations={editAnnotations}
            onSaveBegin={onSaveBegin}
            onSaveEnd={onSaveEnd}
          />
        </>
      )}
    </>
  );
};

export default DocumentViewer;
