import { toast } from 'react-toastify';
import { DocumentIndex, DocumentType, IndexType, PicklistItem } from '../../types/meta';
import { getMeta, saveMeta } from '../../api/meta';

export interface ImportedDocumentType {
  id: string;
  name: string;
  description: string;
  relatedDocs: number;
  selectedForImport?: boolean;
  alreadyExists?: boolean;
}

export interface ImportedPickList {
  id: string;
  name: string;
  description: string;
  items: ImportedPickListItem[];
}

export interface ImportedPickListItem {
  name: string;
  value: string;
  parent: string;
  order: number;
}

export interface ImportedDocumentIndex {
  id: string;
  name: string;
  parent: string;
  parentName: string;
  type: string;
  order: number;
  size: number;
  dbname: string;
  picklistItems: PicklistItem[];
  selectedForImport?: boolean;
  alreadyExists?: boolean;
}

export interface IndexGroup {
  dbname: string;
  indexes: ImportedDocumentIndex[];
  selectedForImport?: boolean;
  alreadyExists?: boolean;
}

export const getDocumentTypesFromXML = (document: Document): ImportedDocumentType[] => {
  const types: ImportedDocumentType[] = [];
  const results = document.querySelectorAll('documentClass');
  for (let count = 0; count < results.length; count++) {
    const parsed = convertXMLDocumentClassToType(results.item(count));
    if (parsed) {
      types.push(parsed);
    }
  }
  return types;
};

export const convertXMLDocumentClassToType = (documentClass: Node): ImportedDocumentType => {
  let name = '';
  let description = '';
  let id = '';
  let relatedDocs = 0;

  for (let i = 0; i < documentClass.childNodes.length; i++) {
    const child = documentClass.childNodes.item(i);
    switch (child.nodeName) {
      case 'name':
        name = child.textContent ? child.textContent : '';
        break;
      case 'description':
        description = child.textContent ? child.textContent : '';
        break;
      case 'id':
        id = child.textContent ? child.textContent : '';
        break;
      case 'docCount':
        relatedDocs = child.textContent ? Number(child.textContent) : 0;
        break;
      default:
        break;
    }
  }
  return {
    name,
    description,
    id,
    relatedDocs,
  };
};

export const getDocumentIndexesFromXML = (document: Document): ImportedDocumentIndex[] => {
  //get the picklists first
  const picklists: ImportedPickList[] = [];
  const presults = document.querySelectorAll('picklist');
  for (let count = 0; count < presults.length; count++) {
    const parsed = convertXMLPickList(presults.item(count));
    if (parsed) {
      picklists.push(parsed);
    }
  }
  const indexes: ImportedDocumentIndex[] = [];
  const results = document.querySelectorAll('documentIndex');
  for (let count = 0; count < results.length; count++) {
    const parsed = convertXMLDocumentIndex(results.item(count), picklists);
    if (parsed) {
      indexes.push(parsed);
    }
  }
  //need to see if this is a picklist... if it is we need to populate picklist values
  //look for parentnames in xml
  const types = getDocumentTypesFromXML(document);
  for (const index of indexes) {
    for (const type of types) {
      if (index.parent === type.id) {
        index.parentName = type.name;
        break;
      }
    }
  }
  return indexes;
};
export const convertXMLPickListItem = (picklist: Node): ImportedPickListItem => {
  let name = '';
  let value = '';
  let parent = '';
  let order = 32000;

  for (let i = 0; i < picklist.childNodes.length; i++) {
    const child = picklist.childNodes.item(i);
    switch (child.nodeName) {
      case 'name':
        name = child.textContent ? child.textContent : '';
        break;
      case 'value':
        value = child.textContent ? child.textContent : '';
        break;
      case 'parentid':
        parent = child.textContent ? child.textContent : '';
        break;
      case 'order':
        order = child.textContent ? Number(child.textContent) : 32000;
        break;
      default:
        break;
    }
  }
  return {
    name,
    value,
    parent,
    order,
  };
};
export const convertXMLPickListItems = (picklist: Node): ImportedPickListItem[] => {
  const items: ImportedPickListItem[] = [];
  for (let i = 0; i < picklist.childNodes.length; i++) {
    const child = picklist.childNodes.item(i);
    switch (child.nodeName) {
      case 'entry':
        items.push(convertXMLPickListItem(child));
        break;
      default:
        break;
    }
  }
  return items;
};

export const convertXMLPickList = (picklist: Node): ImportedPickList => {
  let id = '';
  let name = '';
  let description = '';
  let items: ImportedPickListItem[] = [];

  for (let i = 0; i < picklist.childNodes.length; i++) {
    const child = picklist.childNodes.item(i);
    switch (child.nodeName) {
      case 'id':
        id = child.textContent ? child.textContent : '';
        break;
      case 'name':
        name = child.textContent ? child.textContent : '';
        break;
      case 'description':
        description = child.textContent ? child.textContent : '';
        break;
      case 'values':
        items = convertXMLPickListItems(child);
        break;
      default:
        break;
    }
  }
  return {
    id,
    name,
    description,
    items,
  };
};
export const getPickListsFromXML = (document: Document): ImportedPickList[] => {
  const picklists: ImportedPickList[] = [];
  const results = document.querySelectorAll('picklist');
  for (let count = 0; count < results.length; count++) {
    const parsed = convertXMLPickList(results.item(count));
    if (parsed) {
      picklists.push(parsed);
    }
  }
  return picklists;
};

export const convertXMLDocumentIndex = (documentIndex: Node, picklists: ImportedPickList[]): ImportedDocumentIndex => {
  let id = '';
  let name = '';
  let parent = '';
  let type = '';
  let order = 32000;
  let size = -1;
  let dbname = '';
  let picklistid = '';

  for (let i = 0; i < documentIndex.childNodes.length; i++) {
    const child = documentIndex.childNodes.item(i);
    switch (child.nodeName) {
      case 'id':
        id = child.textContent ? child.textContent : '';
        break;
      case 'name':
        name = child.textContent ? child.textContent : '';
        break;
      case 'ownerid':
        parent = child.textContent ? child.textContent : '';
        break;
      case 'type':
        type = child.textContent ? child.textContent : '';
        break;
      case 'order':
        order = child.textContent ? Number(child.textContent) : 0;
        break;
      case 'size':
        size = child.textContent ? Number(child.textContent) : 0;
        break;
      case 'dbname':
        dbname = child.textContent ? child.textContent : '';
        break;
      case 'picklistid':
        picklistid = child.textContent ? child.textContent : '';
        break;
      default:
        break;
    }
  }
  const picklistItems: PicklistItem[] = [];
  if (type === 'picklist') {
    // need to lookup the correct picklist and create picklist items
    const picklist = picklists.find((p) => p.id === picklistid);
    if (picklist) {
      for (const item of picklist.items) {
        picklistItems.push({
          key: item.value,
          order: item.order,
          value: item.name,
        });
      }
    }
  }
  return {
    id,
    name,
    parent,
    type,
    order,
    size,
    dbname,
    parentName: '',
    picklistItems,
  };
};

export const convertImportTypeToDocumentType = (accountId: string, importType: ImportedDocumentType): DocumentType => {
  return {
    accountId,
    id: importType.id,
    name: importType.name,
    description: importType.description,
    status: 'active',
    created: new Date(),
    modified: new Date(),
    shortCode: '',
    requiredIndexes: [],
    order: 0,
  };
};
export const convertImportTypesToDocumentTypes = (accountId: string, importTypes: ImportedDocumentType[]): DocumentType[] => {
  return importTypes.map((importType) => convertImportTypeToDocumentType(accountId, importType));
};

export const saveImportTypesToMeta = async (accountId: string, importTypes: ImportedDocumentType[]): Promise<void> => {
  const docTypes = convertImportTypesToDocumentTypes(accountId, importTypes);
  const meta = await getMeta(accountId);
  //add types to meta
  const filteredTypes = docTypes.filter((type) => {
    for (const existingType of meta.documentTypes) {
      if (type.id === existingType.id) {
        return false;
      }
    }
    return true;
  });
  const allTypes: DocumentType[] = [...meta.documentTypes, ...filteredTypes];
  meta.documentTypes = allTypes;
  //make sure they dont already exist... of they do.. ignore
  await toast.promise(saveMeta(accountId, meta), {
    pending: 'Updating Meta',
    success: 'Meta Updated',
    error: 'There was an error',
  });
};

export const convertImportIndexToDocumentIndex = (accountId: string, importIndex: ImportedDocumentIndex): DocumentIndex => {
  return {
    accountId,
    id: importIndex.id,
    name: importIndex.name,
    created: new Date(),
    modified: new Date(),
    dbname: importIndex.dbname,
    description: importIndex.name,
    meta: [],
    picklistItems: importIndex.picklistItems,
    type: importIndex.type as IndexType,
    indexRequired: false,
    order: 0,
  };
};
export const convertImportIndexesToDocumentIndexes = (accountId: string, importIndexes: ImportedDocumentIndex[]): DocumentIndex[] => {
  return importIndexes.map((importIndex) => convertImportIndexToDocumentIndex(accountId, importIndex));
};

export const saveImportGroupsToMeta = async (accountId: string, importGroups: IndexGroup[]): Promise<void> => {
  const indexesToCreate: DocumentIndex[] = importGroups.map((indexGroup) => {
    return {
      ...convertImportIndexToDocumentIndex(accountId, indexGroup.indexes[0]),
      meta: [
        {
          id: 'conversionMapping',
          value: JSON.stringify(
            indexGroup.indexes.map((idx) => {
              return idx.id;
            }),
          ),
        },
      ],
    };
  });
  const meta = await getMeta(accountId);
  //add types to meta
  const filteredIndexes = indexesToCreate.filter((index) => {
    for (const existingIndex of meta.documentIndexes) {
      if (index.id === existingIndex.id) {
        return false;
      }
    }
    return true;
  });
  const allIndexes: DocumentIndex[] = [...meta.documentIndexes, ...filteredIndexes];
  meta.documentIndexes = allIndexes;
  //make sure they dont already exist... of they do.. ignore
  await toast.promise(saveMeta(accountId, meta), {
    pending: 'Updating Meta',
    success: 'Meta Updated',
    error: 'There was an error',
  });
};
