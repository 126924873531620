import React from 'react';
import { Column, useTable } from 'react-table';
import { Input, Table } from 'reactstrap';
import { DocumentIndex } from '../../types/meta';
import styles from '../../styles/archiveui.module.css';

interface IndexTableProps {
  documentIndexes: DocumentIndex[];
  onRequiredToggle: (indexId: string) => void;
  onHideToggle: (indexId: string) => void;
  requiredIndexes: string[];
  hideIndexes: string[];
}

export const TypeModalIndexTable = ({ documentIndexes, onHideToggle, onRequiredToggle, requiredIndexes, hideIndexes }: IndexTableProps) => {
  const getIndexColumns = () =>
    React.useMemo(() => {
      const cols = [
        {
          Header: 'Index',
          accessor: 'name',
        },
        {
          Header: 'Show',
          accessor: 'id',
          Cell: (cellProps) => {
            return (
              <Input
                style={{ display: 'block', margin: '0 auto' }}
                type="checkbox"
                checked={!hideIndexes.includes(cellProps.row.original.id)}
                onChange={() => {
                  onHideToggle(cellProps.row.original.id);
                }}
              />
            );
          },
        },
        {
          Header: 'Required',
          accessor: 'description',
          Cell: (cellProps) => {
            return (
              <Input
                style={{ display: 'block', margin: '0 auto' }}
                type="checkbox"
                checked={requiredIndexes.includes(cellProps.row.original.id)}
                onChange={() => {
                  onRequiredToggle(cellProps.row.original.id);
                }}
              />
            );
          },
        },
      ] as Column<DocumentIndex>[];

      return cols;
    }, [documentIndexes, requiredIndexes, hideIndexes]);

  const indexColumns: Column<DocumentIndex>[] = getIndexColumns();

  if (!indexColumns) return null;
  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);

  const table = useTable({
    columns: indexColumns,
    data: documentIndexes,
    getRowId,
  });
  return (
    <>
      <Table striped hover size="sm" id="auditTable" responsive {...table.getTableProps()}>
        <thead className={styles.tableHeader}>
          {table.headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <td>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
