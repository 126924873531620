import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LoadingComponent } from '../../Loading';
import { getDocumentThumbnail } from '../../api/document';
import DocumentTable from './documentTable';
import { Button, ButtonGroup, Card, CardBody, Col, Row } from 'reactstrap';
import Pagination from '../../pagination';
import styles from '../../styles/archiveui.module.css';
import { useUserContext } from '../../contexts/UserContext';
import { Document } from '../../types/document';
import { useCartContext } from '../../contexts/CartContext';
import { SortField } from '../documentsearch';

interface DocumentListProps {
  documents: Document[];
  totalCount: number;
  updatePage: (newPage: number) => void;
  startNewSearch: () => void;
  pageSize: number;
  loadingDocuments: boolean;
  currentPage?: number;
  addResultsToCart: () => void;
  showInactiveTypes: boolean;
  currentSort?: SortField;
  updateSort: (newSort: SortField) => void;
}
const DocumentList = ({
  documents,
  totalCount,
  updatePage,
  startNewSearch,
  pageSize,
  loadingDocuments,
  currentPage,
  addResultsToCart,
  showInactiveTypes,
  currentSort,
  updateSort,
}: DocumentListProps) => {
  const { currentAccount } = useUserContext();
  const tableRef = useRef<HTMLDivElement>(null);
  const [thumbnails, setThumbnails] = useState<Record<string, string>>({});

  const fetchThumbs = async (newDocuments: Document[]) => {
    for (const document of newDocuments) {
      if (Object.keys(thumbnails).includes(document.id)) continue;

      getDocumentThumbnail(currentAccount.accountId, document.id).then((thumb) => {
        setThumbnails((thumbnails) => {
          return {
            ...thumbnails,
            [document.id]: thumb,
          };
        });
      });
    }
  };

  useEffect(() => {
    if (documents) {
      fetchThumbs(documents);
    }

    if (tableRef.current) {
      tableRef.current.scrollIntoView();
    }
  }, [documents]);

  const totalPages = Math.ceil(totalCount / pageSize);

  console.log('documents', documents);
  return (
    <div ref={tableRef} style={{ height: '100%' }}>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h3>Documents</h3>
            </Col>
            <ButtonGroup>
              <Button className={styles.scrbBtnOrange} onClick={startNewSearch}>
                New Search
              </Button>
              {totalCount < 1000 && (
                <Button color="success" onClick={addResultsToCart}>
                  Add Results to Cart
                </Button>
              )}
            </ButtonGroup>
          </Row>
        </CardBody>
      </Card>
      <LoadingComponent isLoading={loadingDocuments}>
        <DocumentTable
          documents={documents}
          accountId={currentAccount.accountId}
          thumbnails={thumbnails}
          updatePage={updatePage}
          pageSize={pageSize}
          totalPages={totalPages}
          controlledPage={currentPage}
          showInactiveTypes={showInactiveTypes}
          currentSort={currentSort}
          updateSort={updateSort}
        />
      </LoadingComponent>
    </div>
  );
};

export default DocumentList;
