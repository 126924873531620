import React from 'react';
import { PrivateRoute } from '../auth/privateroute';
import BatchList from '../batch/batchlist';
import ScribOnlineImport from '../batch/scribonlineimport';
import { useUserContext } from '../contexts/UserContext';
import { LoadingComponent } from '../Loading';
import UserList from '../users/userlist';
import NotAuthorized from '../users/userprofile/notauthorized';
import AccountList from '../account/accountlist';
import AccountDetail from '../account/accountdetail';
import AuditLog from '../audit/auditlog';
import InternalUsersList from '../account/apiusers/apiuserslist/index';
import InternalUserDetail from '../account/apiusers/apiuserdetail/index';
import DWT from '../scanner/viewer';
import Cart from '../cart';
import DocumentViewer from '../viewer';
import { DocumentDetail } from '../document/documentdetail';
import DocumentSearch from '../document/documentsearch';
import DocumentList from '../document/documentlist';
import UserImport from '../users/userimport/importlist';
import Meta from '../meta';
import RoleList from '../roles/rolelist';
import RoleDetail from '../roles/roledetail';
import UserDetail from '../users/userdetail/index';
import UserProfilePage, { UserProfile } from '../users/userprofile';
import FailedLogin from '../auth/failedlogin';
import { Route, useHistory } from 'react-router';
import { useMetaProvider } from '../contexts/MetaContext';
import ImageSilo from '../batch/imagesilo';
import { redirectUser } from '../users/userprofile/user';
import OrganizerConfig from '../organizer/config/organizerconfig';
import PrintLeadSheets from '../organizer/print/printer';

export const Routes = () => {
  const loading = useMetaProvider().isRefreshing;
  const {
    profile: { permissions, user },
    currentAccount,
  } = useUserContext();

  const history = useHistory();
  if (history.location.pathname === '/') {
    redirectUser(currentAccount.accountId, user, permissions);
  }
  return (
    <LoadingComponent isLoading={loading}>
      <PrivateRoute path="/" exact component={(props: string) => <DocumentSearch />} />
      <PrivateRoute path="/batch" exact component={(props: string) => <BatchList />} />
      <PrivateRoute path="/batch/scribonline" exact component={(props: string) => <ScribOnlineImport />} />
      <PrivateRoute path="/roles" exact component={(props: string) => <RoleList />} />
      <PrivateRoute path="/roledetail/:id?" exact component={(props: string) => <RoleDetail />} />
      <PrivateRoute path="/users" exact component={(props: string) => <UserList />} />
      <PrivateRoute path="/adminusers" exact component={(props: string) => <UserList adminList={true} />} />
      <PrivateRoute path="/importusers" exact component={(props: string) => <UserImport />} />
      <PrivateRoute path="/userdetail/:id?/:adminList?" component={(props: string) => <UserDetail />} />
      <PrivateRoute path="/userprofile" component={UserProfilePage} />
      <PrivateRoute path="/meta" component={(props: string) => <Meta />} />
      <PrivateRoute path="/viewer/:documentId" component={(props: string) => <DocumentViewer />} />
      <PrivateRoute path="/document/:documentId?" component={(props: string) => <DocumentDetail />} />
      <PrivateRoute path="/notauthorized" component={NotAuthorized} />
      <PrivateRoute path="/cart" component={Cart} />
      <Route path="/failedlogin" component={() => <FailedLogin message="You must be invited to join the Archive" />} />
      <Route path="/accountlist" exact component={AccountList} />
      <Route path="/accountdetail/:accountId?" component={AccountDetail} />
      <Route path="/auditlog" exact component={AuditLog} />
      <Route path="/apiusers/:accountId?" component={InternalUsersList} />
      <Route path="/apiuserdetail/:accountId/:id?" component={InternalUserDetail} />
      <Route path="/organizer/config" component={OrganizerConfig} />
      <Route path="/organizer/print" component={PrintLeadSheets} />
      {currentAccount.activateScanner && (
        <Route path="/scan" component={() => <DWT userEmail={useUserContext().profile.user.email} accountId={useUserContext().currentAccount.accountId} />} />
      )}
      <Route path="/imagesilo" component={() => <ImageSilo />} />
    </LoadingComponent>
  );
};
