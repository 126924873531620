import fetch from 'node-fetch';
import { AuditEntry } from '../types/audit';
import { getAuthToken, getBaseApiUrl } from './helpers';

let firstTime: boolean;

export const getAudits = async (
  actorFilter: string,
  targetFilter: string,
  resultFilter: string,
  applicationFilter: string,
  startDate: string,
  endDate: string,
): Promise<AuditEntry[]> => {
  firstTime = true;
  const url = `${getBaseApiUrl()}/v1/api/archive/audits${buildFilterParam('actor', actorFilter)}${buildFilterParam('target', targetFilter)}${buildFilterParam(
    'result',
    resultFilter,
  )}${buildFilterParam('application', applicationFilter)}${buildFilterParam('startDate', startDate)}${buildFilterParam('endDate', endDate)}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    console.log('in get account called fetch');
    return response.json() as Promise<AuditEntry[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

const buildFilterParam = (param: string, value: string): string => {
  if (!param || !value || value === '') return '';
  const toReturn = `${firstTime ? '?' : '&'}${param}=${value}`;
  if (firstTime) firstTime = false;
  return toReturn;
};
