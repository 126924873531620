import React, { useEffect, useState } from 'react';
import styles from '../../styles/archiveui.module.css';
import AccountTable from '../accountlisttable/accounttable';
import { getAccounts } from '../../api/accounts';
import { Loading, LoadingComponent } from '../../Loading';
import { Account } from '../../types/account';

const AccountsList = (): JSX.Element => {
  const [accounts, updateAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const setAccounts = async () => {
      const accounts = await getAccounts();
      updateAccounts(accounts);
      setLoading(false);
    };

    setAccounts();
  }, []);

  return (
    <LoadingComponent isLoading={loading}>
      <div className={styles.customLogContainer}>
        <AccountTable accounts={accounts} />
      </div>
    </LoadingComponent>
  );
};

export default AccountsList;
