import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import TypeSelect from '../metacomponents/type/typeselect';
import styles from '../../styles/archiveui.module.css';
import { Alert, Button, Col, Container, Form, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { IndexFields } from '../metacomponents/index/indexfields';
import { DateRange } from './daterange';
import { generateQuery } from './querygeneration';
import { BsPlus } from 'react-icons/bs';
import { AiOutlineMinus } from 'react-icons/ai';
import { StatusInput } from '../documentdetail/statusinput';
import { useMetaProvider } from '../../contexts/MetaContext';
import { LoadingComponent } from '../../Loading';

interface SearchComponentProps {
  submitSearch: (query: string) => void;
  searchError?: string;
  searching: boolean;
  showInactiveTypes: boolean;
  setShowInactiveTypes: (showInactiveTypes: boolean) => void;
}
const SearchComponent = ({ submitSearch, searchError, searching, showInactiveTypes, setShowInactiveTypes }: SearchComponentProps) => {
  const { metaState } = useMetaProvider();

  const [selectedTypes, setSelectedTypes] = useState<Record<string, boolean>>({});
  const [indexValues, setIndexValues] = useState<Record<string, string | undefined>>({});
  const [modifiedTimeStart, setModifiedTimeStart] = useState<Date>();
  const [modifiedTimeEnd, setModifiedTimeEnd] = useState<Date>();
  const [creationTimeStart, setCreationTimeStart] = useState<Date>();
  const [creationTimeEnd, setCreationTimeEnd] = useState<Date>();
  const [freeSearch, setFreeSearch] = useState<string>();
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [statusSearch, setStatusSearch] = useState<string>('ACTIVE');

  useEffect(() => {
    setSelectedTypes({});
    setIndexValues({});
    setModifiedTimeStart(undefined);
    setModifiedTimeEnd(undefined);
    setCreationTimeStart(undefined);
    setCreationTimeEnd(undefined);
    setStatusSearch('ACTIVE');
  }, [searching]);

  const toggleSelectedType = (event: ChangeEvent<HTMLInputElement>) => {
    const currentSelectedTypes = { ...selectedTypes };
    currentSelectedTypes[event.target.name] = event.target.checked;
    setSelectedTypes(currentSelectedTypes);
  };

  const setIndexValue = (event: ChangeEvent<HTMLInputElement>) => {
    const currentIndexValues = indexValues;
    currentIndexValues[event.target.name] = event.target.value;
    setIndexValues(currentIndexValues);
  };

  const updateStatusSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setStatusSearch(event.target.value);
  };

  const submitSearchInternal = async () => {
    const typesToSearch: string[] = [];
    for (const typeId of Object.keys(selectedTypes)) {
      if (selectedTypes[typeId]) {
        typesToSearch.push(typeId);
      }
    }

    const query = generateQuery({
      indexes: metaState.documentIndexes,
      indexValues,
      freeTextSearch: freeSearch,
      createdStartDate: creationTimeStart,
      createdEndDate: creationTimeEnd,
      modifiedStartDate: modifiedTimeStart,
      modifiedEndDate: modifiedTimeEnd,
      selectedTypes: typesToSearch,
      statusSearch,
    });

    submitSearch(query);
  };

  const showInactiveTypesButton = useMemo(() => {
    return (
      metaState.allowedDocumentTypes.filter((type) => {
        return type.status === 'inactive';
      }).length > 0
    );
  }, [metaState]);

  return (
    <Container className={styles.documentsearchContainer}>
      <div style={{ marginTop: '10px' }}>
        <InputGroup>
          <Input
            aria-label="Search Documents"
            disabled={searching}
            onChange={(e) => {
              setFreeSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                submitSearchInternal();
              }
            }}
          />
          <InputGroupAddon addonType="append">
            <Button className={styles.scrbBtnBlue} onClick={submitSearchInternal} disabled={searching}>
              Search
            </Button>
          </InputGroupAddon>
          <InputGroupAddon addonType="append">
            <Button
              onClick={() => setShowAdvanced(!showAdvanced)}
              className={showAdvanced ? '' : styles.scrbBtnOrange}
              style={{ backgroundColor: showAdvanced ? 'gray' : '#f68b2b' }}
              disabled={searching}
              aria-label="Add filters Button"
            >
              {showAdvanced ? <AiOutlineMinus /> : <BsPlus />}
            </Button>
          </InputGroupAddon>
        </InputGroup>
        <Row>
          {searchError && searchError !== '' && (
            <Alert color="danger" style={{ width: '100%', margin: '1%', textAlign: 'center' }}>
              {searchError}
            </Alert>
          )}
        </Row>
      </div>
      <LoadingComponent isLoading={searching}>
        {showAdvanced && (
          <Form style={{ marginTop: '25px' }}>
            <Row>
              <Col sm="4">
                <h3>Status</h3>
                <StatusInput onChange={updateStatusSearch} defaultValue={statusSearch} showAll={true} />
                <h3>Document Types</h3>
                <TypeSelect
                  toggleType={toggleSelectedType}
                  selectedTypes={Object.keys(selectedTypes).filter((key) => {
                    return selectedTypes[key];
                  })}
                  showInactive={showInactiveTypes}
                />
                {showInactiveTypesButton && (
                  <Button
                    onClick={() => setShowInactiveTypes(!showInactiveTypes)}
                    className={showInactiveTypes ? styles.scrbBtnOrange : styles.scrbBtnBlue}
                    style={{ backgroundColor: showInactiveTypes ? 'gray' : '#f68b2b', marginTop: '5px', marginBottom: '5px', width: '100%' }}
                  >
                    {showInactiveTypes ? 'Hide Inactive Types' : 'Show Inactive Types'}
                  </Button>
                )}
                <br />
                <h5>Created</h5>
                <DateRange
                  name="created"
                  startDateLabel="From"
                  endDateLabel="To"
                  startDateOnChange={setCreationTimeStart}
                  endDateOnChange={setCreationTimeEnd}
                />
                <br />
                <h5>Modified</h5>
                <DateRange
                  name="modified"
                  startDateLabel="From"
                  endDateLabel="To"
                  startDateOnChange={setModifiedTimeStart}
                  endDateOnChange={setModifiedTimeEnd}
                />
              </Col>
              <Col sm="8">
                <h3>Indexes</h3>
                <IndexFields onChange={setIndexValue} selectedTypes={Object.keys(selectedTypes).filter((type) => selectedTypes[type])}></IndexFields>
              </Col>
            </Row>
          </Form>
        )}
      </LoadingComponent>
    </Container>
  );
};

export default SearchComponent;
