import React, { Dispatch, SetStateAction } from 'react';
import { Card, CardBody, Form, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';

interface SearchBarProps {
  updateActorFilter: Dispatch<SetStateAction<string>>;
  updateTargetFilter: Dispatch<SetStateAction<string>>;
  updateResultFilter: Dispatch<SetStateAction<string>>;
  updateApplicationFilter: Dispatch<SetStateAction<string>>;
  updateStartDate: Dispatch<SetStateAction<string>>;
  updateEndDate: Dispatch<SetStateAction<string>>;
  actorFilter: string;
  targetFilter: string;
  resultFilter: string;
  applicationFilter: string;
  startDate: string;
  endDate: string;
  searchAudits: () => void;
}

const SearchBar = ({
  updateActorFilter,
  updateTargetFilter,
  updateResultFilter,
  updateApplicationFilter,
  updateStartDate,
  updateEndDate,
  actorFilter,
  targetFilter,
  resultFilter,
  applicationFilter,
  startDate,
  endDate,
  searchAudits,
}: SearchBarProps): JSX.Element => {
  const clearFilters = () => {
    updateActorFilter('');
    updateApplicationFilter('');
    updateResultFilter('');
    updateTargetFilter('');
    updateStartDate('');
    updateEndDate('');
  };

  return (
    <div>
      <Card className={styles.filtersCard} style={{ marginTop: '1em', borderRadius: '.5em', paddingTop: '0em' }}>
        <CardBody className={styles.searchBarHeader}>
          <h5 style={{ textAlign: 'start', fontWeight: 500, marginTop: '.5em' }}>Audit Log</h5>
        </CardBody>
        <CardBody className={styles.searchBarBody}>
          <Form>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="select"
                    name="actor"
                    id="actor"
                    placeholder="Actor"
                    aria-label="Select Actor"
                    value={actorFilter}
                    onChange={(e) => updateActorFilter(e.target.value.trim())}
                  >
                    <option></option>
                    <option>Actor 1</option>
                    <option>Actor 2</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="select"
                    name="target"
                    id="target"
                    placeholder="Target"
                    aria-label="Select Target"
                    value={targetFilter}
                    onChange={(e) => updateTargetFilter(e.target.value.trim())}
                  >
                    <option></option>
                    <option>Target 1</option>
                    <option>Target 2</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="select"
                    name="result"
                    id="result"
                    placeholder="Result"
                    aria-label="Select Result"
                    value={resultFilter}
                    onChange={(e) => updateResultFilter(e.target.value.trim())}
                  >
                    <option></option>
                    <option>Result 1</option>
                    <option>Result 2</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="select"
                    name="application"
                    id="application"
                    placeholder="Application"
                    aria-label="Select Application"
                    value={applicationFilter}
                    onChange={(e) => updateApplicationFilter(e.target.value.trim())}
                  >
                    <option></option>
                    <option>Application 1</option>
                    <option>Application 2</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="date"
                    name="start-date"
                    id="start-date"
                    placeholder="Start Date"
                    aria-label="Select Start Date"
                    value={startDate}
                    onChange={(e) => updateStartDate(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="date"
                    name="end-date"
                    id="end-date"
                    placeholder="End Date"
                    aria-label="Select End Date"
                    value={endDate}
                    onChange={(e) => updateEndDate(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className={styles.detailBtnContainer}>
              <Button id="clearFilterBtn" aria-label="Clear Filter Button" className={styles.scrbBtnBlueMargin} onClick={clearFilters}>
                Clear Filters
              </Button>
              <Button id="applyFilterBtn" aria-label="Apply Filter Button" className={styles.scrbBtnOrange} onClick={searchAudits}>
                Apply Filters
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default SearchBar;
