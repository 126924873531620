import React, { ChangeEvent, KeyboardEvent } from 'react';
import { Button, FormGroup, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';
import { AiFillStar } from 'react-icons/ai';
import { FaSearch } from 'react-icons/fa';

interface IndexInputProps {
  inputType: InputType;
  indexName: string;
  indexId: string;
  value?: string;
  isRequired?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isOrganizerPrimaryIndex?: boolean;
  organizerLookupFunction?: () => void;
}

export const IndexInput = ({
  inputType,
  indexName,
  indexId,
  value,
  isRequired,
  onChange,
  isOrganizerPrimaryIndex,
  organizerLookupFunction,
}: IndexInputProps) => {
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && organizerLookupFunction) {
      event.preventDefault();
      organizerLookupFunction();
    }
  };

  let input = (
    <Input value={value} type={inputType} name={indexId} id={indexId} onChange={(e) => onChange(e)} onKeyPress={handleKeyPress} required={isRequired} />
  );
  if (isOrganizerPrimaryIndex) {
    input = (
      <InputGroup>
        {input}
        <InputGroupAddon addonType="append">
          <Button color="info" onClick={organizerLookupFunction}>
            <FaSearch />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    );
  }

  return (
    <FormGroup>
      <Label for={indexId}>
        {isRequired && (
          <>
            <AiFillStar color="#b01320" />
            &nbsp;
          </>
        )}
        {indexName}
      </Label>
      {input}
    </FormGroup>
  );
};
