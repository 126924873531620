import React, { RefObject, useRef } from 'react';
import { ScanDocument } from '../../types/scanner';
import styles from '../../styles/archiveui.module.css';
import { Thumbnail } from './thumbnail';
import { ReducerItemType } from '.';
import { DocumentContainer } from './documentcontainer';

interface ThumbnailViewerProps {
  documents: ScanDocument[];
  movePage: (fromDocumentIndex: number, toDocumentIndex: number, fromPageIndex: number, toPageIndex: number) => void;
  selectedDocuments: Record<string, boolean>; //id: true/false
  selectedPages: Record<string, boolean>; //id: true/false
  handleItemClick: (id: string, itemType: ReducerItemType, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  moveSelectedPages: (toPageIndex: number, toDocumentIndex: number) => void;
  editDocumentMeta: (docId: string, event: React.MouseEvent<SVGElement, MouseEvent>) => void;
  updateRefs: (id: string, ref: RefObject<HTMLDivElement>, isInView: boolean) => void;
  updateDocumentContainerRefs: (id: string, ref: RefObject<HTMLDivElement>, isInView: boolean) => void;
  documentErrors: Record<string, string | undefined>;
  isExistingDocument: boolean;
}

export const ThumbnailViewer = ({
  documents,
  movePage,
  selectedDocuments,
  selectedPages,
  handleItemClick,
  moveSelectedPages,
  editDocumentMeta,
  updateRefs,
  updateDocumentContainerRefs,
  documentErrors,
  isExistingDocument,
}: ThumbnailViewerProps) => {
  const selectDocument = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    handleItemClick(id, 'documents', event);
  };

  const selectPage = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    handleItemClick(id, 'pages', event);
  };

  const renderedDocs = (
    <>
      {documents &&
        documents.map((document, docIndex) => {
          const pages = document.pages;
          const isSelected = selectedDocuments[document.releaseDocument.id];
          return (
            <DocumentContainer
              key={`documentcontainer_${document.releaseDocument.id}`}
              index={docIndex}
              document={document}
              isSelected={isSelected}
              selectDocument={selectDocument}
              editDocumentMeta={editDocumentMeta}
              updateDocumentContainerRefs={updateDocumentContainerRefs}
              error={documentErrors[document.releaseDocument.id]}
              isExistingDocument={isExistingDocument}
            >
              {pages &&
                pages.map((page, pageIndex) => {
                  return (
                    <div key={`thumbnail_${docIndex}_${pageIndex}`} onClick={(e) => selectPage(e, page.id)}>
                      <Thumbnail
                        scanPage={page}
                        pageIndex={pageIndex}
                        documentIndex={docIndex}
                        documents={documents}
                        movePage={movePage}
                        isSelected={selectedPages[page.id]}
                        selectedPages={selectedPages}
                        moveSelectedPages={moveSelectedPages}
                        updateRefs={updateRefs}
                      />
                    </div>
                  );
                })}
            </DocumentContainer>
          );
        })}
    </>
  );

  return (
    <>
      <div className={styles.thumbnailViewerContainer}>{renderedDocs}</div>
    </>
  );
};
