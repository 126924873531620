import React, { useState } from 'react';
import { FaUserTag } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { ImCheckmark, ImSearch, ImUsers } from 'react-icons/im';
import { AiFillFileAdd } from 'react-icons/ai';
import { RiFolderChartFill, RiAdminFill, RiFolderSettingsFill, RiBarChartGroupedLine, RiBriefcase2Fill, RiQrScanFill, RiUserFill } from 'react-icons/ri';
import { BsFillCartFill } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import styles from '../styles/archiveui.module.css';
import AccessControl from '../users/userprofile/accesscontrol';
import { Account } from '../types/account';
import { useCartContext } from '../contexts/CartContext';
import { CgMenuRightAlt } from 'react-icons/cg';
import { useUIContext } from '../contexts/UIContext';
import { useUserContext } from '../contexts/UserContext';
import logo from './scribonline_blue.png';
import birdHead from './bird-logo-head.png';
import { routePermissions } from '../users/userprofile/user';
import { FaBarcode } from 'react-icons/fa';
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs';

interface NavigationProps {
  logout: () => void;
}

const SideNav = ({ logout }: NavigationProps) => {
  let currentAccount: Account | undefined = undefined;
  let setCurrentAccount: (account: Account) => void;
  let userAccounts: Account[] = [];

  try {
    const userContext = useUserContext();
    currentAccount = userContext.currentAccount;
    setCurrentAccount = userContext.setCurrentAccount;
    userAccounts = userContext.userAccounts.sort((a, b) => {
      return ('' + a.shortName).trim().localeCompare(('' + b.shortName).trim());
    });
  } catch (e) {
    console.log('User provider not available...' + e);
  }

  const location = useLocation();
  const mediaQuery = window.matchMedia('( min-width: 768px )');
  const { sideBarOpen, toggleSidebar, accountSearch, setAccountSearch } = useUIContext();

  let cartItems: string[] = [];
  try {
    cartItems = useCartContext().cartItems;
  } catch (e) {
    console.log('Cart provider not available');
  }

  const navItems = currentAccount ? (
    <>
      <AccessControl requiredPermissionList={routePermissions['/'].hardPermissions} softPermissionList={routePermissions['/'].softPermissions}>
        <NavItem className={styles.navMenuItem}>
          <NavLink className={styles.navLink} tag={Link} to={'/'}>
            <ImSearch color="#ceeaf4" size={25} className={styles.menuBtn} />
            <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Search Documents</div>
          </NavLink>
          <div
            style={{ display: location.pathname === '/' ? 'block' : 'none' }}
            className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
          ></div>
        </NavItem>
      </AccessControl>
      <AccessControl requiredPermissionList={routePermissions['/document'].hardPermissions} softPermissionList={routePermissions['/document'].softPermissions}>
        <NavItem className={styles.navMenuItem}>
          <NavLink className={styles.navLink} tag={Link} to={'/document'}>
            <AiFillFileAdd color="#ceeaf4" size={25} className={styles.menuBtn} />
            <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Add Document</div>
          </NavLink>
          <div
            style={{ display: location.pathname === '/document' ? 'block' : 'none' }}
            className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
          ></div>
        </NavItem>
      </AccessControl>
      {currentAccount.activateScanner && (
        <AccessControl requiredPermissionList={routePermissions['/scan'].hardPermissions} softPermissionList={routePermissions['/scan'].softPermissions}>
          <NavItem className={styles.navMenuItem}>
            <NavLink className={styles.navLink} tag={Link} to={'/scan'}>
              <RiQrScanFill color="#ceeaf4" size={25} className={styles.menuBtn} />
              <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Scanner</div>
            </NavLink>
            <div
              style={{ display: location.pathname === '/scan' ? 'block' : 'none' }}
              className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
            ></div>
          </NavItem>
        </AccessControl>
      )}
      <AccessControl
        requiredPermissionList={[{ permissionId: 'meta', action: 'read' }]}
        softPermissionList={[
          { permissionId: 'document', action: 'read' },
          { permissionId: 'document', action: 'create' },
        ]}
      >
        <li className={styles.menuDivider}></li>
      </AccessControl>
      <AccessControl requiredPermissionList={routePermissions['/users'].hardPermissions} softPermissionList={routePermissions['/users'].softPermissions}>
        <NavItem className={styles.navMenuItem}>
          <NavLink className={styles.navLink} tag={Link} to={'/users'}>
            <ImUsers color="#ceeaf4" size={25} className={styles.menuBtn} />
            <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Manage Users</div>
          </NavLink>
          <div
            style={{ display: location.pathname === '/users' ? 'block' : 'none' }}
            className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
          ></div>
        </NavItem>
      </AccessControl>
      <AccessControl requiredPermissionList={routePermissions['/roles'].hardPermissions} softPermissionList={routePermissions['/roles'].softPermissions}>
        <NavItem className={styles.navMenuItem}>
          <NavLink className={styles.navLink} tag={Link} to={'/roles'}>
            <FaUserTag color="#ceeaf4" size={25} className={styles.menuBtn} />
            <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Manage Roles</div>
          </NavLink>
          <div
            style={{ display: location.pathname === '/roles' ? 'block' : 'none' }}
            className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
          ></div>
        </NavItem>
      </AccessControl>
      <AccessControl requiredPermissionList={routePermissions['/meta'].hardPermissions} softPermissionList={routePermissions['/meta'].softPermissions}>
        <NavItem className={styles.navMenuItem}>
          <NavLink className={styles.navLink} tag={Link} to={'/meta'}>
            <RiFolderChartFill color="#ceeaf4" size={25} className={styles.menuBtn} />
            <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Manage Doc Types</div>
          </NavLink>
          <div
            style={{ display: location.pathname === '/meta' ? 'block' : 'none' }}
            className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
          ></div>
        </NavItem>
      </AccessControl>
      {currentAccount.activateOrganizer && (
        <>
          <AccessControl permissionId="organizer" action={'create'}>
            <NavItem className={styles.navMenuItem}>
              <NavLink className={styles.navLink} tag={Link} to={'/organizer/config'}>
                <BsFileEarmarkSpreadsheet color="#ceeaf4" size={25} className={styles.menuBtn} />
                <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Manage Organizer</div>
              </NavLink>
              <div
                style={{ display: location.pathname === '/organizer/config' ? 'block' : 'none' }}
                className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
              ></div>
            </NavItem>
          </AccessControl>
          <NavItem className={styles.navMenuItem}>
            <NavLink className={styles.navLink} tag={Link} to={'/organizer/print'}>
              <FaBarcode color="#ceeaf4" size={25} className={styles.menuBtn} />
              <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Print Lead Sheets</div>
            </NavLink>
            <div
              style={{ display: location.pathname === '/organizer/print' ? 'block' : 'none' }}
              className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
            ></div>
          </NavItem>
        </>
      )}
      <AccessControl>
        <>
          <li className={styles.menuDivider}></li>
          <NavItem className={styles.navMenuItem}>
            <NavLink className={styles.navLink} tag={Link} to={'/accountlist'}>
              <RiBriefcase2Fill color="#ceeaf4" size={25} className={styles.menuBtn} />
              <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Accounts</div>
            </NavLink>
            <div
              style={{ display: location.pathname === '/accountlist' ? 'block' : 'none' }}
              className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
            ></div>
          </NavItem>
          <NavItem className={styles.navMenuItem}>
            <NavLink className={styles.navLink} tag={Link} to={'/adminusers'}>
              <RiAdminFill color="#ceeaf4" size={25} className={styles.menuBtn} />
              <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Admin Users</div>
            </NavLink>
            <div
              style={{ display: location.pathname === '/adminusers' ? 'block' : 'none' }}
              className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
            ></div>
          </NavItem>
          <NavItem className={styles.navMenuItem}>
            <NavLink className={styles.navLink} tag={Link} to={'/auditlog'}>
              <RiBarChartGroupedLine color="#ceeaf4" size={25} className={styles.menuBtn} />
              <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Audit Log</div>
            </NavLink>
            <div
              style={{ display: location.pathname === '/auditlog' ? 'block' : 'none' }}
              className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
            ></div>
          </NavItem>
          <NavItem className={styles.navMenuItem}>
            <NavLink className={styles.navLink} tag={Link} to={'/batch'}>
              <RiFolderSettingsFill color="#ceeaf4" size={25} className={styles.menuBtn} />
              <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Batches</div>
            </NavLink>
            <div
              style={{ display: location.pathname === '/batch' ? 'block' : 'none' }}
              className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
            ></div>
          </NavItem>
        </>
      </AccessControl>
    </>
  ) : (
    <NavItem className={styles.navMenuItem}>
      <NavLink className={styles.navLink} tag={Link}>
        <RiBriefcase2Fill color="#ceeaf4" size={25} className={styles.menuBtn} />
        <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Create Account</div>
      </NavLink>
      <div style={{ display: 'block' }} className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}></div>
    </NavItem>
  );

  return (
    <div style={{ width: sideBarOpen ? '13em' : '3em' }} className={styles.sideNav}>
      <div className={styles.navListContainer}>
        <div className={styles.scribLogo}>
          <img src={birdHead} style={{ height: '45px', top: 0, left: 0, transform: 'scaleX(-1)' }} alt="" />
          <img src={logo} style={{ height: '56px', top: 0, left: 0, display: sideBarOpen ? 'flex' : 'none' }} alt="" />
        </div>
        <Nav vertical className="list-unstyled pb-3">
          {navItems}
        </Nav>
      </div>
      <div className={styles.navBarBottom}>
        <div className={styles.navMenuItem}>
          <GiHamburgerMenu color="#ceeaf4" size={25} style={{ minWidth: '1.5em' }} className={styles.hamburgerIcon} onClick={toggleSidebar} />
          <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Show/Hide Drawer</div>
        </div>
        {cartItems.length > 0 && (
          <AccessControl permissionId={'document'} action={'read'}>
            <NavItem className={styles.navMenuItem}>
              <NavLink className={styles.navLink} tag={Link} to={'/cart'}>
                <BsFillCartFill color="#ceeaf4" size={25} className={styles.menuBtn} />
                <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Cart</div>
              </NavLink>
              <div
                style={{ display: location.pathname === '/cart' ? 'block' : 'none' }}
                className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
              ></div>
            </NavItem>
          </AccessControl>
        )}
        {currentAccount && (
          <ul className={styles.menuNavLinks} style={{ marginLeft: '-55px' }}>
            <UncontrolledDropdown direction="up" nav inNavbar className={styles.dropdownToggle}>
              <DropdownToggle
                drop={'up'}
                nav
                style={{ color: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '-40px', maxWidth: '100%' }}
              >
                <RiUserFill color="#ceeaf4" size={25} className={styles.hamburgerIcon} />
                <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden} title={currentAccount.shortName} style={{ paddingLeft: '1px' }}>
                  {currentAccount.shortName}
                </div>{' '}
              </DropdownToggle>
              <DropdownMenu style={{ boxShadow: '1px 2px 7px 1px rgb(0 0 0 / 13%)' }}>
                <NavLink className={styles.link} tag={Link} to={'/userprofile'}>
                  <div className="dropdown-item" style={{ marginLeft: '-15px' }}>
                    Profile
                  </div>
                </NavLink>
                <DropdownItem className="text-primary">
                  {currentAccount?.shortName}
                  <ImCheckmark className={styles.checkMark} />
                </DropdownItem>
                {userAccounts.length > 1 && (
                  <>
                    <DropdownItem divider />
                    <Input
                      aria-label="Filter Profile"
                      onChange={(event) => setAccountSearch(event.target.value)}
                      style={{ width: '90%', height: '25px', margin: 'auto' }}
                    />
                    <div className={styles.scrollable}>
                      {userAccounts
                        .filter((account) => {
                          const filterMatch =
                            !accountSearch ||
                            account.name.toLowerCase().indexOf(accountSearch) >= 0 ||
                            account.shortName.toLowerCase().indexOf(accountSearch) >= 0;
                          return account.accountId !== currentAccount?.accountId && filterMatch;
                        })
                        .map((account, index) => {
                          return (
                            <DropdownItem
                              onClick={() => {
                                setCurrentAccount(account);
                              }}
                              key={account.accountId}
                            >
                              {account.shortName}
                            </DropdownItem>
                          );
                        })}
                    </div>
                  </>
                )}
                <DropdownItem divider />
                <DropdownItem onClick={logout}> Log Out </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ul>
        )}
      </div>
    </div>
  );
};

export default SideNav;
