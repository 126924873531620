import React, { useState } from 'react';
import { Column, HeaderGroup, useSortBy, useTable } from 'react-table';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { getDocumentTypesFromXML, ImportedDocumentType, saveImportTypesToMeta } from '.';
import { getMeta } from '../../api/meta';
import styles from '../../styles/archiveui.module.css';

interface ImportTypesProps {
  accountId: string;
}

const parseTypesFromXML = (xmlString: string): ImportedDocumentType[] => {
  const xmlDocument = new DOMParser().parseFromString(xmlString.replace(/dc:/g, ''), 'application/xml');
  return getDocumentTypesFromXML(xmlDocument);
};

const ImportTypes = ({ accountId }: ImportTypesProps): JSX.Element => {
  const [importedDocumentTypes, setTypes] = useState<ImportedDocumentType[]>([]);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [importURL, setImportURL] = useState('');

  const startImagingImportFile = async () => {
    if (!selectedFile) {
      toast.error('You must upload an xml file to import.');
      return;
    }
    const xmlString = await selectedFile.text();
    startImagingImport(xmlString);
  };

  const startImagingImportURL = async () => {
    if (!importURL) {
      toast.error('You must enter a url to import from.');
      return;
    }
    const resp = await fetch(importURL);
    const xmlString = await resp.text();
    console.log('xml', xmlString);
    startImagingImport(xmlString);
  };

  const startImagingImport = async (xmlString: string) => {
    const incomingTypes = parseTypesFromXML(xmlString);
    //get current meta... loop to check for existing types and set
    const curMeta = await getMeta(accountId);

    setTypes(
      incomingTypes.map((incomingType) => {
        for (const existingType of curMeta.documentTypes) {
          if (incomingType.id === existingType.id) {
            incomingType.alreadyExists = true;
            return incomingType;
          }
        }
        return incomingType;
      }),
    );
  };

  const clearTypes = () => {
    setTypes([]);
  };

  const createTypes = async () => {
    toggleConfirmation();
    const selectedTypes: ImportedDocumentType[] = importedDocumentTypes.filter((type) => type.selectedForImport);
    await saveImportTypesToMeta(accountId, selectedTypes);
    clearTypes();
  };

  const updateTypeSelected = (typeId: string | null | undefined) => {
    if (!typeId) {
      return;
    }
    setTypes((oldtypes) => {
      return oldtypes.map((type) => {
        if (type.id === typeId) {
          return { ...type, selectedForImport: !type.selectedForImport };
        }
        return type;
      });
    });
  };
  const [modal, setModal] = useState(false);

  const toggleConfirmation = () => setModal(!modal);
  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Description',
          accessor: 'description',
        },
        {
          Header: 'Document Count',
          accessor: 'relatedDocs',
        },
        {
          Header: 'Import',
          accessor: 'selectedForImport',
          // eslint-disable-next-line react/display-name
          Cell: (cellProps) => {
            let display = '❌';
            let click = () => {
              ('');
            };
            if (!cellProps.rowsById[cellProps.row.id].original.alreadyExists) {
              display = cellProps.value ? '✅' : '⛔️';
              click = () => {
                updateTypeSelected(cellProps.rowsById[cellProps.row.id].original.id);
              };
            }
            return <span onClick={click}>{display}</span>;
          },
        },
      ] as Column<ImportedDocumentType>[],
    [],
  );
  const data = React.useMemo(() => importedDocumentTypes, [importedDocumentTypes]);
  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );
  const renderSort = (column: HeaderGroup<ImportedDocumentType>): JSX.Element => {
    if (column.isSorted) {
      return <span>{column.isSortedDesc ? ' 🔽' : ' 🔼'}</span>;
    }
    return <span></span>;
  };

  const table = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (table != undefined) {
    table.style.borderRadius = '.5em';
  }

  const fileChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      if (e.currentTarget.files.length > 0) {
        const f = e.currentTarget.files.item(0);
        if (f) {
          setSelectedFile(f);
        }
      }
    }
  };

  return (
    <div>
      <CardBody className={styles.searchBarBody}>
        <div className="row">
          <div className="col">
            <InputGroup>
              <Input placeholder="Input ScribOnline URL" onChange={(e) => setImportURL(e.target.value)}></Input>
              <InputGroupAddon addonType="append">
                <Button className={styles.scrbBtnBlue} onClick={startImagingImportURL}>
                  Load
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>
          <div className="col">
            <InputGroup>
              <CustomInput id="fileInput" type="file" onChange={fileChange} accept=".xml" />
              <InputGroupAddon addonType="append">
                <Button className={styles.scrbBtnBlue} onClick={startImagingImportFile}>
                  Load
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>
          <div className="col-md-auto">
            <ButtonGroup>
              <Button className={styles.scrbBtnOrange} onClick={toggleConfirmation}>
                Import Selected Doc Types
              </Button>
              <Button onClick={clearTypes}>Clear</Button>
            </ButtonGroup>
          </div>
        </div>
        <br />
      </CardBody>
      {data.length > 0 ? (
        <Card className={styles.tableContainer}>
          <Table striped hover size="sm" id="auditTable" responsive>
            <thead className={styles.tableHeader}>
              {headerGroups.map((headerGroup) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      if (column.Header === 'Name') {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.IdHeader}>
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                          </th>
                        );
                      } else if (column.Header === 'Actions') {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.actionsHeader}>
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                          </th>
                        );
                      } else {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.th}>
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                          </th>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.column.Header === 'Name') {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td style={{ paddingLeft: '1.25em' }} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      } else {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      ) : (
        <Alert color="warning" className={styles.noResultsAlert}>
          No types imported...
        </Alert>
      )}

      <Modal isOpen={modal} toggle={toggleConfirmation}>
        <ModalHeader style={{ backgroundColor: '#ceeaf4' }}>Import the select document types?</ModalHeader>
        <ModalBody style={{ backgroundColor: '#F4F4F4' }}>
          Import the follwing types? <br />
          {importedDocumentTypes.map((type) => {
            if (type.selectedForImport && !type.alreadyExists) {
              return (
                <div key={type.id}>
                  <strong>{type.name}</strong>
                </div>
              );
            }
            return '';
          })}
        </ModalBody>
        <ModalFooter style={{ backgroundColor: '#F4F4F4' }}>
          <Button className={styles.scrbBtnOrange} onClick={createTypes}>
            Import
          </Button>{' '}
          <Button className={styles.scrbBtnBlue} onClick={toggleConfirmation}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ImportTypes;
